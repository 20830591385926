<div class="label-text">
  <label translate for=" Input " class="block">{{ label | translate }} </label>
</div>

<span class="p-fluid">
  <p-dropdown
    #Input
    [options]="arrayPayload"
    [optionLabel]="optionLabel"
    [optionValue]="optionValue"
    [placeholder]="placeholder | translate"
    [autoDisplayFirst]="false"
    [dataKey]="datakey"
    (onChange)="onChange($event)"
    [(ngModel)]="selectedItem"
  >
  </p-dropdown>
</span>
