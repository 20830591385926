import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
@Pipe({
  name: 'dateformat',
})
export class DateFormatPipe implements PipeTransform {
  transform(value: any, args?: any): string {
    return moment(value).format('MMM DD, YYYY');
  }
}
