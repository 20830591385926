import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { MessageService } from 'primeng/api';
import { AppSettings } from '../../app.settings';
import { CommonBindingDataService } from '../../services/common-binding-data.service';
import * as utils from '../../utility-functions/utils';
interface Marker {
  lat: number;
  lng: number;
  label?: string;
  draggable: boolean;
}
declare const google: any;

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
})
export class MapComponent implements OnInit, OnChanges {
  @Input() mapType: string;
  @Output() latlng = new EventEmitter<any>();
  @Output() address = new EventEmitter<any>();
  @Output() polygonLatLng = new EventEmitter<any>();
  @Input() existingLat;
  @Input() existingLng;
  @Input() existingPolygon;
  @Input() nearByStores: any = [];
  arrayOfNearby = [];
  markers: Marker[] = [];
  lat: any;
  editBtn;
  lng: any;
  pointList: { latitude: number; longitude: number }[] = [];
  drawingManager: any;
  selectedShape: any;
  existing;
  selectedArea = 0;
  storeMap;
  constructor(private commonBindingDataService: CommonBindingDataService, private messageService: MessageService) {}

  ngOnInit(): void {}

  onMapReady(map) {
    this.setCurrentPosition(map);
    this.initDrawingManager(map);
    this.drawExisting(map);
    this.storeMap = map;
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('nearByStores')) {
      this.drawExisting(this.storeMap);
    }
  }

  drawExisting(map) {
    if (this.nearByStores.length) {
      this.nearByStores.forEach((element) => {
        const triangleCoords = element;
        const bermudaTriangle = new google.maps.Polygon({
          paths: triangleCoords,
          strokeColor: '#FF0000',
          strokeOpacity: 0.4,
          strokeWeight: 1,
          fillColor: '#FF0000',
          fillOpacity: 0.15,
        });
        this.arrayOfNearby.push(bermudaTriangle);
        bermudaTriangle.setMap(map);
      });
    } else {
      for (const ele of this.arrayOfNearby) {
        ele.setMap(null);
      }
      this.arrayOfNearby = [];
    }
  }

  selectMapType(map: any) {
    //marker map
    const self = this;
    const options = {
      drawingControl: true,

      drawingControlOptions: {
        position: google.maps.ControlPosition.RIGHT,
        drawingModes: [google.maps.drawing.OverlayType.MARKER],
      },
      markerOptions: {
        icon: '../assets/images/store-icon.png',
        draggable: true,
      },
      polygonOptions: {
        draggable: true,
        editable: true,
      },

      mapTypeControlOptions: {
        style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
      },
    };
    this.drawingManager = new google.maps.drawing.DrawingManager(options);
    this.drawingManager.setMap(map);

    //if lat and lng are already present -> show marker and disabled all controls.
    if (this.existingLat && this.existingLng) {
      const myLatLng = { lat: this.existingLat, lng: this.existingLng };
      const existMark = new google.maps.Marker({
        position: myLatLng,
        icon: '../assets/images/store-icon.png',
        map,
        title: this.commonBindingDataService.getLabel('store'),
        draggable: true,
      });

      this.setMapPositionLat(map, this.existingLat, this.existingLng);

      if (this.existingPolygon?.length !== 0) {
        self.drawingManager.setDrawingMode(null);
        // To hide:
        self.drawingManager.setOptions({
          drawingControl: false,
        });
      } else {
        self.drawingManager.setOptions({
          drawingControlOptions: {
            position: google.maps.ControlPosition.RIGHT,
            drawingModes: [google.maps.drawing.OverlayType.POLYGON],
          },
        });
        self.drawingManager.setMap(map);
      }

      let posInit;
      let posLast;
      existMark.addListener('dragend', (event) => {
        posLast = event.latLng.toJSON();

        const geocoder = new google.maps.Geocoder();
        geocoder.geocode({ latLng: event.latLng }, this.emitAddress);
        this.latlng.emit(posLast);
      });
    }

    //if polygon is existing
    if (this.existingPolygon.length !== 0) {
      this.createCustomBtnDiv(map);
      this.setExistingPoly(self.drawingManager, map);

      //polygon drag scenario
      let posInit;
      let posLast;
      this.existing?.addListener('dragstart', (event) => {
        posInit = event.latLng.toJSON();
      });

      this.existing?.addListener('dragend', (event) => {
        posLast = event.latLng.toJSON();
        //this points are updating in set_at event Listener
        //use this event if required.
        // let latDiff = posLast.lat - posInit.lat;
        // let lngDiff = posLast.lng - posInit.lng;
        // this.existingPolygon.forEach((p) => {
        //   p.lat = p.lat + (posLast.lat - posInit.lat);
        //   p.lng = p.lng + (posLast.lng - posInit.lng);
        // });
        this.polygonLatLng.emit(this.existingPolygon);
      });

      //Polygon edit event
      const paths1 = this.existing?.getPaths();
      for (let p = 0; p < paths1.getLength(); p++) {
        google.maps.event.addListener(paths1.getAt(p), 'set_at', (event, previous) => {
          const lastSet = this.existing?.getPath();
          this.updatePointList(lastSet);
          this.existingPolygon = this.pointList;
        });

        google.maps.event.addListener(paths1.getAt(p), 'insert_at', (event) => {
          const lastInsert = this.existing?.getPath();
          this.updatePointList(lastInsert);
          this.existingPolygon = this.pointList;
        });
      }
    }
  }

  emitAddress = (result, status) => {
    const self = this;
    if ('OK' === status) {
      self.address.emit(result[0]);
    } else {
      self.address.emit('addressNotFound');
    }
  };

  setExistingPoly(drawingManager, map) {
    drawingManager.setDrawingMode(null);
    drawingManager.setOptions({
      drawingControl: false,
    });
    this.existing = new google.maps.Polygon({
      paths: this.existingPolygon,
      editable: false,
      draggable: false,
      strokeOpacity: 1,
      strokeWeight: 2,
      fillColor: '#28812c',
      fillOpacity: 0.15,
    });
    this.existing.setMap(map);
  }

  createEditBtnControl(map) {
    const editBtn = document.createElement('button');

    // CSS for the control custom button.
    editBtn.style.backgroundColor = '#749650';
    editBtn.style.border = '2px solid #fff';
    editBtn.style.borderRadius = '3px';
    editBtn.style.boxShadow = '0 2px 6px rgba(0,0,0,.3)';
    editBtn.style.color = '#fff';
    editBtn.style.cursor = 'pointer';
    editBtn.style.fontFamily = 'Roboto';
    editBtn.style.fontSize = '1rem';
    editBtn.style.lineHeight = '38px';
    editBtn.style.margin = '8px 5px';
    editBtn.style.padding = '0 10px';
    editBtn.style.textAlign = 'center';
    editBtn.textContent = this.commonBindingDataService.getLabel('editPolygon');
    editBtn.title = this.commonBindingDataService.getLabel('editPolygon');
    editBtn.type = 'button';

    editBtn.addEventListener('click', () => {
      if (this.existing) {
        if (this.existing.draggable) {
          this.existing.draggable = false;
          this.existing.setEditable(false);
          this.editBtn.textContent = this.commonBindingDataService.getLabel('editPolygon');
          this.editBtn.title = this.commonBindingDataService.getLabel('editPolygon');
          this.editBtn.style.backgroundColor = '#749650';
          this.messageService.add({
            severity: 'warn',
            key: 'br',
            summary: 'Success',
            detail: this.commonBindingDataService.getLabel('noEditPolygonMsg'),
          });
        } else {
          this.existing.draggable = true;
          this.existing.setEditable(true);
          this.editBtn.textContent = this.commonBindingDataService.getLabel('stopEditingPolygon');
          this.editBtn.title = this.commonBindingDataService.getLabel('stopEditingPolygon');
          this.editBtn.style.backgroundColor = 'red';
          this.messageService.add({
            severity: 'warn',
            key: 'br',
            summary: 'Success',
            detail: this.commonBindingDataService.getLabel('editPolygonMsg'),
          });
        }
      }
    });

    return editBtn;
  }

  createCustomBtnDiv(map) {
    const editBtnDiv = document.createElement('div');
    this.editBtn = this.createEditBtnControl(map);
    editBtnDiv.appendChild(this.editBtn);
    map.controls[google.maps.ControlPosition.TOP_CENTER].push(editBtnDiv);
  }

  initDrawingManager = (map: any) => {
    const self = this;
    this.selectMapType(map);

    google.maps.event.addListener(this.drawingManager, 'overlaycomplete', (event) => {
      if (event.type === google.maps.drawing.OverlayType.POLYGON) {
        const paths = event.overlay.getPaths();
        for (let p = 0; p < paths.getLength(); p++) {
          google.maps.event.addListener(paths.getAt(p), 'set_at', () => {
            if (!event.overlay.drag) {
              self.updatePointList(event.overlay.getPath());
            }
          });
          google.maps.event.addListener(paths.getAt(p), 'insert_at', () => {
            self.updatePointList(event.overlay.getPath());
          });
          google.maps.event.addListener(paths.getAt(p), 'remove_at', () => {
            self.updatePointList(event.overlay.getPath());
          });
        }
        self.updatePointList(event.overlay.getPath());
      }
      if (event.type !== google.maps.drawing.OverlayType.MARKER) {
        // Switch back to non-drawing mode after drawing a shape.
        self.drawingManager.setDrawingMode(null);
        // To hide:
        self.drawingManager.setOptions({
          drawingControl: false,
        });
      }
    });

    //event for marker touch
    google.maps.event.addListener(this.drawingManager, 'markercomplete', (event) => {
      const geocoder = new google.maps.Geocoder();
      geocoder.geocode({ latLng: event.position }, this.emitAddress);
      const pos = event.position.toJSON();
      this.latlng.emit(pos);
      self.drawingManager.setDrawingMode(null);

      self.drawingManager.setOptions({
        drawingControlOptions: {
          position: google.maps.ControlPosition.RIGHT,
          drawingModes: [google.maps.drawing.OverlayType.POLYGON],
        },
      });
      self.drawingManager.setMap(map);
    });
  };

  setMarker(event, map) {
    const marker = new google.maps.Marker({
      position: event.position,
      map,
      draggable: true,
    });
    marker.addListener('dragend', (eve) => {
      //TODO : emit after removing overlay marker.
    });
  }

  updatePointList(path) {
    this.pointList = [];
    const len = path.getLength();
    for (let i = 0; i < len; i++) {
      this.pointList.push(path.getAt(i).toJSON());
    }
    this.polygonLatLng.emit(this.pointList);
    this.selectedArea = google.maps.geometry.spherical.computeArea(path);
  }

  deleteSelectedShape() {
    if (this.selectedShape) {
      this.selectedShape.setMap(null);
      this.selectedArea = 0;
      this.pointList = [];
      // To show:
      this.drawingManager.setOptions({
        drawingControl: true,
      });
    }
  }

  clearSelection() {
    if (this.selectedShape) {
      this.selectedShape.setEditable(false);
      this.selectedShape = null;
      this.pointList = [];
    }
  }
  setSelection(shape) {
    this.clearSelection();
    this.selectedShape = shape;
    shape.setEditable(true);
  }

  mapClicked($event: any) {
    this.lat = $event.coords.lat;
    this.lng = $event.coords.lng;
  }

  markerDragEnd(m: Marker, $event: MouseEvent) {}

  private setCurrentPosition(map) {
    if (this.existingPolygon?.length || this.existingLat) {
      return;
    }
    let lat;
    let lng;
    if (utils.getCountryId() === AppSettings.COLUMBIA_ID) {
      lat = 4.691398780005191;
      lng = -74.09957873025287;
    } else if (utils.getCountryId() === AppSettings.INDIA_ID) {
      lat = 18.50186621225735;
      lng = 73.85748602888162;
    }
    const center = new google.maps.LatLng(lat, lng);
    map.panTo(center);
  }

  private setMapPosition(map, existing) {
    const center = new google.maps.LatLng(existing[0].lat, existing[0].lng);
    map.panTo(center);
  }

  private setMapPositionLat(map, lat, lng) {
    const center = new google.maps.LatLng(lat, lng);
    map.panTo(center);
  }
}
