import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { timer } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { AppSettings } from '../../modules/shared/app.settings';
import { CommonBindingDataService } from '../../modules/shared/services/common-binding-data.service';
import { UserCommonService } from '../../modules/shared/services/user-common.service';
import * as utils from '../../modules/shared/utility-functions/utils';
import { ConfirmPasswordValidator } from './confirm-password.validators';
@Component({
  selector: 'app-password-management',
  templateUrl: './password-management.component.html',
  styleUrls: ['./password-management.component.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PasswordManagementComponent implements OnInit {
  forgetPasswordForm: FormGroup;
  otpForm: FormGroup;
  newPasswordForm: FormGroup;
  displayTime$: any;
  visibleTimer = false;
  isVisibleNewPassword = false;
  countryId = utils.getCountryId();
  isVisibleOTP = false;
  isVisibleForgetPassword = true;
  displayTime: any = '';
  passwordType = 'password';
  repasswordType = 'password';
  isEyeOpen: any = '';
  isEyeOpen2: any = '';
  uniqueKey: any;
  isResendOTP = false;
  public timerInterval: any;
  constructor(
    private formBuilder: FormBuilder,
    private userService: UserCommonService,
    private messageService: MessageService,
    private commonBindingDataService: CommonBindingDataService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.forgetPasswordForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.pattern(AppSettings.NEW_EMAIL_PATTERN)]],
    });
    this.otpForm = this.formBuilder.group({
      input0: ['', [Validators.required, Validators.maxLength(1)]],
      input1: ['', [Validators.required, Validators.maxLength(1)]],
      input2: ['', [Validators.required, Validators.maxLength(1)]],
      input3: ['', [Validators.required, Validators.maxLength(1)]],
      input4: ['', [Validators.required, Validators.maxLength(1)]],
      input5: ['', [Validators.required, Validators.maxLength(1)]],
    });
    this.newPasswordForm = this.formBuilder.group(
      {
        password: ['', [Validators.required, this.noWhitespaceValidator]],
        confirmPassword: ['', [Validators.required, this.noWhitespaceValidator]],
      },
      {
        validator: ConfirmPasswordValidator('password', 'confirmPassword'),
      }
    );
  }
  noWhitespaceValidator(control: FormControl) {
    const isWhitespace = ((control && control.value && control.value.toString()) || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { whitespace: true };
  }
  sendOTP() {
    const obj = {
      countryId: this.countryId,
      otpType: 'CHANGE_PASSWORD',
      userDevice: {},
      email: this.forgetPasswordForm.value.email,
    };
    this.userService.forgotPassword(obj).subscribe((res) => {
      this.isVisibleForgetPassword = false;
      this.isVisibleOTP = true;
      this.isResendOTP = false;
      this.displayTime$ = this.getTimer(2);
    });
  }
  getTimer(minutesTotal: number) {
    let currentTime = utils.minuteToCurrentTime(minutesTotal);
    this.visibleTimer = true;
    return timer(0, 1000).pipe(
      takeUntil(timer(currentTime)),
      map(() => {
        const minutes = utils.getMinutes(currentTime);
        const seconds = utils.getSeconds(currentTime);
        currentTime -= AppSettings.MILI_SECONDS;
        this.visibleTimer = currentTime === AppSettings.MILI_SECONDS ? false : true;
        if (minutes === 0 && seconds === 3) {
          this.isResendOTP = true;
        }
        return `${minutes ? '0' + minutes : '00'}:${seconds ? (seconds < 10 ? `0${seconds}` : seconds) : '00'}`;
      })
    );
  }
  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > AppSettings.CHAR_CODE_31 && (charCode < AppSettings.CHAR_CODE_48 || charCode > AppSettings.CHAR_CODE_57)) {
      return false;
    }
    return true;
  }
  otpController(event, next, prev) {
    if (event.target.value.length < 1 && prev) {
      prev.focus();
    } else if (next && event.target.value.length > 0) {
      next.focus();
    } else {
      return 0;
    }
  }
  verifyOTP() {
    const otp = Object.values(this.otpForm.value).join('');
    const obj = {
      countryId: this.countryId,
      otpType: 'CHANGE_PASSWORD',
      otp,
      userDevice: {},
      email: this.forgetPasswordForm.value.email,
    };
    this.userService.verifyOTP(obj).subscribe((res) => {
      this.isVisibleOTP = false;
      this.isVisibleNewPassword = true;
      this.uniqueKey = res.changePasswordKey;
    });
  }
  resendOTP() {
    if (this.isResendOTP) {
      this.displayTime$ = this.getTimer(2);
      this.sendOTP();
      this.otpForm.reset();
    }
  }
  toggleEye() {
    this.passwordType = this.isEyeOpen ? 'password' : 'text';
    return (this.isEyeOpen = !this.isEyeOpen);
  }
  reToggleEye() {
    this.repasswordType = this.isEyeOpen2 ? 'password' : 'text';
    return (this.isEyeOpen2 = !this.isEyeOpen2);
  }
  resetPassword() {
    if (this.newPasswordForm.value.password === this.newPasswordForm.value.confirmPassword) {
      const obj = {
        countryId: this.countryId,
        newPassword: this.newPasswordForm.value.password,
        changePasswordKey: this.uniqueKey,
        userDevice: {},
      };
      this.userService.resetPassword(obj).subscribe((res) => {
        this.messageService.add({
          severity: 'success',
          key: 'tr',
          summary: 'Success',
          detail: this.commonBindingDataService.getLabel('passwordResetSuccess'),
        });
        this.router.navigate(['/signin']);
      });
    } else {
      return;
    }
  }
}
