<div class="ui-g grid justify-content-between p-3" [ngClass]="cardShadow ? 'card-shadow' : ''" *ngIf="chartHeaderAndControlsFlag">
  <div class="col-auto mr-3">
    <h2 [id]="chartHeaderId" class="mb-0 font-medium text-xl mt-1">{{ header }}</h2>
    <h6 class="font-normal text-base my-auto sub-header">
      <span [class]="item.class" *ngFor="let item of subHeader">
        {{ item.text }}
      </span>
    </h6>
  </div>

  <div class="col grid my-auto" *ngIf="dropdownsNextToLabel">
    <div class="col-12 grid">
      <div [class]="item.columnClass" *ngFor="let item of noOfDropdowns">
        <label translate class="mb-2">{{ item.label | translate }}</label>
        <p-dropdown
          [id]="dropDownId"
          [options]="item.data"
          (onChange)="onChangeDropdown($event, item.label)"
          [(ngModel)]="item.dataKey"
        ></p-dropdown>
      </div>
    </div>
  </div>
  <div
    class="col-auto grid my-auto"
    *ngIf="externalLegends"
    appExternalLegend
    [chartData]="externalLegendLabels"
    (onLegendTrigger)="onLegendTrigger($event)"
  ></div>
  <div class="col-auto text-right my-auto" *ngIf="viewDetailsWithReload">
    <button type="button" [id]="viewChartDetailsButtonId" class="p-button p-button-outlined p-button-sm" (click)="viewChartDetails()">
      {{ commonBindingService.getLabel('view_details') | translate }}
    </button>
    <i [id]="refreshDataButtonId" class="pi pi-refresh" (click)="getChartData()"></i>
  </div>
</div>
<div class="grid p-3">
  <div class="col-12" style="width: 100%; height: 400px">
    <canvas [id]="id"></canvas>
  </div>
</div>
