<section class="grid justify-content-end right-0">
  <div class="col-2 dropdown" [formGroup]="parent">
    <p-dropdown
      [options]="countryList"
      [disabled]="disabled"
      [formControlName]="formCtrlName"
      [placeholder]="'selectCountry' | translate"
      optionLabel="label"
      (onChange)="onChange($event)"
    >
      <ng-template pTemplate="selectedItem">
        <div class="country-item country-item-value" *ngIf="selectedLanguage">
          <span class="'fi fi-{{ selectedLanguage.value | lowercase }}"></span>
          <div>{{ selectedLanguage.label }} ({{ selectedLanguage.value }})</div>
        </div>
      </ng-template>
      <ng-template let-country pTemplate="item">
        <div class="country-item">
          <span class="'fi fi-{{ country.value | lowercase }}"></span>
          <div class="selected-item">{{ country.label }}</div>
        </div>
      </ng-template>
    </p-dropdown>
  </div>
  <div class="col-2 button-div">
    <button
      type="button"
      [icon]="isIcon ? 'pi pi-chevron-right' : 'none'"
      [label]="btnLabel | translate"
      pButton
      class="button"
      iconPos="right"
      (click)="onClick($event)"
      [disabled]="saveDisabled"
    ></button>
  </div>
</section>
