import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { CommonBindingDataService } from '../../../shared/services/common-binding-data.service';

@Component({
  selector: 'app-navtab',
  templateUrl: './navtab.component.html',
})
export class NavtabComponent implements OnInit {
  @Input() type;
  public settingsNavs: MenuItem[];
  activeItem: MenuItem;
  constructor(private router: Router, private commonBindingDataService: CommonBindingDataService) {}

  ngOnInit() {
    this.settingsNavs = [];

    const currentInc = this;
    setTimeout(() => {
      currentInc.showCurrentMenu();
    }, 200);
  }

  showCurrentMenu() {
    switch (this.router.url) {
    }
  }
}
