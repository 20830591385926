import { EventEmitter, Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class UiService {
  private currentLanguage = 'en_US';

  private _languageChangeEvent = new EventEmitter<string>();

  private _sidebarToggledEvent = new Subject<string>();

  private _loaderStateChangeEvent = new EventEmitter<string>();

  private _profileUpdateEvent = new Subject<string>();

  constructor() {}

  get languageChangeEvent(): EventEmitter<string> {
    return this._languageChangeEvent;
  }

  get loaderStatusChangeEvent(): EventEmitter<string> {
    return this._loaderStateChangeEvent;
  }

  changeLanguage(language: string) {
    this.currentLanguage = language;
    this._languageChangeEvent.emit(language);
  }

  changeLoaderStatus(state: string) {
    this._loaderStateChangeEvent.emit(state);
  }

  sidebarToggled() {
    this._sidebarToggledEvent.next('');
  }

  get sidebarToggledEvent(): Subject<string> {
    return this._sidebarToggledEvent;
  }

  profileUpdated() {
    this._profileUpdateEvent.next('');
  }

  get profileUpdatedEvent(): Subject<string> {
    return this._profileUpdateEvent;
  }
}
