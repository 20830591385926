import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import * as utils from '../../shared/utility-functions/utils';
import { RestApiService } from '../services/rest-api.service';

@Injectable()
export class UserCommonService {
  countryId = utils.getCountryId();
  constructor(private restApiService: RestApiService) {}
  signOut(): Observable<any> {
    return this.restApiService.delete('d', '/api/v1/users/sessions', 'page-center');
  }

  signIn(d, data): Observable<any> {
    return this.restApiService.post(d, '/api/v1/users/sessions', data, 'page-center');
  }

  setPassword(data): Observable<any> {
    return this.restApiService.post('password', '/users/set-password', data, 'page-center');
  }

  getUserProfile(): Observable<any> {
    return this.restApiService.get('profile', '/secure/users/profile', 'page-center');
  }

  forgotPassword(data): Observable<any> {
    return this.restApiService.post('password', `/api/v1/${this.countryId}/login/request-otp`, data);
  }
  getAllCustomers(url: any) {
    return this.restApiService.get('users', url);
  }

  getAccessRole(userId): Observable<any> {
    return this.restApiService.get('UserRole', `/api/v1/roles/userId?userId=${userId}`, 'page-center');
  }
  verifyOTP(data): Observable<any> {
    return this.restApiService.post('password', `/api/v1/${this.countryId}/login/verify-otp`, data, 'page-center');
  }
  resetPassword(data): Observable<any> {
    return this.restApiService.post('password', `/api/v1/users/change-password`, data, 'page-center');
  }
}
