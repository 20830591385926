import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component, ElementRef, EventEmitter, Inject, Input, OnInit, Output, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Message, MessageService } from 'primeng/api';
import { environment } from '../../../../../../environments/environment';
import { AppSettings } from '../../../app.settings';
import { CommonBindingDataService } from '../../../services/common-binding-data.service';
import { RestApiService } from '../../../services/rest-api.service';
import { LoaderService } from '../../loader/loader.service';

@Component({
  selector: 'app-upload-file-box',
  templateUrl: './upload-file-box.component.html',
  styleUrls: ['./upload-file-box.component.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UploadFileBoxComponent implements OnInit, AfterViewInit {
  @ViewChild('fileUpload') fileUpload: ElementRef;
  @Input() accept = '';
  @Input() uploadButtonLabel;
  @Output() beforeFileUploadEvent: EventEmitter<any> = new EventEmitter();
  @Output() afterFileUploadEvent: EventEmitter<any> = new EventEmitter();
  @Input() options: any[];
  @Input() src: string;
  @Input() formCtrlName: string;
  @Input() dataCy: string;
  @Input() fileType = '';
  @Input() aspectRatio = false;
  @Input() note = 'ratio';
  @Input() noteWeb = 'ratioWeb';
  @Input() label = '';
  @Input() isRequired = false;
  @Input() disabled = false;
  @Input() validateBannerWebImg = false;
  @Input() validateComboWebImg = false;
  @Input() validateImage = false;
  @Input() validateOneIsToOne = false;
  @Input() imageType = '';
  @Input() imageUploadBoxID = '';
  @Input() imageUploadNoteID = '';
  maxSize = AppSettings.FILE_UPLOAD_MAX_SIZE_IN_BYTE;
  fileUploadUrl: string = AppSettings.UPLOAD_FILE_URL;
  maxSizeMessage = this.commonService.getLabel('file_size_limit');
  message: Message[] = [];
  test: any;
  baseUrl = environment.basePath;
  isValidatedFile = true;
  constructor(
    @Inject(ElementRef) elementRef: ElementRef,

    private translate: TranslateService,
    private loaderService: LoaderService,
    private commonService: CommonBindingDataService,
    private restApiService: RestApiService,
    private http: HttpClient,
    private messageService: MessageService
  ) {
    this.fileUpload = elementRef;
  }
  ngOnInit(): void {}

  ngAfterViewInit() {
    this.fileUpload?.nativeElement?.setAttribute('aria-describedby', `${this.formCtrlName}-help`);
    if (this.dataCy) {
      this.fileUpload?.nativeElement?.setAttribute('data-cy', `${this.formCtrlName || this.dataCy}`);
    }
  }
  onChange($event) {}

  onClick(e) {
    this.fileUpload['el'].nativeElement.querySelector('.p-fileupload-choose').click();
  }
  onUploadError(e) {
    this.message = [];
    this.message.push({
      severity: 'error',
      summary: 'Error',
      detail: this.commonService.getLabel('file_upload_error'),
    });
    this.loaderService.hide();
  }

  onBeforeSend(e, file) {
    // code -> image type send (uncomment this after backend changes are done).
    // e.formData.append('imageType', this.imageType);
    if (this.validateImage && this.validateBannerWebImg) {
      this.checkBannerImageValidation(file);
    } else if (this.validateImage && this.validateComboWebImg) {
      this.checkComboImageValidation(file);
    } else if (this.validateImage && this.validateOneIsToOne) {
      this.checkImageValidationOneIsToOne(file);
    }
    this.loaderService.show('page-center');
  }

  onUpload(e) {
    if (!this.isValidatedFile) {
      this.messageService.add({
        severity: 'error',
        key: 'tr',
        summary: 'Error',
        detail: this.commonService.getLabel(
          this.validateBannerWebImg
            ? 'imageUploadErrorBanner'
            : this.validateComboWebImg
            ? 'imageUploadErrorCombo'
            : 'imageUploadErrorOneIsToOne'
        ),
      });
      this.loaderService.hide();
      return;
    }
    const event = e.originalEvent.body;
    this.beforeFileUploadEvent.emit(event);
    this.loaderService.hide();
  }

  private checkComboImageValidation(event) {
    const file = event.files[0];

    const img = new Image();
    img.src = URL.createObjectURL(file);

    img.onload = () => {
      const width = img.width;
      const height = img.height;
      const currentAspectRatio = width / height;

      if (currentAspectRatio >= AppSettings.COMBO_IMG_RATIO_FROM && currentAspectRatio <= AppSettings.COMBO_IMG_RATIO_TO) {
        this.isValidatedFile = true;
      } else {
        this.isValidatedFile = false;
      }
    };
  }

  private checkBannerImageValidation(event) {
    const file = event.files[0];

    const img = new Image();
    img.src = URL.createObjectURL(file);

    img.onload = () => {
      const width = img.width;
      const height = img.height;
      const currentAspectRatio = width / height;

      if (currentAspectRatio >= AppSettings.BANNER_IMG_RATIO_FROM && currentAspectRatio <= AppSettings.BANNER_IMG_RATIO_TO) {
        this.isValidatedFile = true;
      } else {
        this.isValidatedFile = false;
      }
    };
  }

  private checkImageValidationOneIsToOne(event) {
    const file = event.files[0];

    const img = new Image();
    img.src = URL.createObjectURL(file);

    img.onload = () => {
      const width = img.width;
      const height = img.height;
      const currentAspectRatio = width / height;

      if (currentAspectRatio >= AppSettings.ONE_IS_TO_ONE_FROM && currentAspectRatio <= AppSettings.ONE_IS_TO_ONE_TO) {
        this.isValidatedFile = true;
      } else {
        this.isValidatedFile = false;
      }
    };
  }
}
