import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-pie-chart',
  templateUrl: './pie-chart.component.html',
})
export class PieChartComponent implements OnInit {
  @Input() data: any[] = [];
  @Input() header: string;

  colorArray = ['#FF6384', '#36A2EB', '#FFCE56', '#7753FF', '#9CCC65'];
  headerStr = 'Header';
  buildData: any;

  constructor() {
    this.buildData = {
      labels: [],
      datasets: [
        {
          data: [],
          backgroundColor: [],
          hoverBackgroundColor: [],
        },
      ],
    };
  }
  ngOnInit() {
    this.headerStr = this.header;
    this.data.forEach((item, index) => {
      let color = this.getRandomColor();
      if (this.colorArray.length > index) {
        color = this.colorArray[index];
      }
      this.buildData.labels.push(item.label);
      this.buildData.datasets[0].data.push(item.value);
      this.buildData.datasets[0].backgroundColor.push(color);
      this.buildData.datasets[0].hoverBackgroundColor.push(color);
    });
  }

  getRandomColor() {
    return '#' + Math.random().toString(16).substr(-6);
  }
}
