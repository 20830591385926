<section class="image-box">
  <!-- <a class="icon-position"><em class="pi pi-ellipsis-v"></em><em class="em-second pi pi-ellipsis-v"></em></a> -->
  <a class="icon-position flex justify-content-end" (click)="onClick($event)"> <a class="pi pi-times-circle"> </a> </a>
  <div>
    <img [src]="src" alt="" />
  </div>
  <div class="dropdown-box">
    <p-dropdown
      [(ngModel)]="selectedValue"
      class="image-box-dropdown"
      [options]="dropdownSelection"
      optionLabel="name"
      optionValue="value"
      (onChange)="onChange($event)"
    ></p-dropdown>
  </div>
  <div class="mt-1">
    <p-inputNumber
      #Input
      [min]="1"
      mode="decimal"
      (ngModelChange)="positionChange($event)"
      [(ngModel)]="selectedPosition"
      [useGrouping]="false"
      [placeholder]="'position' | translate"
    >
    </p-inputNumber>
  </div>
</section>
