import { Directive, ElementRef, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';

@Directive({
  selector: '[appExternalLegend]',
})
export class ExternalLegendDirective implements OnChanges {
  @Input() chartData: Array<{
    label: 'string';
    color: 'string';
    withCoupon: Array<number>;
    withOutCoupon: Array<number>;
    isActive: boolean;
  }>;
  @Output() onLegendTrigger = new EventEmitter();
  isActive: boolean;
  isChanged = false;
  constructor(private element: ElementRef) {}
  ngOnChanges(changes: SimpleChanges) {
    if (!this.isChanged && this.chartData.length > 0) {
      this.chartData?.map((ele, i) => {
        const innerTag = document.createElement('div');
        const legendController = document.createElement('div');
        legendController.style.width = '15px';
        legendController.style.height = '15px';
        legendController.style.borderRadius = '50%';
        legendController.style.marginRight = '5px';
        legendController.style.backgroundColor = ele.color;
        const legendLabel = document.createElement('div');
        legendLabel.innerText = ele.label;
        innerTag.appendChild(legendController);
        innerTag.appendChild(legendLabel);
        innerTag.style.display = 'flex';
        innerTag.style.alignItems = 'center';
        innerTag.style.marginRight = '10px';
        innerTag.style.cursor = 'pointer';
        (this.element.nativeElement as Element).appendChild(innerTag);
        this.isChanged = true;
        innerTag.addEventListener('click', () => {
          this.chartData[i].isActive = !this.chartData[i].isActive;
          if (!this.chartData[i].isActive) {
            (this.element.nativeElement.childNodes[i] as HTMLDivElement).style.textDecoration = 'line-through';
            (this.element.nativeElement.childNodes[i] as HTMLDivElement).style.textDecorationThickness = '2px';
          } else {
            (this.element.nativeElement.childNodes[i] as HTMLDivElement).style.textDecoration = 'none';
          }
          this.onLegendTrigger.emit({ chartData: this.chartData });
        });
      });
    }
  }
}
