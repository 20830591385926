import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-autocomplete',
  templateUrl: './autocomplete.component.html',
  styleUrls: ['./autocomplete.component.scss'],
})
export class AutocompleteComponent implements OnInit {
  @Input() selectedItem: any;
  @Input() arrayPayloadAuto: any;
  @Input() field: any;
  @Input() multiple: any = false;
  @Output() onValueChange = new EventEmitter<any>();
  @Output() completeMethod = new EventEmitter<any>();
  @Output() unselect = new EventEmitter<any>();
  @Output() clearEvent = new EventEmitter<any>();
  @Input() datakey;
  @Input() label = '';
  @Input() forceSelection = false;
  @Input() isRequired = false;
  @Input() isDisable = false;
  constructor() {}

  ngOnInit(): void {}

  onAutoComplete(changes) {
    this.completeMethod.emit(changes);
  }

  onChange(changes) {
    this.onValueChange.emit(changes);
  }

  onUnselect(changes) {
    this.unselect.emit(changes);
  }

  onClear(event) {
    this.clearEvent.emit(event);
  }
}
