import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { MissingTranslationHandler, TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { ConfirmationService } from 'primeng/api';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { BlockUIModule } from 'primeng/blockui';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { ChartModule } from 'primeng/chart';
import { CheckboxModule } from 'primeng/checkbox';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { EditorModule } from 'primeng/editor';
import { FileUploadModule } from 'primeng/fileupload';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { MenuModule } from 'primeng/menu';
import { MultiSelectModule } from 'primeng/multiselect';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { PanelModule } from 'primeng/panel';
import { PanelMenuModule } from 'primeng/panelmenu';
import { RadioButtonModule } from 'primeng/radiobutton';
import { RatingModule } from 'primeng/rating';
import { TableModule } from 'primeng/table';
import { TabMenuModule } from 'primeng/tabmenu';
import { TabViewModule } from 'primeng/tabview';
import { NavtabComponent } from './components/navtab/navtab.component';

import { AgmCoreModule } from '@agm/core';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { CardModule } from 'primeng/card';
import { ChipsModule } from 'primeng/chips';
import { DividerModule } from 'primeng/divider';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputSwitchModule } from 'primeng/inputswitch';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { TreeModule } from 'primeng/tree';
import { environment } from '../../../environments/environment';
import { AdminService } from '../admin/admin.service';
import { SeoService } from '../product/services/seo.service';
import * as utils from '../shared/utility-functions/utils';
import { AssignSearchComponent } from './components/assign-search/assign-search.component';
import { BarChartComponent } from './components/bar-chart/bar-chart.component';
import { CalendarComponent } from './components/calendar/calendar.component';
import { ChartComponent } from './components/chart/chart.component';
import { AutocompleteComponent } from './components/elements/autocomplete/autocomplete.component';
import { CancelSaveButtonComponent } from './components/elements/cancel-save-button/cancel-save-button.component';
import { CategoryPositionComponent } from './components/elements/category-position/category-position.component';
import { CountrySaveNextButtonComponent } from './components/elements/country-save-next-button/country-save-next-button.component';
import { DropdownComponent } from './components/elements/dropdown/dropdown.component';
import { EditorComponent } from './components/elements/editor/editor.component';
import { ImageBoxWithDropdownComponent } from './components/elements/image-box-with-dropdown/image-box-with-dropdown.component';
import { ImageBoxComponent } from './components/elements/image-box/image-box.component';
import { InputComponent } from './components/elements/input/input.component';
import { TextInputComponent } from './components/elements/text-input/text-input.component';
import { UploadFileBoxComponent } from './components/elements/upload-file-box/upload-file-box.component';
import { FileUploaderComponent } from './components/file-uploader/file.uploader.component';
import { HeaderComponent } from './components/header/header.component';
import { HeaderService } from './components/header/header.service';
import { LayoutComponent } from './components/layout/layout.component';
import { LoaderComponent } from './components/loader/loader.component';
import { LoaderService } from './components/loader/loader.service';
import { MapComponent } from './components/map/map.component';
import { GridService } from './components/mi-grid/grid.service';
import { MiGridComponent } from './components/mi-grid/mi-grid.component';
import { PageHeaderComponent } from './components/page-header/page-header.component';
import { PieChartComponent } from './components/pie-chart/pie-chart.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { SkuGridComponent } from './components/sku-grid/sku-grid.component';
import { CurrencyFormatterDirective } from './directives/currency-formatter.directive';
import { ExternalLegendDirective } from './directives/external-legend.directive';
import { DisableControlDirective } from './directives/input-disabled.directive';
import { SearchLocationDirective } from './directives/search-location.directive';
import { AgoPipe } from './pipes/ago.pipe';
import { DateFormatPipe } from './pipes/dateformat.pipe';
import { DateTimeFormatPipe } from './pipes/datetimeformat.pipe';
import { MinutsFormatPipe } from './pipes/minutesformat.pipe';
import { AuthenticationValidationService } from './services/authentication-validation.service';
import { CommonBindingDataService } from './services/common-binding-data.service';
import { CommonMessageTransferService } from './services/common-message-transfer.service';
import { MiMissingTranslationHandlerService } from './services/mi-missing-translation-handler.service';
import { MiTranslateLoaderService } from './services/mi-translate-loader.service';
import { RestApiService } from './services/rest-api.service';
import { SidebarService } from './services/sidebar.service';
import { StorageService } from './services/storage.service';
import { UiService } from './services/ui.service';
import { UserCommonService } from './services/user-common.service';

@NgModule({
  imports: [
    CommonModule,
    DividerModule,
    TreeModule,
    InputSwitchModule,
    MultiSelectModule,
    RouterModule,
    PanelMenuModule,
    RatingModule,
    ButtonModule,
    EditorModule,
    ConfirmDialogModule,
    CalendarModule,
    ChartModule,
    MenuModule,
    DropdownModule,
    RadioButtonModule,
    CheckboxModule,
    DialogModule,
    FormsModule,
    FileUploadModule,
    ReactiveFormsModule,
    AutoCompleteModule,
    BlockUIModule,
    OverlayPanelModule,
    PanelModule,
    TabMenuModule,
    TabViewModule,
    TableModule,
    ProgressSpinnerModule,
    ChipsModule,
    BreadcrumbModule,
    InputTextareaModule,
    InputNumberModule,
    AgmCoreModule.forRoot({
      apiKey: environment.mapApiKey,
      libraries: ['places', 'drawing', 'geometry'],
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: MiTranslateLoaderService,
      },
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: MiMissingTranslationHandlerService,
      },
    }),
    CardModule,
  ],
  declarations: [
    HeaderComponent,
    SidebarComponent,
    PageHeaderComponent,
    DisableControlDirective,
    SearchLocationDirective,
    AssignSearchComponent,
    PieChartComponent,
    BarChartComponent,
    LoaderComponent,
    FileUploaderComponent,
    AgoPipe,
    DateFormatPipe,
    DateTimeFormatPipe,
    MinutsFormatPipe,
    NavtabComponent,
    MiGridComponent,
    LayoutComponent,
    InputComponent,
    DropdownComponent,
    EditorComponent,
    ImageBoxWithDropdownComponent,
    UploadFileBoxComponent,
    CalendarComponent,
    ImageBoxComponent,
    CancelSaveButtonComponent,
    CountrySaveNextButtonComponent,
    MapComponent,
    CategoryPositionComponent,
    AutocompleteComponent,
    TextInputComponent,
    ChartComponent,
    CurrencyFormatterDirective,
    ExternalLegendDirective,
    SkuGridComponent,
  ],
  exports: [
    HeaderComponent,
    SidebarComponent,
    PageHeaderComponent,
    TranslateModule,
    ConfirmDialogModule,
    SearchLocationDirective,
    DisableControlDirective,
    AssignSearchComponent,
    PieChartComponent,
    BarChartComponent,
    LoaderComponent,
    FileUploaderComponent,
    AgoPipe,
    DateFormatPipe,
    DateTimeFormatPipe,
    MinutsFormatPipe,
    NavtabComponent,
    MiGridComponent,
    InputComponent,
    DropdownComponent,
    EditorComponent,
    ImageBoxWithDropdownComponent,
    UploadFileBoxComponent,
    CalendarComponent,
    ImageBoxComponent,
    CancelSaveButtonComponent,
    CountrySaveNextButtonComponent,
    MapComponent,
    CategoryPositionComponent,
    AutocompleteComponent,
    TextInputComponent,
    CardModule,
    ChartComponent,
    CurrencyFormatterDirective,
    ExternalLegendDirective,
    SkuGridComponent,
  ],
})
export class SharedModule {
  constructor(translate: TranslateService) {
    const languageId = utils.getLanguage();
    // this language will be used as a fallback when a translation isn't found in the current language
    translate.setDefaultLang(languageId);
    // the lang to use, if the lang isn't available, it will use the current loader to get them
    if (utils.getLanguage()) {
      translate.use(utils.getLanguage());
    } else {
      translate.use(languageId);
    }
  }

  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [
        LoaderService,
        RestApiService,
        UiService,
        UserCommonService,
        StorageService,
        CommonMessageTransferService,
        CommonBindingDataService,
        SearchLocationDirective,
        DisableControlDirective,
        AuthenticationValidationService,
        ConfirmationService,
        TranslateService,
        SidebarService,
        GridService,
        AdminService,
        HeaderService,
        SeoService,
      ],
    };
  }
}
