import { Component, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { Subscription } from 'rxjs';
import { AppSettings } from '../../app.settings';
import { CommonBindingDataService } from '../../services/common-binding-data.service';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent implements OnInit, OnChanges, OnDestroy {
  items: MenuItem[];
  breadCrumbArray: any[];
  headerStr: string;
  isDashboard: boolean;
  isDashboardViewDetails: string[];
  subscription: Subscription;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public commonBindingDataService: CommonBindingDataService,
    private fb: FormBuilder
  ) {
    this.breadCrumbArray = [];
    router.events.subscribe((event) => {
      this.isDashboard =
        this.router.url.includes(AppSettings.DEFAULT_COMPONENT_ROUTE.toLowerCase()) &&
        !this.router.url.match('/' + AppSettings.DEFAULT_COMPONENT_ROUTE.toLowerCase() + '/');
      const viewDetailsRoutes = [
        'sales-history-by-period',
        'sales-by-category',
        'sales-by-customers',
        'sales-by-products',
        'sales-by-stores',
      ];
      this.isDashboardViewDetails = viewDetailsRoutes.filter((ele) => router.url.includes(ele));
      if (event instanceof NavigationEnd) {
        this.fillBreadCrumArray();
      }
    });
  }

  ngOnInit() {
    let id = '';
    this.subscription = this.commonBindingDataService.getUserID().subscribe((rel) => {
      const url = this.router.url;
      if (url.includes('customer-management')) {
        id = rel.text;
        this.breadCrumbArray.forEach((ele, index) => {
          if (index !== 0) {
            ele.url = ele.url + '/' + id;
          }
        });
      }
    });
  }

  ngOnChanges() {
    this.fillBreadCrumArray();
  }

  fillBreadCrumArray() {
    while (this.breadCrumbArray.length > 0) {
      this.breadCrumbArray.pop();
    }
    const currentObj = this;
    const url = this.router.url;
    let updatedUrl = url;
    if (url.lastIndexOf('?') > 0) {
      updatedUrl = url.split('?')[0];
    }
    const urlSplitArray = updatedUrl.split('/');
    let urlBuilder = '#';
    const urlLength = urlSplitArray.length;
    let count = 0;
    urlSplitArray.forEach((element) => {
      if (AppSettings.IGNORE_LINK.indexOf(element) === -1) {
        urlBuilder += element + '/';
        let visiableStr = '';
        let relativeUrl = '';
        if (element.length === 0 || element === '') {
          visiableStr = '';
          relativeUrl = '';
        } else {
          visiableStr = currentObj.capitalizeFirstLetter(element);
          relativeUrl = urlBuilder;
          if (relativeUrl.endsWith('/')) {
            relativeUrl = relativeUrl.substring(0, relativeUrl.lastIndexOf('/'));
            relativeUrl = relativeUrl;
          }
        }
        if (++count === urlLength) {
          if (url.split('?')[1]) {
            relativeUrl = relativeUrl + '?' + url.split('?')[1];
          }
        }
        if (decodeURIComponent(visiableStr) !== '') {
          if (
            !this.containsNumbers(visiableStr) ||
            visiableStr.includes('Inventory_management') ||
            visiableStr.includes('Add_product') ||
            visiableStr.includes('Upload_products_management') ||
            visiableStr.includes('Terms_and_conditions')
          ) {
            if (visiableStr.includes('Inventory_management')) {
              visiableStr = 'Inventory_management';
            } else if (visiableStr.includes('Add_product')) {
              visiableStr = 'Add_product';
            } else if (visiableStr.includes('Upload_products_management')) {
              visiableStr = 'Upload_products_management';
            } else if (visiableStr.includes('Terms_and_conditions')) {
              visiableStr = 'Terms_and_conditions';
            }
            currentObj.breadCrumbArray.push({ url: relativeUrl, name: decodeURIComponent(visiableStr) });
          }
        }
      }
    });
    this.headerStr = this.breadCrumbArray[this.breadCrumbArray.length - 1].name.replace('>', '');
    this.breadCrumbArray[this.breadCrumbArray.length - 1].name = this.breadCrumbArray[this.breadCrumbArray.length - 1].name.replace(
      '>',
      ''
    );
  }

  containsNumbers(str) {
    return /\d/.test(str);
  }

  capitalizeFirstLetter(element) {
    if (element) {
      element = element.replace(/-/g, '_');
      const words = element.split(' ');
      let stringB = '';
      if (words.length >= 1) {
        for (const index of words.keys()) {
          const w = words[index];
          if (w.length > 0) {
            stringB += w.charAt(0).toUpperCase() + w.slice(1);
          } else {
            stringB += w;
          }
          stringB += ' ';
        }
        return stringB.trim();
      }
    }
    return element;
  }
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
