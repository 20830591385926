import { Injectable } from '@angular/core';
import { ChartData, ChartOptions } from 'chart.js/auto';
import * as moment from 'moment';
import { environment } from '../../../../../environments/environment';
import * as utils from '../../../shared/utility-functions/utils';
import { AppSettings } from '../../app.settings';
import { CommonBindingDataService } from '../../services/common-binding-data.service';
import { RestApiService } from '../../services/rest-api.service';

@Injectable({
  providedIn: 'root',
})
export class ChartService {
  chartData: ChartData;
  chartOptions: ChartOptions;
  listOfCategories: any;
  orderOriginGridURL = '/api/v1/dashboard/order-origin';
  salesHistoryGridURL = '/api/v1/dashboard/sales-history';
  salesByCategoryGridURL = '/api/v1/dashboard/sales-category';
  couponConversionByHistoryGridURL = '/api/v1/dashboard/coupon-conversion-history';
  couponGenerationByCategoryGridURL = '/api/v1/dashboard/coupon-conversion-category';
  languageId = utils.getLanguage();
  headerDataValue: any;

  constructor(private restApiService: RestApiService, private commonBindingService: CommonBindingDataService) {}

  getChartDetails(url, params) {
    return this.restApiService.get('chart', `${environment.basePath}${url}`, 'page-center');
  }

  buildUrlForSearch(url) {
    let params = 'search=' + '' + '&offset=' + 0;
    params += '&languageCode=';
    params += utils.getLanguage();
    params += '&countryId=';
    params += utils.getCountryId();
    let buildUrl = url;
    if (url.lastIndexOf('?') < 0) {
      buildUrl += '?';
    } else {
      buildUrl += '&';
    }
    buildUrl += params;
    return buildUrl;
  }

  isAnArrayWithElements(array: Array<any>) {
    return Array.isArray(array) && array.length > 0;
  }
  isSearchParameterMatched(comparableData, actucalData) {
    return comparableData.toLowerCase().includes(actucalData.toLowerCase());
  }
  iterateBasedOnIndex(iterableArray: Array<any>, index, value: any[], divide) {
    let dataSet: any[] = [];
    iterableArray.forEach((ele, i: any) => {
      value.forEach((key, j) => {
        const flag = divide != null ? ele[divide] : 1;
        dataSet = [...dataSet];
        dataSet[ele[index] - 1] = ele[key] / flag;
      });
    });
    return dataSet;
  }

  iterateBasedOnValue(iterableArray: Array<any>, labelArray: Array<any>, labelKey: string, valueKey: any) {
    const dataSet = [];
    iterableArray.forEach((ele: any) => {
      const index = labelArray.indexOf(ele?.[labelKey]);
      dataSet[index] = ele?.[valueKey];
    });
    return dataSet;
  }

  convert(currencyAmount, withCurrency, customMaxFractionDigits) {
    const commonData = this.commonBindingService.getCurrentCurrencyAndLocaleData();
    const locale = commonData.localeCode;
    const currency = commonData.currencyCode;
    const maxFractionDigits = commonData.maxFractionDigits;
    return utils.valueInRange(currencyAmount, 10e8)
      ? utils.convertNumberIntoCurrency(
          currencyAmount,
          10e8,
          currency,
          locale,
          withCurrency,
          customMaxFractionDigits ? maxFractionDigits : 2
        ) + this.commonBindingService.getCurrentCurrencyAndLocaleData().currencyAbbrevations[0]
      : utils.valueInRange(currencyAmount, 10e5)
      ? utils.convertNumberIntoCurrency(
          currencyAmount,
          10e5,
          currency,
          locale,
          withCurrency,
          customMaxFractionDigits ? maxFractionDigits : 2
        ) + this.commonBindingService.getCurrentCurrencyAndLocaleData().currencyAbbrevations[1]
      : utils.valueInRange(currencyAmount, 10e2)
      ? utils.convertNumberIntoCurrency(
          currencyAmount,
          10e2,
          currency,
          locale,
          withCurrency,
          customMaxFractionDigits ? maxFractionDigits : 2
        ) + this.commonBindingService.getCurrentCurrencyAndLocaleData().currencyAbbrevations[2]
      : Math.abs(Number(currencyAmount));
  }

  getWeekNumbers() {
    const weeks = [];
    for (let i = 1; i <= moment().weeksInYear(); i++) {
      weeks.push(i);
    }

    return weeks;
  }

  public get headerData() {
    return this.headerDataValue;
  }

  public set headerData(v) {
    this.headerDataValue = v;
  }

  toggleHistorical(obj) {
    obj = { ...obj, projection: this.getCurrentProjection() };
    return obj;
  }

  setHistoryBtn(projectionValue: boolean) {
    sessionStorage.setItem('projection', JSON.stringify(projectionValue));
  }
  getHistoryBtn(): boolean {
    return Boolean(JSON.parse(sessionStorage.getItem('projection')));
  }

  getCurrentProjection() {
    return this.getHistoryBtn() ? AppSettings.HISTORICAL : AppSettings.ACTUAL;
  }
}
