import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { RestApiService } from '../../shared/services/rest-api.service';
import * as utils from '../../shared/utility-functions/utils';
import { AddAttributesInterface } from '../components/attributes-master/interfaces/attributes-master.interface';
import { GetAttributes } from '../interfaces/products.interface';

@Injectable({
  providedIn: 'root',
})
export class AttributesService {
  baseUrl: string;
  countryId: string;
  url: string;
  languageId;

  constructor(private restApiService: RestApiService) {
    this.baseUrl = environment.basePath;
    this.url = `/api/v1/${utils.getCountryId()}/`;
    this.languageId = utils.getLanguage();
  }

  search(payload: GetAttributes) {
    return this.restApiService.post('attributes', `${this.url}attributes/search`, payload);
  }

  addProductAttribute(productId: string, payload: any[]) {
    return this.restApiService.put('attributes', `${this.url}products/${productId}/attributes`, payload);
  }

  searchListAttributes(type: string, url: string, payload: any): Observable<any> {
    return this.restApiService.post(type, url, payload, 'page-center');
  }

  addUpdateAttributes(payload: AddAttributesInterface, attributeId: string) {
    if (attributeId) {
      return this.restApiService.put('attributes', `${this.url}attributes/${attributeId}?languageCode=${this.languageId}`, payload);
    }
    return this.restApiService.post('attributes', `${this.url}attributes?languageCode=${this.languageId}`, payload);
  }

  getAttributeById(url) {
    return this.restApiService.get('attributes', url);
  }
}
