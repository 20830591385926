<div class="p-fluid grid">
  <div class="col-12">
    <div class="label-text">
      <label translate>{{ label }}</label>
    </div>
    <span>
      <p-inputNumber
        #Input
        inputId="locale-us"
        mode="decimal"
        inputId="withoutgrouping"
        (onInput)="onChange($event)"
        [useGrouping]="false"
        [(ngModel)]="value"
        [min]="min"
      >
      </p-inputNumber>
    </span>
  </div>
</div>
