<div class="label-text overflowLabel-class">
  <label translate for="Input" class="block">{{ label | translate }} <span *ngIf="isRequired" class="required-star">*</span></label>
</div>

<span class="p-fluid">
  <p-inputNumber
    *ngIf="inputType === 'number'"
    #Input
    (onInput)="onChange($event)"
    class=""
    [disabled]="disabled"
    [min]="0"
    [(ngModel)]="selectedItem"
    [useGrouping]="false"
  >
  </p-inputNumber>
  <input
    #Input
    *ngIf="inputType === 'text'"
    (input)="onChange($event)"
    class="input-text"
    [disabled]="disabled"
    [(ngModel)]="selectedItem"
    pInputText
  />
</span>
