import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RestApiService } from '../../services/rest-api.service';

@Injectable()
export class HeaderService {
  constructor(private restApiService: RestApiService) {}

  public getCountryList(url: string): Observable<{}> {
    return this.restApiService.get('country-list', url, 'page-center');
  }

  public updatePassword(url: string, payload: any) {
    return this.restApiService.put('update-password', url, payload);
  }
}
