<div class="userform">
  <form [formGroup]="SKUForm">
    <div class="grid col-12 pb-0">
      <div class="col-12 pb-0 sku-button-div">
        <button
          pButton
          id="addBanner-selectProductsBySearchInput-backBtn"
          data-cy="cancel-btn"
          type="button"
          [label]="'back' | translate"
          class="p-button-outlined"
          (click)="onSKUBackClick()"
        ></button>
      </div>
    </div>

    <div class="grid col-12">
      <div class="form-control col-12">
        <app-input
          id="addBanner-applicableOn-selectProductsBySearchInput"
          [parent]="SKUForm"
          [label]="'ProductSKU' | translate"
          formCtrlName="productSKU"
          [isRequired]="true"
          inputType="textarea"
        ></app-input>
        <span class="sku-info" translate> addCommaSeparatedProductsSKU </span>
      </div>
    </div>

    <div class="grid col-12">
      <div class="col-12 sku-button-div">
        <p-button
          id="addBanner-selectProductsBySKU-validateBtn"
          [label]="'validate' | translate"
          [disabled]="!SKUForm.valid"
          (onClick)="onValidButtonClick()"
        >
        </p-button>
        <p-button
          id="addBanner-selectProductsBySKU-addBtn"
          [label]="addBtnLabel | translate"
          [disabled]="isSkuListValid()"
          (onClick)="onEmitProducts()"
        >
        </p-button>
      </div>
    </div>

    <div class="grid col-12" *ngIf="validateSKUResponseArray.length">
      <div class="col-12 sku-button-div">
        <p-table [value]="validateSKUResponseArray" [paginator]="false" responsiveLayout="stack" [breakpoint]="'400px'" class="col-12">
          <ng-template pTemplate="header">
            <tr>
              <ng-container *ngFor="let col of columnDefs; first as isFirst; index as i; trackBy as trackingId">
                <th
                  [pSortableColumn]="col.sort ? col.field : ''"
                  [ngClass]="col.headerClass"
                  [ngStyle]="{ width: col.width ? col.width : 'auto' }"
                >
                  {{ col.header }}

                  <p-sortIcon *ngIf="col.sort" [field]="col.field"></p-sortIcon>
                </th>
              </ng-container>
            </tr>
          </ng-template>

          <ng-template pTemplate="body" let-rowData let-columns="columnDefs" let-rowIndex="rowIndex">
            <tr [pSelectableRowIndex]="rowIndex" [class]="rowIndex % 2 === 0 ? 'even-row' : 'odd-row'">
              <td
                *ngFor="let col of columnDefs; trackBy as trackingId"
                [class]="statusColArray.includes(col.field) ? getClassForStatus(rowData[col.field]) : col.class"
              >
                <div class="tabel-body-value col-12">
                  <div [class]="col.class" class="col-6 p-0">
                    {{ rowData[col.field] }}
                  </div>
                  <div class="edit-sku-table col-2 p-0">
                    <span
                      (click)="onEditSKUClick(rowData)"
                      *ngIf="col.field === 'sku' && !rowData['validSku']"
                      class="pi pi-pencil edit-sku-table"
                    ></span>
                    <span
                      (click)="onDeleteSKUClick(rowData)"
                      *ngIf="col.field === 'sku' && !rowData['validSku']"
                      class="pi pi-trash edit-sku-table ml-2"
                    ></span>
                  </div>
                </div>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </div>
  </form>
</div>

<p-dialog
  [header]="'editSKU' | translate"
  [(visible)]="editSkuPopup"
  [modal]="true"
  [style]="{ height: 'auto' }"
  [maximizable]="true"
  [draggable]="false"
  [resizable]="true"
>
  <ng-template pTemplate="content">
    <div class="col-12">
      <form [formGroup]="editProductSKUForm">
        <div class="form-control col-12">
          <app-input
            [parent]="editProductSKUForm"
            label="SKU"
            formCtrlName="editProductSKU"
            dataCy="editProductSKUForm-email"
            [isRequired]="true"
            inputType="text"
          >
          </app-input>
        </div>
      </form>
    </div>
  </ng-template>
  <ng-template pTemplate="footer">
    <div class="dialog-footer-btn">
      <button pButton [label]="'cancel' | translate" (click)="hideDialog()" class="p-button p-button-outlined"></button>
      <button pButton [label]="'save' | translate" (click)="saveSKU()" class="p-button"></button>
    </div>
  </ng-template>
</p-dialog>
