import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Chart, ChartData, ChartOptions, ChartType, registerables } from 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import * as moment from 'moment';
import * as utils from '../../../shared/utility-functions/utils';
import { CommonBindingDataService } from '../../services/common-binding-data.service';
import { GridService } from '../mi-grid/grid.service';
import { ChartService } from './chart.service';
Chart.register(...registerables, ChartDataLabels);
@Component({
  selector: 'app-chart',
  templateUrl: './chart.component.html',
  styleUrls: ['../../../dashboard/styles/dashboard.scss', './chart.component.scss'],
})
export class ChartComponent implements OnInit, OnChanges {
  @Input() dropDownId = '';
  @Input() viewChartDetailsButtonId = '';
  @Input() refreshDataButtonId = '';
  @Input() chartHeaderId = '';
  @Input() type!: ChartType;
  @Input() id = '';
  @Input() externalLegendLabels: any[];
  @Input() data!: ChartData;
  @Input() options!: ChartOptions;
  @Input() header: string;
  @Input() subHeader: [{ text: 'string'; class: 'string' }];
  @Input() firstDropdownHeader: string;
  @Input() secondDropdownHeader: string;
  @Input() dropdownsNextToLabel: boolean;
  @Input() commonHeaderForMultipleCharts: boolean;
  @Output() fetchData: EventEmitter<any> = new EventEmitter();
  @Output() fetchChartDetails: EventEmitter<any> = new EventEmitter();
  @Input() url = '';
  @Input() params = '';
  @Input() noOfDropdowns: Array<any> = [];

  @Input() viewDetailsWithReload = false;
  @Input() chartHeaderAndControlsFlag = false;
  @Input() cardShadow = true;

  @Input() extraFields: any = {};
  @Input() externalLegends = false;
  @Output() onChangeDropdownEvent = new EventEmitter();
  @Output() chartDataResponseEvent = new EventEmitter();
  @Output() getChartDataEvent = new EventEmitter();
  @Output() externalLegendsEvents = new EventEmitter();
  headerStr = 'Header';
  monthList: Array<any> = [];
  selectedMonth;
  yearList: Array<any> = [];
  selectedYear;
  currentChart!: Chart<any>;
  totalRecords = 0;
  sortF = '';
  sortO = '';
  offset = 0;
  searchText = '';
  startDate = 0;
  endDate = 0;
  // setRowLength = 0;
  languageId = utils.getLanguage();
  countryId = utils.getCountryId();

  constructor(
    private elm: ElementRef,
    public commonBindingService: CommonBindingDataService,
    private gridService: GridService,
    private chartService: ChartService
  ) {
    this.resetData();
    this.setMonthDropDown();
    this.setYearDropDown();
  }
  async ngOnChanges(changes: SimpleChanges) {
    if (changes.url || changes.extraFields) {
      if (Array.isArray(this.extraFields.limit) && this.extraFields.limit.length > 0) {
        this.extraFields.limit = this.extraFields.limit[this.extraFields.limit.length - 1];
      }
      await this.getChartData();
    }
    this.plotGraph();
  }
  getChartData() {
    const extraFields = Object.keys(this.extraFields);
    let params = `offset=${this.offset}&languageCode=${this.languageId}
                      &countryId=${this.countryId}&sortColumn=${this.sortF}&sortType=${this.sortO}`;

    if (this.searchText) {
      params += `&search=${this.searchText}`;
    }

    if (extraFields.length) {
      extraFields.map((ele) => {
        params.includes(ele);
        params += `&${ele}=${this.extraFields[ele]}`;
      });
    }
    if (this.startDate) {
      params += `&startDate=${this.startDate}`;
    }
    if (this.endDate) {
      params += `&endDate=${this.endDate}`;
    }
    let buildUrl = this.url;
    if (this.url.lastIndexOf('?') < 0) {
      buildUrl += '?';
    } else {
      buildUrl += '&';
    }
    buildUrl += params;
    this.gridService.loadGridData(buildUrl).subscribe((res: any) => {
      this.chartDataResponseEvent.emit({ res, url: this.url });
      this.plotGraph();
    });
  }

  plotGraph() {
    setTimeout(() => {
      if (this.currentChart) {
        this.resetData();
      }
      this.currentChart = new Chart(this.id, {
        type: this.type,
        data: this.data,
        options: this.options,
      });
      this.currentChart.update();
    });
  }

  ngOnInit() {
    this.headerStr = this.header;
    this.refreshData();
  }

  resetData() {
    this.currentChart?.destroy();
  }

  getRandomColor() {
    return '#' + Math.random().toString(16).substr(-6);
  }

  setMonthDropDown() {
    this.monthList = [];
    const months = moment.months();
    months.forEach((element, idx) => {
      this.monthList.push({
        label: element,
        value: idx + 1,
      });
    });
    this.selectedMonth = moment().month() + 1;
  }

  setYearDropDown() {
    this.yearList = [];
    for (let yearCount = 10; yearCount >= 0; yearCount--) {
      this.yearList.push({
        label: moment().year() - yearCount,
        value: moment().year() - yearCount,
      });
    }
    this.selectedYear = moment().year();
  }

  refreshData() {
    this.fetchData.emit({
      month: this.selectedMonth,
      year: this.selectedYear,
    });
  }

  viewChartDetails() {
    if (
      this.subHeader
        .map((ele) => ele.text)
        .join('')
        .replace(' ', '')
        .includes(
          this.commonBindingService.getLabel('history', false) + this.commonBindingService.getLabel('period_with_parenthsis', false)
        )
    ) {
      this.fetchChartDetails.emit('/dashboard/sales-history-by-period');
    } else if (
      this.subHeader
        .map((ele) => ele.text)
        .join('')
        .replace(' ', '')
        .includes(this.commonBindingService.getLabel('sales', false) + this.commonBindingService.getLabel('category_parenthsis', false))
    ) {
      this.fetchChartDetails.emit('/dashboard/sales-by-category');
    }
  }

  onChangeDropdown(event, label) {
    this.onChangeDropdownEvent.emit({ event: event.value, label, chartHeader: this.header, url: this.url });
  }
  onLegendTrigger(event) {
    if (this.chartService.isSearchParameterMatched(this.url, this.chartService.couponConversionByHistoryGridURL)) {
      this.legendAction(event);
    } else if (this.chartService.isSearchParameterMatched(this.url, this.chartService.couponGenerationByCategoryGridURL)) {
      this.legendAction(event);
    }
  }

  legendAction(event) {
    if (this.chartService.isAnArrayWithElements(event.chartData)) {
      this.currentChart.data.datasets.map((ele, i) => {
        if (event.chartData[i]?.isActive) {
          ele.data = event.chartData[i].data[i];
          return ele;
        } else {
          ele.data = Array(utils.getDashboardDefaultValues().month.length).fill(0);
          return ele;
        }
      });
      this.currentChart.update();
    }
  }
}
