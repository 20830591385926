import { Router } from '@angular/router';
import { ConfirmationService, MenuItem, Message, MessageService } from 'primeng/api';
import { Subscription, timer } from 'rxjs';
import { UiService } from '../../services/ui.service';
import { AppSettings } from './../../app.settings';

import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { ConfirmPasswordValidator } from '../../../../pages/password-management/confirm-password.validators';
import * as utils from '../../../shared/utility-functions/utils';
import { CommonBindingDataService } from '../../services/common-binding-data.service';
import { UserCommonService } from '../../services/user-common.service';
import { GridModel } from '../mi-grid/grid-model';
import { StorageService } from './../../services/storage.service';
import { HeaderService } from './header.service';

interface obj1 {
  countryCode: string;
  countryId: string;
  createdAt: 1574762838716;
  createdBy: string;
  flagImgFileId: string;
  flagImgUrl: string;
  fullNameLocale: string;
  localNames: string;
  supportedCurrency: any;
  twoLetterAbbreviation: string;
  updatedAt: number;
  updatedBy: string;
}
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit {
  notificationInterval;
  userFullName: string;
  items: MenuItem[];
  message: Message[] = [];
  profileUrl = AppSettings.DEFAULT_PROFILE_IMAGE_URL;
  changePasswordPopup = false;
  editProfilePopup = false;
  subscription: Subscription;
  myDate = new Date(1496314866000);
  myDateLong = 1496314866000;
  minuts = 240;
  notifications: any = [];
  unReadNotificatonsCount = 0;
  limit = 5;
  offset = 0;
  showMoreOff = true;
  secondsCounter: any;
  logoutSession: any;
  selectedCountry;
  istri = false;
  countries;
  userFname;
  userLname;
  userDetails;
  countryURL = '/api/v1/countries';
  model: GridModel;
  userRole;
  passwordChangePopup = false;
  changePasswordForm: FormGroup;

  constructor(
    private uiService: UiService,
    private router: Router,
    private commonService: CommonBindingDataService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private storageService: StorageService,
    private headerservice: HeaderService,
    private userCommandService: UserCommonService,
    private fb: FormBuilder
  ) {
    this.userDetails = JSON.parse(this.storageService.getItem(AppSettings.USER_DETAILS));
    this.getCountry();

    if (screen.width < 1280) {
      document.getElementsByTagName('body')[0].classList.toggle('closed');
    }
  }

  ngOnInit() {
    this.userRole = JSON.parse(this.storageService.getItem('userRole'));
    this.setSessionInterval();
    this.storageService.logout.subscribe((res) => {
      this.logout();
    });

    this.items = [
      {
        label: 'Logout',
        icon: 'fa fa-sign-out',
        command: (event) => {
          this.doSignOut();
        },
      },
    ];

    const userObj: any = this.storageService.getItem(AppSettings.USER_DETAILS);
    if (userObj !== undefined && userObj !== null) {
      this.userFullName = userObj.firstName + ' ' + userObj.lastName;
      if (userObj.userDetails !== undefined && userObj.userDetails !== null && userObj.userDetails.profileImage !== null) {
        this.profileUrl = AppSettings.GET_FILE_THUMB_URL + userObj.userDetails.profileImage;
      }
    }

    this.userFname = this.userDetails?.firstName;
    this.userLname = this.userDetails?.lastName;

    this.changePasswordForm = this.fb.group(
      {
        oldPassword: ['', [Validators.required, this.noWhitespaceValidator]],
        newPassword: ['', [Validators.required, this.noWhitespaceValidator, Validators.pattern(AppSettings.PASSWORD_PATTERN)]],
        confirmPassword: ['', [Validators.required, this.noWhitespaceValidator, Validators.pattern(AppSettings.PASSWORD_PATTERN)]],
      },
      {
        validator: ConfirmPasswordValidator('newPassword', 'confirmPassword'),
      }
    );
  }

  getCountry() {
    const userCountry = utils.getCountryId();
    const params = `offset=${this.offset}&limit=${100}&languageCode=${utils.getLanguage()}&countryId=${userCountry}`;

    let buildUrl = this.countryURL;
    if (this.countryURL.lastIndexOf('?') < 0) {
      buildUrl += '?';
    } else {
      buildUrl += '&';
    }
    buildUrl += params;
    this.headerservice.getCountryList(buildUrl).subscribe((result) => {
      const model = result as GridModel;
      this.countries = model.records;
      this.setCountry();
    });
  }

  setCountry() {
    let temp: string = this.storageService.getItem('userCountry');
    temp = JSON.parse(temp);
    if (temp) {
      this.selectedCountry = this.countries.filter((ele) => ele.countryId === temp)[0];
      this.storageService.setItem('country', this.selectedCountry);
    } else {
      this.selectedCountry = ' ';
    }
  }

  changeCountry() {
    this.storageService.setItem('userCountry', this.selectedCountry.countryId);
    this.storageService.setItem('country', this.selectedCountry);
    location.reload();
  }

  onProfileClick(event: any) {
    event.preventDefault();
    const toggleClass = 'toggle-menu';
    event.currentTarget.classList.toggle(toggleClass);
  }

  onSideMenuClick(event: any) {
    event.preventDefault();
    document.getElementsByTagName('body')[0].classList.toggle('closed');
    this.uiService.sidebarToggled();
  }

  doSignOut() {
    this.confirmationService.confirm({
      message: this.commonService.getLabel('msg_logout'),
      accept: () => {
        this.logout();
      },
    });
  }

  logout() {
    this.userCommandService.signOut().subscribe((results) => {
      this.storageService.removeAll();
      this.router.navigate(['/signin']).then(() => {
        location.reload();
      });
    });
  }

  onCancelEditProfile(event: any) {
    this.editProfilePopup = !this.editProfilePopup;
  }

  onCancelChangePassword(event: any) {
    this.changePasswordPopup = !this.changePasswordPopup;
  }

  errorMessage(error) {
    error = JSON.parse(error);
    this.message = [];
    this.message.push({
      severity: 'error',
      summary: 'error',
      detail: error.general[0].message,
    });
  }

  setSessionInterval() {
    const sessionInterval = timer(10000, 1000);
    this.secondsCounter = sessionInterval.subscribe((n) => {
      const idleSessionTime: number = this.storageService.getItem(AppSettings.INTERVAL);
      if (idleSessionTime) {
        if (idleSessionTime < new Date().getTime()) {
          this.confirmYouThere();
          this.secondsCounter.unsubscribe();
        } else {
          clearTimeout(this.logoutSession);
        }
      }
    });
  }

  confirmYouThere() {
    this.sessionTimeout();
    const message = this.commonService.getLabel('lbl_confirm_idle_session');
    this.confirmationService.confirm({
      message,
      rejectVisible: false,
      accept: () => {
        this.refreshSession();
      },
      reject: () => {
        this.refreshSession();
      },
    });
  }

  sessionTimeout() {
    this.logoutSession = setTimeout(() => {
      const idleSessionTime: number = this.storageService.getItem(AppSettings.INTERVAL);
      if (idleSessionTime < new Date().getTime()) {
        this.storageService.removeAll();
        this.logout();
      } else {
        clearTimeout(this.logoutSession);
      }
    }, AppSettings.IDLE_TIME_ON_CONFIRM_MSG);
  }

  refreshSession() {
    const idleTime = moment().add(AppSettings.IDLE_TIME, 'm').valueOf();
    this.storageService.setItem('interval', idleTime);
    clearTimeout(this.logoutSession);
    // this.userCommandService.sesionRefresh().subscribe(() => { });
    this.setSessionInterval();
  }
  setCountryId(event) {
    // this.storageService.setItem('country', event.value);
  }

  showDialog() {
    this.passwordChangePopup = true;
  }

  savePassword() {
    const url = '/api/v1/users/reset-password';

    if (!this.changePasswordForm.valid) {
      this.messageService.add({
        severity: 'error',
        key: 'tr',
        summary: 'Required',
        detail: this.commonService.getLabel('brandErrorDetail'),
      });
      this.changePasswordForm.markAllAsTouched();
      return;
    }

    const userData = { ...this.changePasswordForm.value };
    userData.oldPassword = userData.oldPassword;
    userData.newPassword = userData.newPassword;
    userData.countryId = utils.getCountryId();
    this.headerservice.updatePassword(url, userData).subscribe((res) => {
      this.messageService.add({
        severity: 'success',
        key: 'tr',
        summary: this.commonService.getLabel('Success'),
        detail: this.commonService.getLabel('userUpdatedSuccess'),
      });
    });
    this.changePasswordForm.reset();
    this.passwordChangePopup = false;
  }

  resetForm() {
    this.changePasswordForm.reset();
  }

  noWhitespaceValidator(control: FormControl) {
    const isWhitespace = ((control && control.value && control.value.toString()) || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { whitespace: true };
  }
}
