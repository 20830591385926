import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-text-input',
  templateUrl: './text-input.component.html',
  styleUrls: ['./text-input.component.scss'],
})
export class TextInputComponent implements OnInit {
  @Output() onValueChange = new EventEmitter<any>();
  @Input() arrayPayload: [];
  @Input() inputType = 'number';
  @Input() optionLabel;
  @Input() optionValue;
  @Input() datakey;
  @Input() label;
  @Input() placeholder: any;
  @Input() selectedItem: any;
  @Input() disabled = false;
  @Input() isRequired = false;

  constructor() {}

  ngOnInit(): void {}
  onChange(changes) {
    this.onValueChange.emit(changes);
  }
}
