import { CurrencyPipe } from '@angular/common';
import { Directive, ElementRef, Input, OnChanges } from '@angular/core';
import { ChartService } from '../components/chart/chart.service';
import { CommonBindingDataService } from '../services/common-binding-data.service';

@Directive({
  selector: '[appCurrencyFormatter]',
  providers: [CurrencyPipe],
})
export class CurrencyFormatterDirective implements OnChanges {
  @Input() value;
  @Input() withCurrency: boolean;
  @Input() withAbbrevation = false;
  @Input() customMinFraction = false;
  currencyCode = this.commonService.getCurrentCurrencyAndLocaleData().currencyCode;
  constructor(
    private element: ElementRef,
    private chartConfig: ChartService,
    private commonService: CommonBindingDataService,
    private currencyPipe: CurrencyPipe
  ) {}

  ngOnChanges() {
    this.element.nativeElement.innerHTML = this.currencyFormatter();
  }

  currencyFormatter() {
    return this.withAbbrevation
      ? this.chartConfig.convert(this.value, this.withCurrency, this.customMinFraction)
      : this.currencyPipe.transform(
          this.value,
          this.withCurrency ? this.commonService.getCurrentCurrencyAndLocaleData().currencyCode : ' ',
          this.withCurrency ? 'symbol-narrow' : '',
          this.commonService.getCurrentCurrencyAndLocaleData().minFraction,
          this.commonService.getCurrentCurrencyAndLocaleData().localeCode
        );
  }
}
