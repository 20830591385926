/** @format */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { RestApiService } from '../../shared/services/rest-api.service';
import * as utils from '../../shared/utility-functions/utils';

@Injectable({
  providedIn: 'root',
})
export class ProductListService {
  baseUrl: string;
  countryId: string;
  selectedLanguage;
  constructor(private readonly http: HttpClient, private restApiService: RestApiService) {
    this.baseUrl = environment.basePath;
    this.countryId = utils.getCountryId();
  }

  getProducts() {
    return this.http.get(`${this.baseUrl}/api/v1/${this.countryId}/products`);
  }
  getProductDetails(url: string): Observable<{}> {
    return this.restApiService.get('product-details', url, 'page-center');
  }

  publishProductPut(d, url: string, data): Observable<any> {
    return this.restApiService.put(d, url, data, 'page-center');
  }
  getProductList(url: any) {
    return this.restApiService.get('products', url);
  }

  setLanguage(language) {
    this.selectedLanguage = language;
  }

  getLanguage() {
    return this.selectedLanguage;
  }
}
