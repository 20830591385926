import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class SidebarService {
  menuItems: any;
  sideState;
  adminSideBar = [];
  corpId;
  userDetails;
  accessDetailsList: any;
  private sideSubject = new Subject<any>();

  constructor() {
    this.sideState = this.sideSubject.asObservable();
  }

  addModule(menuItemName, menuItem) {
    if (this.menuItems.includes(menuItemName)) {
    }
  }

  sequentialMenuArrangement() {}
}
