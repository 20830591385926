import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { AppSettings } from '../app.settings';

@Injectable()
export class StorageService {
  public logout: Subject<any> = new Subject<any>();
  cookiesItems = [];

  constructor() {}

  getItem<T>(key: string): T {
    const result = localStorage.getItem(key);
    let resultJson = null;
    if (result != null) {
      resultJson = result;
    }
    return resultJson;
  }

  setItem<T>(key: string, value: T) {
    localStorage.setItem(key, JSON.stringify(value));
  }

  setItemInCookies(key: string, value: string) {
    this.cookiesItems.push(key);
    const d = new Date();
    d.setTime(d.getTime() + AppSettings.COOKIE_EXPIRY);
    const expires = 'expires=' + d.toUTCString();
    document.cookie = key + '=' + value + ';' + expires + ';path=/';
  }

  getItemFromCookies(key: string) {
    const name = key + '=';
    const ca = document.cookie.split(';');
    for (const element of ca) {
      let c = element;
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return '';
  }

  removeItem<T>(key: string) {
    localStorage.removeItem(key);
  }

  removeAll<T>() {
    localStorage.clear();
  }

  removeAllCookies() {
    const that = this;
    this.cookiesItems.forEach((key) => {
      that.setItemInCookies(key, '');
    });
  }

  getCountryDetailsByKey(param: string = 'countryId') {
    try {
      return JSON.parse(this.getItem('country'))[param] || AppSettings.DEFAULT_COUNTRY;
    } catch (error) {
      return 'IN';
    }
  }
  getCountyId() {
    return JSON.parse(localStorage.getItem('userCountry')) || AppSettings.DEFAULT_COUNTRY;
  }
}
