import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { RestApiService } from '../../shared/services/rest-api.service';

@Injectable({
  providedIn: 'root',
})
export class InventoryService {
  baseUrl: string;
  countryId: string;
  constructor(private readonly http: HttpClient, private restApiService: RestApiService) {
    this.baseUrl = environment.basePath;
  }
  bulkUpload(payload: any): Observable<{}> {
    return this.restApiService.post('categories', `/api/v1/bulk-upload`, payload, 'page-center');
  }
  downLoadSampleFile(url: string, fileName: string) {
    return this.restApiService.fileApiDownload(url, fileName, 'page-center');
  }
  getInventory(url: string) {
    return this.restApiService.get('categories', url, 'page-center');
  }

  sendFileToEmail(url: string) {
    return this.restApiService.get('send-file', url, 'page-center');
  }
}
