import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import CryptoJS from 'crypto-js';
import { Message } from 'primeng/api';
import { Subscription } from 'rxjs';
import { ErrorDto } from '../../modules/shared/models/error-dto';
import { ErrorResponseDto } from '../../modules/shared/models/error-response-dto';
import { CommonMessageTransferService } from '../../modules/shared/services/common-message-transfer.service';
import { AppSettings } from './../../modules/shared/app.settings';
import { StorageService } from './../../modules/shared/services/storage.service';
import { UserCommonService } from './../../modules/shared/services/user-common.service';

@Component({
  selector: 'app-sign-in',
  styleUrls: ['./sign-in.component.scss'],
  templateUrl: './sign-in.component.html',
})
export class SignInComponent implements OnInit, OnDestroy {
  signInResponse: any;
  message: Message[] = [];
  otpId;
  remMe = false;
  forgotPopup = false;
  loginErrors = {
    email: '',
    password: {
      hasError: false,
      error: [],
    },
    general: {
      hasError: false,
      error: [],
    },
  };
  subscription: Subscription;
  isSubmitted = false;
  otpPopup = false;
  setPasswordPopup = false;

  otpEmail;
  otpPhone;
  isSaveBtn = false;
  secretKey = 'mobisoft secretKey 23';

  signInForm = new FormGroup({
    email: new FormControl(),
    password: new FormControl(),
    remMe: new FormControl(),
  });

  restAPIFieldSubscription: any;
  restAPIGeneralSubscription: any;
  isEyeOpen = false;
  passwordType = 'password';
  constructor(
    private formBuilder: FormBuilder,
    private signInService: UserCommonService,
    private translate: TranslateService,
    private router: Router,
    private storageService: StorageService,
    private commonMessageTransferService: CommonMessageTransferService
  ) {}

  ngOnInit() {
    this.signInForm = this.formBuilder.group({
      password: ['', [Validators.required]],
      email: ['', [Validators.required]],
      remMe: [''],
    });
    this.restAPIFieldSubscription = this.commonMessageTransferService.restAPIFieldErrorEvent.subscribe((error: ErrorResponseDto) => {});

    this.restAPIGeneralSubscription = this.commonMessageTransferService.restAPIGeneralErrorEvent.subscribe((error: ErrorDto) => {
      this.isSaveBtn = false;
      this.loginErrors.general.hasError = true;
      const errMessage = { message: error.message };
      this.loginErrors.general.error = [errMessage];
    });

    this.getCredentialsFromCookie();
  }

  getCredentialsFromCookie() {
    const email = this.storageService.getItemFromCookies(AppSettings.COOKIE_EMAIL_LABEL);
    const password = this.storageService.getItemFromCookies(AppSettings.COOKIE_PASS_LABEL);
    const decryptedEmail = CryptoJS.AES.decrypt(email, this.secretKey).toString(CryptoJS.enc.Utf8);
    const decryptedPassword = CryptoJS.AES.decrypt(password, this.secretKey).toString(CryptoJS.enc.Utf8);

    if (email && password) {
      this.signInForm.controls['email'].setValue(decryptedEmail);
      this.signInForm.controls['password'].setValue(decryptedPassword);
    }
  }

  onAPIValidation(error) {
    //error = JSON.parse(error);
    if (error.general) {
      this.loginErrors.general.hasError = true;
      this.loginErrors.general.error = error.general;
    } else if (error.password) {
      this.loginErrors.password.hasError = true;
      this.loginErrors.password.error = error.password;
    }
  }

  doSignIn() {
    const signInData = this.signInForm.value;
    const reqObj = {
      email: signInData.email,
      password: signInData.password,
    };
    this.signInApiCall(reqObj);
  }

  signInApiCall(signInData) {
    this.isSaveBtn = true;
    this.signInService.signIn('sign-in', JSON.stringify(signInData)).subscribe((signInResult) => {
      this.isSaveBtn = false;
      this.signInResponse = signInResult;
      this.setSessionAndUserDetails(signInResult);
    });
  }

  setSessionAndUserDetails(results) {
    const data: any = {};
    data.username = results.username;
    data.firstName = results.firstName;
    data.lastName = results.lastName;
    data.countryId = results.countryId;

    this.storageService.setItem(AppSettings.USER_DETAILS, data);
    AppSettings.USER = data;
    this.storageService.setItemInCookies('Token', results.expires);

    if (this.signInForm.controls['remMe'].value) {
      const encryptedEmail = CryptoJS.AES.encrypt(this.signInForm.controls['email'].value, this.secretKey);
      const encryptedPassword = CryptoJS.AES.encrypt(this.signInForm.controls['password'].value, this.secretKey);

      this.storageService.setItemInCookies(AppSettings.COOKIE_EMAIL_LABEL, encryptedEmail);
      this.storageService.setItemInCookies(AppSettings.COOKIE_PASS_LABEL, encryptedPassword);
    }

    this.signInService.getAccessRole(results.userId).subscribe((res) => {
      this.addDetailsInStorage(res);

      if (AppSettings.STORE_MANAGER_ACCESS === res.roleId) {
        this.storageService.setItem('userRole', 'userRoleStoreManager');
        this.router.navigate(['/order/order-list']);
      } else {
        this.storageService.setItem('userRole', 'userRoleAdmin');
        this.router.navigate(['/dashboard']);
      }
      this.translate.use(res.country[0].languageId);
    });
  }

  addDetailsInStorage(res) {
    this.storageService.setItem('roleId', JSON.parse(res.roleId));
    if (res.country[0]?.languageId) {
      this.storageService.setItem('userLanguage', res.country[0]?.languageId);
    } else {
      this.storageService.setItem('userLanguage', AppSettings.DEFAULT_LANGUAGE);
    }
    if (res.country[0]?.countryId) {
      this.storageService.setItem('userCountry', res.country[0]?.countryId);
    } else {
      this.storageService.setItem('userCountry', AppSettings.DEFAULT_COUNTRY);
    }
    if (res.country[0]?.currencyId) {
      this.storageService.setItem('userCurrency', res.country[0]?.currencyId);
    } else {
      this.storageService.setItem('userCurrency', AppSettings.DEFAULT_CURRENCY);
    }
    if (res.country[0]?.languageId === 'es') {
      this.storageService.setItem('acceptLanguage', 'es-US');
    } else {
      this.storageService.setItem('acceptLanguage', AppSettings.HEADER_ACCEPT_LANGUAGE);
    }
    if (res.country[0]?.timezone) {
      this.storageService.setItem('timeZone', res.country[0].timezone);
    } else {
      this.storageService.setItem('timeZone', AppSettings.DEFAULT_TIMEZONE);
    }
  }

  uniqArray(arrArg) {
    return arrArg.filter((elem, pos, arr) => arr.indexOf(elem) === pos);
  }

  forgotPassword() {
    this.forgotPopup = true;
  }

  onPopClose() {
    this.forgotPopup = false;
  }

  onOtpPopClose() {
    this.otpPopup = false;
  }

  onSetPasswordPopClose() {
    this.setPasswordPopup = false;
  }

  onOtpPopSave(event) {
    if (event.general !== undefined) {
      this.message = [];
      this.message.push({
        severity: 'error',
        summary: 'OTP',
        detail: event.general[0].message,
      });
    } else {
      if (event.otpId) {
        this.otpId = event.otpId;
        this.message = [];
        this.message.push({
          severity: 'success',
          summary: 'OTP Verified',
          detail: 'OTP matched.',
        });
        this.setPasswordPopup = true;
      }
    }
    this.forgotPopup = false;
    this.otpPopup = false;
  }

  onPopSave(results) {
    this.otpEmail = results.email;
    this.otpPhone = results.phone;
    this.message = [];
    this.message = [];
    this.message.push({
      severity: 'success',
      summary: 'Forgot Password.',
      detail: results.general[0].message,
    });
    this.forgotPopup = false;
    this.otpPopup = true;
  }

  eventHandler(event) {
    if (event.keyCode === 13) {
      this.doSignIn();
    }
  }

  ngOnDestroy() {
    this.restAPIFieldSubscription.unsubscribe();
    this.restAPIGeneralSubscription.unsubscribe();
  }

  toggleEye() {
    this.passwordType = this.isEyeOpen ? 'password' : 'text';
    return (this.isEyeOpen = !this.isEyeOpen);
  }
}
