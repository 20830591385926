/* eslint-disable @typescript-eslint/naming-convention */
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AppSettings } from '../app.settings';
import { StorageService } from './storage.service';

import * as moment from 'moment';
import { MessageService } from 'primeng/api';
import { LoaderService } from './../components/loader/loader.service';
import { CommonBindingDataService } from './common-binding-data.service';
import { HandleError, HttpErrorHandler } from './http-error-handler.service';

const httpOptions = {
  headers: new HttpHeaders({
    'Accept-Language': JSON.parse(localStorage.getItem('acceptLanguage')) || AppSettings.HEADER_ACCEPT_LANGUAGE,
    'Content-Type': AppSettings.HEADER_CONTENT_TYPE,
    Accept: AppSettings.HEADER_CONTENT_TYPE,
  }),
};

@Injectable()
export class RestApiService {
  httpHandleError: HandleError;

  constructor(
    private httpClient: HttpClient,
    private httpErrorHandler: HttpErrorHandler,
    private zone: NgZone,
    private router: Router,
    private storageService: StorageService,
    private loaderService: LoaderService,
    private messageService: MessageService,
    private commonBindingDataService: CommonBindingDataService
  ) {
    this.httpHandleError = httpErrorHandler.createHandleError();
  }

  get(identifier: string, url: string, loader?: string): Observable<{}> {
    this.showLoader('loader');
    return this.handleHttpSuccess(this.callerWithoutBody('get', identifier, url));
  }

  post(identifier: string, url: string, body: any, loader?: string): Observable<{}> {
    this.showLoader(loader);
    return this.handleHttpSuccess(this.callerWithBody('post', identifier, url, body));
  }

  put(identifier: string, url: string, body?: any, loader?: string): Observable<{}> {
    this.showLoader(loader);
    return this.handleHttpSuccess(this.callerWithBody('put', identifier, url, body));
  }

  delete(identifier: string, url: string, loader?: string): Observable<{}> {
    return this.handleHttpSuccess(this.callerWithoutBody('delete', identifier, url));
  }

  fileApiDownload(url: string, fileName: string, loader?: string) {
    this.showLoader(loader);
    const that = this;
    return this.httpClient
      .get(this.prependApiUrl(url), {
        headers: this.getHttpClientHeaders(),
        withCredentials: true,
        responseType: 'blob',
        observe: 'response',
      })
      .subscribe({
        next: (data) => {
          that.hideLoader();
          let name = data.headers.get('Content-Disposition');
          if (!name) {
            name = fileName;
          } else {
            name = name.substring(name.indexOf('='));
            name = name.replace('=', '');
          }
          this.downloadExcel(data.body, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', name);
        },
        error: (e) => {
          this.hideLoader();
          if (e.status === 0) {
            this.messageService.add({
              key: 'tr',
              severity: 'error',
              summary: this.commonBindingDataService.getLabel('serverUnreachable'),
              detail: this.commonBindingDataService.getLabel('noInternet'),
            });
          } else {
            this.messageService.add({
              severity: 'error',
              key: 'tr',
              summary: 'Error',
              detail: e?.message,
            });
          }
        },
      });
  }

  downloadExcel(data: any, contentType: string, fileName: string) {
    const blob = new Blob([data], { type: contentType });
    const link = document.createElement('a');
    link.setAttribute('type', 'hidden');
    link.href = window.URL.createObjectURL(blob);
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    this.hideLoader();
    setTimeout(() => {
      link.remove();
    }, 1000);
  }

  callerWithoutBody(method: string, identifier: string, url: string): Observable<{}> {
    if (identifier !== 'sign-out' && AppSettings.IS_IDLE_TIME) {
      this.checkIdleTime();
    }
    const head = { headers: this.getHttpClientHeaders(), withCredentials: true };
    const that = this;
    if (method === 'get') {
      return this.httpClient
        .get(this.prependApiUrl(url), head)
        .pipe(catchError(this.httpHandleError(identifier, [])))
        .pipe(
          map((r: Response) => {
            that.hideLoader();
            return r;
          })
        );
    } else if (method === 'delete') {
      return this.httpClient
        .delete(this.prependApiUrl(url), head)
        .pipe(catchError(this.httpHandleError(identifier, [])))
        .pipe(
          map((r: Response) => {
            that.hideLoader();
            return r;
          })
        );
    }
  }
  callerWithBody(method: string, identifier: string, url: string, body?: any): Observable<{}> {
    if (identifier !== 'sign-out' && AppSettings.IS_IDLE_TIME) {
      this.checkIdleTime();
    }
    const that = this;
    const head = { headers: this.getHttpClientHeaders(), withCredentials: true };
    if (method === 'put') {
      return this.httpClient
        .put(this.prependApiUrl(url), body, head)
        .pipe(catchError(this.httpHandleError(identifier, [])))
        .pipe(
          map((r: Response) => {
            that.hideLoader();
            return r;
          })
        );
    } else if (method === 'post') {
      return this.httpClient
        .post(this.prependApiUrl(url), body, head)
        .pipe(catchError(this.httpHandleError(identifier, [])))
        .pipe(
          map((r: Response) => {
            that.hideLoader();
            return r;
          })
        );
    }
  }

  image(identifier: string, url: string, fileName: string, loader?: string) {
    // responseType: ResponseContentType.Blob
    this.showLoader(loader);
    const head = { headers: this.getHttpClientHeaders() };
    const res = this.httpClient.get(url, head).pipe(catchError(this.httpHandleError(identifier, [])));
    this.downloadFile(res, this.getContentType(fileName), fileName);
  }

  downloadFile(data: any, contentType: string, fileName: string) {
    const blob = new Blob([data.blob()], { type: contentType });
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = fileName;
    link.click();
    this.hideLoader();
    // setTimeout(link.remove(), 1000);
  }

  checkIdleTime() {
    const idleTime = moment().add(AppSettings.IDLE_TIME, 'm').valueOf();
    this.storageService.setItem('interval', idleTime);
  }

  getHttpClientHeaders(): HttpHeaders {
    if (
      this.storageService.getItemFromCookies(AppSettings.TOKEN_KEY) !== undefined &&
      this.storageService.getItemFromCookies(AppSettings.TOKEN_KEY) !== null &&
      this.storageService.getItemFromCookies(AppSettings.TOKEN_KEY).length > 0
    ) {
      return new HttpHeaders({
        'Accept-Language': JSON.parse(localStorage.getItem('acceptLanguage')) || AppSettings.HEADER_ACCEPT_LANGUAGE,
        'Content-Type': AppSettings.HEADER_CONTENT_TYPE,
        Accept: AppSettings.HEADER_CONTENT_TYPE,
        // Authorization: 'Bearer 01GGEZR4ABG905QAJY8K1T3BTE',
        // 'X-SESSION-KEY': this.storageService.getItemFromCookies(AppSettings.TOKEN_KEY)
      });
    }
    return new HttpHeaders({
      'Accept-Language': JSON.parse(localStorage.getItem('acceptLanguage')) || AppSettings.HEADER_ACCEPT_LANGUAGE,
      'Content-Type': AppSettings.HEADER_CONTENT_TYPE,
      Accept: AppSettings.HEADER_CONTENT_TYPE,
      // Authorization: 'Bearer 01GGEZR4ABG905QAJY8K1T3BTE',
    });
  }

  private prependApiUrl(url: string): string {
    return AppSettings.BASE_URL + url;
  }

  private handleHttpSuccess(res: Observable<{}>): Observable<{}> {
    return res;
  }

  private getContentType(fileName: string) {
    const extension = fileName.substring(fileName.lastIndexOf('.') + 1).toLowerCase();
    switch (extension) {
      case 'jpeg':
        return 'image/jpeg';
      case 'jpg':
        return 'image/jpeg';
      case 'png':
        return 'image/png';
      case 'gif':
        return 'image/gif';
      case 'bmp':
        return 'image/x-ms-bmp';
      case 'pdf':
        return 'application/pdf';
      case 'xls':
        return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    }
    return '';
  }

  private onEnd(): void {
    this.hideLoader();
  }

  private showLoader(loader?: string): void {
    if (loader !== undefined && loader !== null && 'none' !== loader.toLowerCase()) {
      this.loaderService.show(loader);
    }
  }

  private hideLoader(): void {
    this.loaderService.hide();
  }
}
