<main>
  <app-loader></app-loader>
  <aside class="sidebar">
    <app-sidebar></app-sidebar>
  </aside>
  <header>
    <app-header class="top-bar grid col-nogutter"></app-header>
  </header>
  <section class="main-panel">
    <div
      [ngClass]="
        isDashboard ? 'dashboard-main-container' : isDashboardViewDetails.length > 0 ? 'dashboard-view-main-container' : 'main-container'
      "
    >
      <div class="bread-crumbs">
        <li class="bread-crumb" *ngFor="let breads of breadCrumbArray; index as i">
          <a [id]="breads.name.trim()" href="{{ breads.url }}" *ngIf="breadCrumbArray.length - 1 !== i" translate
            >&nbsp; breadcrumbs.{{ breads.name.trim() }}</a
          >
          <p *ngIf="breadCrumbArray.length - 1 === i" translate>&nbsp; breadcrumbs.{{ breads.name.trim() }}</p>
        </li>
      </div>
      <main class="main-content">
        <router-outlet></router-outlet>
      </main>
    </div>
  </section>
  <!-- <p-toast position="bottom-right" key="br"></p-toast> -->
</main>
