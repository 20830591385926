import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { RestApiService } from '../../shared/services/rest-api.service';
import * as utils from '../../shared/utility-functions/utils';

@Injectable({
  providedIn: 'root',
})
export class MarketingService {
  baseUrl: string;
  countryId: string;
  languageCode: string;
  constructor(private restApiService: RestApiService) {
    this.baseUrl = environment.basePath;
    this.countryId = utils.getCountryId();
    this.languageCode = utils.getLanguage();
  }
  addCoupon(payload: any) {
    return this.restApiService.post('marketing', `/api/v1/promo-codes`, payload);
  }
  updateCoupon(url: string, request?: any) {
    return this.restApiService.put('Coupon-update', url, request);
  }
  updateBanner(url: string, request?: any) {
    return this.restApiService.put('Banner-update', url, request);
  }
  updateComboOffer(url: string, request?: any) {
    return this.restApiService.put('Combo-offer-update', url, request);
  }
  addBanner(payload: any) {
    return this.restApiService.post('marketing', `/api/v1/${this.countryId}/${this.languageCode}/banner`, payload);
  }
  updateCouponStatus(payload: any) {
    return this.restApiService.put('marketing', `/api/v1/promo-codes/action/status`, payload);
  }
  updateComboOfferStatus(payload: any) {
    return this.restApiService.put('marketing', `/api/v1/${this.countryId}/combo-offers/action/status`, payload);
  }
  addComboOffer(payload: any) {
    return this.restApiService.post('marketing', `/api/v1/${this.countryId}/combo-offers`, payload);
  }
  addNotification(url: string, payload: any): Observable<any> {
    return this.restApiService.post('add-notification', url, payload);
  }
  editNotification(url: string, payload: any): Observable<any> {
    return this.restApiService.put('add-notification', url, payload);
  }
  updateNotificationStatus(url: string) {
    return this.restApiService.put('notification-status', url);
  }
  getNotificationDetails(url: string): Observable<any> {
    return this.restApiService.get('get-notification-details', url, 'page-center');
  }
  getCouponDetails(url: string): Observable<any> {
    return this.restApiService.get('get-coupon-details', url);
  }
  getBannerDetails(url: string): Observable<any> {
    return this.restApiService.get('get-banner-details', url);
  }
  getSuggestedBanner(url: string): Observable<any> {
    return this.restApiService.get('get-suggested-banner', url);
  }
  getComboOfferDetails(url: string): Observable<any> {
    return this.restApiService.get('get-coupon-details', url);
  }
}
