import { AfterViewInit, Component, ElementRef, EventEmitter, Inject, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';
import { Message, MessageService } from 'primeng/api';
import { environment } from '../../../../../environments/environment';
import { AppSettings } from '../../../shared/app.settings';
import { LoaderService } from './../../components/loader/loader.service';
import { CommonBindingDataService } from './../../services/common-binding-data.service';

@Component({
  selector: 'app-file-uploader',
  templateUrl: './file.uploader.component.html',
  styleUrls: ['./file.uploader.component.scss'],
})
export class FileUploaderComponent implements OnInit, AfterViewInit, OnChanges {
  @ViewChild('fileUpload') fileUpload: ElementRef;
  @Input() accept;
  @Input() uploadButtonLabel;
  @Output() beforeFileUploadEvent: EventEmitter<any> = new EventEmitter();
  @Output() afterFileUploadError: EventEmitter<any> = new EventEmitter();
  @Output() afterFileUploadEvent: EventEmitter<any> = new EventEmitter();
  @Output() onFileRemove: EventEmitter<any> = new EventEmitter();
  @Input() options: any[];
  @Input() src: string;
  @Input() formCtrlName: string;
  @Input() dataCy: string;
  @Input() isUploadManually = '';
  @Input() chooseStyleClass = 'none';
  @Input() fileLimit;
  @Input() disabled = false;
  @Input() uploadedFilesFromApi: any = [];
  @Input() deleteBtnDisabled = false;
  @Input() fileUploadBoxID = '';
  @Input() isDeleteAll = new EventEmitter<any>();
  uploadedFiles: any[] = [];
  maxSize = AppSettings.FILE_UPLOAD_MAX_SIZE_IN_BYTE;
  fileUploadUrl: string = AppSettings.UPLOAD_FILE_URL;
  maxSizeMessage;
  message: Message[] = [];
  test: any;
  baseUrl = environment.basePath;
  files: any = [];
  fileId: any;
  constructor(
    @Inject(ElementRef) elementRef: ElementRef,
    private loaderService: LoaderService,
    private commonService: CommonBindingDataService,
    private messageService: MessageService
  ) {
    this.fileUpload = elementRef;
  }
  ngOnInit(): void {
    this.maxSizeMessage = this.commonService.getLabel('file_size_limit');
    this.isDeleteAll.subscribe(() => {
      this.deleteAll();
    });
  }
  ngOnChanges(): void {}

  deleteAll() {
    this.uploadedFiles = [];
  }

  ngAfterViewInit() {
    this.fileUpload?.nativeElement?.setAttribute('aria-describedby', `${this.formCtrlName}-help`);
    if (this.dataCy) {
      this.fileUpload?.nativeElement?.setAttribute('data-cy', `${this.formCtrlName || this.dataCy}`);
    }
    if (this.uploadedFilesFromApi?.originalFileName) {
      this.uploadedFiles.push(this.uploadedFilesFromApi);
    }
  }
  onChange($event) {}

  onClick(e) {
    this.fileUpload['el'].nativeElement.querySelector('.p-fileupload-choose').click();
  }
  onUploadError(e) {
    this.message = [];
    this.message.push({
      severity: 'error',
      summary: 'Error',
      detail: this.commonService.getLabel('file_upload_error'),
    });
    this.loaderService.hide();
    this.afterFileUploadError.emit(e);
  }

  onBeforeSend(e, file) {
    this.loaderService.show('page-center');
  }

  onUpload(e) {
    const event = e.originalEvent.body;
    this.uploadedFiles.push(event);
    this.beforeFileUploadEvent.emit(event);
    this.fileId = event.fileId;
    this.messageService.add({
      severity: 'success',
      key: 'tr',
      summary: 'Success',
      detail: this.commonService.getLabel('fileUploadSuccess'),
    });
    this.loaderService.hide();
  }

  openLink(file) {
    window.open(file.cdnUrl);
  }

  deleteFile(index) {
    this.uploadedFiles.splice(index, 1);
    this.onFileRemove.emit(index);
  }
  customFilesUpload(event, file) {
    this.files.push(event.files);
  }
}
