import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RestApiService } from '../shared/services/rest-api.service';
@Injectable()
export class AdminService {
  constructor(private restApiService: RestApiService) {}

  public getStore(url: string): Observable<{}> {
    return this.restApiService.get('get-Stores', url, 'page-center');
  }
  getUserDetails(id) {
    return this.restApiService.get('get-user-details', `/api/v1/users/${id}`);
  }
  public postUser(d, data): Observable<any> {
    return this.restApiService.post(d, '/api/v1/users', data, 'page-center');
  }
  updateUserDetails(id: any, payload: any) {
    return this.restApiService.put('update-user', `/api/v1/users/${id}`, payload);
  }
}
