import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { LoaderState } from './loader';

@Injectable()
export class LoaderService {
  loaderState;
  private loaderSubject = new Subject<LoaderState>();

  constructor() {
    this.loaderState = this.loaderSubject.asObservable();
  }

  show(type: string) {
    this.loaderSubject.next({ show: true } as LoaderState);
  }

  hide() {
    this.loaderSubject.next({ show: false } as LoaderState);
  }
}
