<div class="layout-dashboard">
  <div [class]="titleClass ? titleClass : 'grid mb-5'">
    <div class="col">
      <h1 [id]="headerTitleId" class="header screen-title" [class]="miGridTitleClass">
        {{ miGridTitle | translate }}<span *ngIf="miGridTitle && miGridTitleFlag" class="total-record"> ({{ totalRecords }}) </span>
      </h1>
    </div>
    <div class="col text-right my-auto">
      <button [id]="headerViewDetailsId" class="p-button p-button-outlined p-button-sm" (click)="viewDetails()" *ngIf="viewDetailsRequired">
        {{ commonService.getLabel('view_details') | translate }}
      </button>
      <i [id]="headerRefreshListId" class="pi pi-refresh" *ngIf="reloadRequired" (click)="refreshList()"></i>
    </div>
  </div>
  <div class="grid" *ngIf="filterInViewDetails">
    <div class="col">
      <div class="grid">
        <div class="col-6">
          <app-input
            [parent]="dashboardFilterForm"
            formCtrlName="filterBySearchInput"
            inputType="input-group"
            [label]="commonService.getLabel('search_lbl')"
            [placeholder]="commonService.getLabel('search_lbl')"
            inputGroupIcon="end"
            inputGroupIconClass="pi pi-search"
            [(ngModel)]="searchValue"
            (input)="searchInputChange($event.target.value)"
          >
          </app-input>
        </div>
        <div class="col-2">
          <app-input
            [parent]="dashboardFilterForm"
            formCtrlName="filterByFromMonth"
            inputType="dropdown"
            [isRequired]="false"
            [arrayPayload]="monthList"
            [label]="commonService.getLabel('from')"
            (onValueChange)="changeInDropdown('from', $event)"
          >
          </app-input>
        </div>
        <div class="col-2">
          <app-input
            [parent]="dashboardFilterForm"
            formCtrlName="filterByToMonth"
            inputType="dropdown"
            [isRequired]="false"
            [arrayPayload]="monthList"
            [label]="commonService.getLabel('to')"
            (onValueChange)="changeInDropdown('to', $event)"
            [placeholder]="defaultToMonth"
          >
          </app-input>
        </div>
        <!-- <div class="col-1 h-full mt-4 ml-auto download-link">
          <button class="p-element p-ripple p-button-outlined p-button-secondary p-button p-button-sm py-3">
            <span class="p-button-label flex">
              <img src="../../../../../assets/images/icon-download-report.png" width="30" height="30" /> </span
            ><span class="p-ink"></span>
          </button>
        </div> -->
      </div>
    </div>
  </div>
  <!-- (window:resize)="onResize($event)" -->
  <div #dataTable class="grid flex-row flex-wrap" [class]="divClass">
    <div class="small-label col-12 ml-4" *ngIf="dropdownLabel">{{ dropdownLabel | translate }}</div>
    <div [class]="isDateField && dateFieldType === 'multiple' ? 'flex col-12 mb-5 gridHead' : 'flex col-12 mb-5 pr-4 gridHead'">
      <div class="col p-0 grid justify-content-start align-items-end">
        <div [class]="searchBarClass" *ngIf="isSearchBarVisible">
          <span class="p-input-icon-right w-full">
            <i class="pi pi-search"></i>
            <input
              [id]="headerSearchValueId"
              class="w-full"
              type="text"
              #textInput
              [(ngModel)]="searchValue"
              pInputText
              placeholder="{{ searchPlaceholder | translate }}"
              (input)="searchInputChange($event.target.value)"
            />
          </span>
        </div>
        <div [class]="searchBarClass" *ngIf="isStoreSearchBarVisible">
          <div class="input-block-search">
            <div>
              <span class="p-input-icon-right w-full">
                <i class="pi pi-search"></i>
                <input
                  [id]="searchValueProductID"
                  class="w-full"
                  type="text"
                  #textInput
                  [(ngModel)]="searchValueProduct"
                  pInputText
                  placeholder="{{ searchSKUPlaceholder | translate }}"
                />
              </span>
            </div>
            <div class="ml-2">
              <span class="p-input-icon-right w-full">
                <i class="pi pi-search"></i>
                <input
                  [id]="searchValueStoreID"
                  class="w-full"
                  type="text"
                  #textInput
                  [(ngModel)]="searchValueStore"
                  pInputText
                  placeholder="{{ searchStorePlaceholder | translate }}"
                />
              </span>
            </div>
            <div class="ml-2"><p-button [id]="searchButtonByProductAndStoreID" label="Search" (click)="getSearchedText()"></p-button></div>
          </div>
        </div>
        <div [class]="dropdownClass" *ngIf="dropdownPlaceholder">
          <p-dropdown
            [options]="dropdownOptions"
            [(ngModel)]="dropdownSelection"
            placeholder="{{ dropdownPlaceholder | translate }} "
            [optionLabel]="dropdownOptionLabel"
            [showClear]="true"
          >
          </p-dropdown>
        </div>
        <div [class]="dropdown2Class" *ngIf="dropdown2Placeholder">
          <p-dropdown
            [options]="dropdown2Options"
            [(ngModel)]="dropdown2Selection"
            placeholder="{{ dropdown2Placeholder | translate }}"
            [optionLabel]="dropdown2OptionLabel"
            [showClear]="true"
          ></p-dropdown>
        </div>
      </div>
      <div class="grid justify-content-evenly marginAuto align-items-end">
        <div class="mr-2 dates-wrapper" *ngIf="isDateField && dateFieldType === 'multiple'">
          <p-calendar
            #startDate
            [id]="startDateCouponCodeID"
            (click)="getDates(startDate, 'startDate')"
            [dateFormat]="dateFormat"
            inputId="dateformat"
            [placeholder]="'startDate' | translate"
            class="mr-2"
            (onClear)="onDateClear('startDate')"
            showClear="true"
            [showIcon]="false"
          ></p-calendar>
          <p-calendar
            #endDate
            [id]="endDateCouponCodeID"
            (click)="getDates(endDate, 'endDate')"
            [dateFormat]="dateFormat"
            inputId="dateformat"
            showClear="true"
            (onClear)="onDateClear('endDate')"
            [placeholder]="'endDate' | translate"
            [showIcon]="false"
          ></p-calendar>
        </div>
        <div class="mr-2" *ngIf="isDateField && dateFieldType === 'single'">
          <div class="label-text">
            <label translate for="dropdownSelection" class="block" translate> {{ datePlaceholder }} </label>
          </div>
          <div class="mr">
            <p-calendar
              [id]="headerDatepickerID"
              #startDate1
              (click)="setOrderOn(startDate1, 'date')"
              [dateFormat]="dateFormat"
              showClear="true"
              (onClear)="setOrderOn(startDate1, 'clear')"
              [placeholder]="datePlaceholder | translate"
            >
            </p-calendar>
          </div>
        </div>
        <div class="mr-2" *ngIf="isStatusDropdown">
          <div class="label-text">
            <label translate for="dropdownSelection" class="block" translate> status </label>
          </div>
          <div [class]="dropdownClass">
            <p-dropdown
              [id]="headerStatusDropdownID"
              (onChange)="sortByStatus($event)"
              [options]="statusDropdownOptions"
              [(ngModel)]="dropdownSelection"
              [placeholder]="'user_dropdown' | translate"
              [optionLabel]="dropdownOptionLabel"
              optionValue="value"
            ></p-dropdown>
          </div>
        </div>
        <div class="mr-2 exportButton" *ngIf="exportButtonLabel">
          <button
            pButton
            type="button"
            label="{{ exportButtonLabel | translate }}"
            (click)="onClick($event)"
            class="p-button-outlined p-button-sm"
            badgeClass=""
          ></button>
        </div>
        <div class="mr-2 refreshClass" *ngIf="refreshButtonIconClass">
          <button
            pButton
            type="button"
            (click)="refreshList()"
            icon="{{ refreshButtonIconClass }}"
            class="p-button-rounded p-button-text relode-btn"
          ></button>
          <div class="mr-2 reloadLabel" (click)="refreshList()" *ngIf="reloadLabel" translate>reload</div>
        </div>

        <div class="mr-1" *ngIf="buttonLabel">
          <button
            [id]="headerAddProductButton"
            pButton
            type="button"
            label="{{ buttonLabel | translate }}"
            [routerLink]="buttonRouterLink"
            icon="pi pi-plus"
            badgeClass="p-badge-primary"
            class="p-button-sm"
          ></button>
        </div>
      </div>
    </div>
    <div class="table-wrapper">
      <p-table
        [id]="headertableData"
        #primeTable
        [lazy]="true"
        [rows]="paginationPageSize"
        [value]="dataSource"
        [paginator]="pagination"
        [sortOrder]="-1"
        [totalRecords]="totalRecords"
        (onLazyLoad)="loadDatasourceLazy($event)"
        [selectionMode]="rowSelection"
        (onRowSelect)="onRowSelect($event)"
        (onRowUnselect)="onRowUnselect($event)"
        [(selection)]="selectedRecords"
        responsiveLayout="stack"
        [breakpoint]="'400px'"
        class="col-12"
        [rowsPerPageOptions]="[10, 25, 50]"
        [currentPageReportTemplate]="paginationText"
        [showCurrentPageReport]="true"
        [autoLayout]="true"
      >
        <ng-template pTemplate="header">
          <tr>
            <th id="" *ngIf="isCheckBoxVisible">
              <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
            </th>

            <ng-container *ngFor="let col of columnDefs; first as isFirst; index as i; trackBy as trackingId">
              <th
                [pSortableColumn]="col.sort ? col.field : ''"
                [ngClass]="col.headerClass"
                [ngStyle]="{ width: col.width ? col.width : 'auto' }"
              >
                {{ col.header }}
                {{
                  col.currency
                    ? this.commonService.createCurrencySymbol(this.commonService.getCurrentCurrencyAndLocaleData().currencyCode)
                    : ''
                }}
                <p-sortIcon *ngIf="col.sort" [field]="col.field"></p-sortIcon>
              </th>

              <th *ngIf="!col.resize">
                {{ col.header }}

                <p-sortIcon *ngIf="col.sort" [field]="col.field"></p-sortIcon>
              </th>
            </ng-container>

            <th style="width: 50px" *ngIf="actionButtonsDefs.length"></th>
          </tr>
        </ng-template>

        <ng-template pTemplate="footer">
          <tr class="" [attr.colspan]="columnDefs.length">
            <td *ngIf="totalRecords === 0">
              <div class="no-records" *ngIf="totalRecords === 0">
                <div class="center-div" translate>lbl_no_records_found</div>
              </div>
            </td>
          </tr>
        </ng-template>

        <ng-template pTemplate="body" let-rowData let-columns="columnDefs" let-rowIndex="rowIndex">
          <tr [pSelectableRowIndex]="rowIndex" [class]="rowIndex % 2 === 0 ? 'even-row' : 'odd-row'">
            <td *ngIf="isCheckBoxVisible">
              <p-tableCheckbox [value]="rowData"></p-tableCheckbox>
            </td>

            <td
              *ngFor="let col of columnDefs; trackBy as trackingId"
              [class]="statusColArray.includes(col.field) ? getClassForStatus(rowData[col.field]) : col.class"
            >
              <!-- {{ rowData[col.field] }} -->
              <!-- <span *ngIf="col.field === 'value'">%</span> -->

              <div
                *ngIf="!textColArray.includes(col.field) && !dateColArray.includes(col.field) && !edit.length && !col.currency"
                innerHTML="{{ col.titleCase ? (rowData[col.field] | titlecase) : rowData[col.field] }}"
                routerLinkActive="router-link-active"
                [class]="!statusColArray.includes(col.field) ? col.class : ''"
                [pTooltip]="col.tooltip ? rowData[col.field] : none"
              ></div>

              <!-- <div
                *ngIf="
                  !textColArray.includes(col.field) && !dateColArray.includes(col.field) && !edit.length && !col.currency && col.jsonValue
                "
                innerHTML="{{ rowData[col.field] ? getJsonParseValue(rowData[col.field]) : '' }}"
                [class]="!statusColArray.includes(col.field) ? col.class : ''"
                [pTooltip]="col.tooltip ? (rowData[col.field] ? getJsonParseValue(rowData[col.field]) : '') : none"
              ></div> -->

              <div
                *ngIf="!textColArray.includes(col.field) && !dateColArray.includes(col.field) && col.currency && !col.currencyAbbrevation"
                [class]="!statusColArray.includes(col.field) ? col.class : ''"
                innerHTML="{{ chartService.convert([col.field]) | currency : ' ' : '' }}"
                routerLinkActive="router-link-active"
              ></div>

              <div
                *ngIf="!textColArray.includes(col.field) && !dateColArray.includes(col.field) && col.currency && !col.currencyAbbrevation"
                [class]="!statusColArray.includes(col.field) ? col.class : ''"
                appCurrencyFormatter
                [value]="rowData[col.field]"
                [withCurrency]="false"
                [withAbbrevation]="false"
                routerLinkActive="router-link-active"
              ></div>

              <div
                *ngIf="!textColArray.includes(col.field) && !dateColArray.includes(col.field) && col.currency && col.currencyAbbrevation"
                [class]="!statusColArray.includes(col.field) ? col.class : ''"
                appCurrencyFormatter
                [value]="rowData[col.field]"
                [withCurrency]="false"
                [withAbbrevation]="true"
                routerLinkActive="router-link-active"
              ></div>

              <div
                [id]="rowData[col.field]"
                *ngIf="!textColArray.includes(col.field) && !dateColArray.includes(col.field) && edit.length && !col.currency"
                innerHTML="{{ rowData[col.field] }}"
                [routerLink]="col.isRouting ? routerLink(rowData) : none"
                [class]="!statusColArray.includes(col.field) ? col.class : ''"
                [pTooltip]="col.tooltip ? rowData[col.field] : none"
                routerLinkActive="router-link-active"
                class="document"
              ></div>

              <div *ngIf="imageColArray.includes(col.field)">
                <img src="{{ rowData[col.field] }}" alt="{{ rowData[col.field] }}" width="40" />
              </div>

              <div *ngIf="col.field === 'brandImage'">
                <img src="{{ rowData['brandImage'] }}" alt="{{ rowData['brandImage'] }}" width="40" />
              </div>

              <div *ngIf="validateInputSwitch(actionSwitch, col)">
                <p-inputSwitch
                  [id]="col.field + rowIndex"
                  (onChange)="handleChange($event, rowIndex)"
                  [(ngModel)]="rowData[actionSwitch[0].field]"
                >
                </p-inputSwitch>
              </div>

              <div *ngIf="validateInputSwitch2(actionSwitch, col)">
                <p-inputSwitch
                  (onChange)="couponSwitches($event, rowIndex, rowData)"
                  [disabled]="actionSwitch?.[0].disabled && rowData.editable === false"
                  [(ngModel)]="rowData[actionSwitch?.[0].field]"
                >
                </p-inputSwitch>
              </div>
              <div *ngIf="dateColArray.includes(col.field)">
                {{ getTimezoneDate(rowData[col.field]) | date : dateTableFormat }}
              </div>
              <div *ngIf="col.field === 'More'">
                <img
                  src="assets/images/icon-more-option.png"
                  (click)="col.class === 'eclipse-pointer' ? more.toggle($event) : ''; moreSelected(rowData)"
                  class="p-1 pointer"
                />
              </div>
              <div *ngIf="isEnableProcessButton && componentName !== uploadProductImageComponent && col.field === 'processStatus'">
                <button
                  *ngIf="!isEnableProcessButtonsWithIcons"
                  pButton
                  type="button"
                  label="{{ processButtonLabel | translate }}"
                  (click)="onProcessFileButtonClick($event, rowIndex)"
                  class="p-button-outlined p-button-sm"
                  badgeClass=""
                ></button>
                <div class="flex justify-content-around flex-wrap card-container" *ngIf="isEnableProcessButtonsWithIcons">
                  <img
                    [id]="uploadPreProcessActionBtn + rowIndex"
                    class="cursor-pointer pl-auto"
                    width="25"
                    height="25"
                    src="/assets/images/pre-process.png"
                    alt=""
                    (click)="downloadFile(rowData.fileId)"
                    [pTooltip]="'uploadedFile' | translate"
                    tooltipPosition="left"
                  />
                  <img
                    [id]="uploadPostProcessActionBtn + rowIndex"
                    *ngIf="isCompleted(rowData['status'])"
                    class="cursor-pointer"
                    width="25"
                    height="25"
                    src="/assets/images/post-process.png"
                    alt=""
                    (click)="downloadFile(rowData.reportFileId)"
                    [pTooltip]="'reportFile' | translate"
                    tooltipPosition="left"
                  />
                  <img
                    [id]="uploadProcessActionBtn + rowIndex"
                    *ngIf="isDraft(rowData['status'])"
                    class="cursor-pointer"
                    width="25"
                    height="25"
                    src="/assets/images/processing.png"
                    alt=""
                    (click)="onProcessFileButtonClick($event, rowIndex)"
                    [pTooltip]="'processFile' | translate"
                    tooltipPosition="left"
                  />
                </div>
              </div>

              <div *ngIf="isEnableProcessButton && componentName === uploadProductImageComponent && col.field === 'processStatus'">
                <div class="flex gap-2 card-container" *ngIf="isEnableProcessButtonsWithIcons">
                  <img
                    [id]="uploadedFileActionBtn + rowIndex"
                    class="cursor-pointer pl-auto"
                    width="25"
                    height="25"
                    src="/assets/images/uploaded_file.svg"
                    alt=""
                    (click)="downloadFile(rowData.fileId)"
                    [pTooltip]="'uploadedFile' | translate"
                    tooltipPosition="left"
                  />
                  <img
                    [id]="evaluatedFileActionBtn + rowIndex"
                    *ngIf="isEvaluatedFileShow(rowData['status'])"
                    class="cursor-pointer"
                    width="25"
                    height="25"
                    src="/assets/images/evaluated_file.svg"
                    alt=""
                    (click)="downloadFile(rowData.reportFileId)"
                    [pTooltip]="'Evaluated File' | translate"
                    tooltipPosition="left"
                  />

                  <img
                    [id]="processedFileActionBtn + rowIndex"
                    *ngIf="isProcessedFileShow(rowData['status'])"
                    class="cursor-pointer"
                    width="25"
                    height="25"
                    src="/assets/images/processed_file.svg"
                    alt=""
                    (click)="downloadFile(rowData.processingFileId)"
                    [pTooltip]="'reportFile' | translate"
                    tooltipPosition="left"
                  />

                  <img
                    *ngIf="isEvaluatedImageUpload(rowData['status'])"
                    class="cursor-pointer"
                    width="25"
                    height="25"
                    src="/assets/images/processing.png"
                    alt=""
                    (click)="onProcessFileButtonClick($event, rowIndex)"
                    [pTooltip]="'processFile' | translate"
                    tooltipPosition="left"
                  />

                  <img
                    *ngIf="isDraftImageUpload(rowData['status'])"
                    class="cursor-pointer"
                    width="25"
                    height="25"
                    src="/assets/images/processing.png"
                    alt=""
                    (click)="onEvaluateFileButtonClick($event, rowIndex)"
                    [pTooltip]="'evaluate file' | translate"
                    tooltipPosition="left"
                  />
                </div>
              </div>

              <div
                *ngIf="col.field === 'maxDiscount' && (rowData['type'] === 'PERCENTAGE' || rowData['type'] === 'percentage')"
                id="iconSize"
              >
                {{ rowData['percentage'] / 100 | percent
                }}<i
                  class="pi pi-info-circle"
                  title="{{ 'maximumAllowedDiscount' | translate }} {{ commonService.createCurrencySymbol(commonService.getCurrentCurrencyAndLocaleData().currencyCode) }} 
                  {{
                    rowData[col.field]
                      | currency
                        : ' '
                        : ''
                        : commonService.getCurrentCurrencyAndLocaleData().minFraction
                        : commonService.getCurrentCurrencyAndLocaleData().localeCode
                  }}"
                ></i>
              </div>
              <div *ngIf="col.field === 'maxDiscount' && (rowData['type'] === 'AMOUNT' || rowData['type'] === 'amount')">
                {{
                  rowData[col.field]
                    | currency
                      : ' '
                      : ''
                      : commonService.getCurrentCurrencyAndLocaleData().minFraction
                      : commonService.getCurrentCurrencyAndLocaleData().localeCode
                }}
              </div>
              <div *ngIf="col.field === 'priceAndDate'">
                <div>
                  <div>
                    {{
                      rowData['discountedPrice']
                        | currency
                          : ' '
                          : ''
                          : commonService.getCurrentCurrencyAndLocaleData().minFraction
                          : commonService.getCurrentCurrencyAndLocaleData().localeCode
                    }}
                  </div>
                  <div class="date-class-for-price" *ngIf="rowData['effectiveFrom'] && rowData['effectiveTo']">
                    {{
                      getTimezoneDate(rowData['effectiveFrom'])
                        | date
                          : (compareDateYear(rowData['effectiveFrom'], rowData['effectiveTo'])
                              ? dateTableFormatNoYear
                              : dateTableFormatShort)
                    }}
                    -
                    {{
                      getTimezoneDate(rowData['effectiveTo'])
                        | date
                          : (compareDateYear(rowData['effectiveFrom'], rowData['effectiveTo'])
                              ? dateTableFormatNoYear
                              : dateTableFormatShort)
                    }}
                  </div>
                </div>
              </div>
            </td>

            <td *ngIf="actionButtonsDefs.length" class="action-icons" (click)="actionBtnClick($event, rowData)">
              <div class="action-icon">
                <div class="grid-menu" appClickOutside (clickOutside)="close($event)">
                  <div *ngFor="let btn of actionButtonsDefs" class="grid-menu-item">
                    <div [class]="btn.class">{{ btn.label }}</div>
                  </div>
                </div>
              </div>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
</div>
<form
  [formGroup]="couponSwitchForm"
  *ngIf="actionSwitch[0]?.field === 'promoCodeEnabled' || actionSwitch[0]?.field === 'comboOfferEnabled'"
>
  <p-dialog [header]="currentSwitchField | translate" [visible]="display" [modal]="true" translate>
    <ng-template pTemplate="content">
      <div class="col-12">
        <div class="label-text">
          <label translate class="block widthAuto">{{ 'grid_status' | translate }} <span class="required-star">*</span></label>
        </div>
        <p-dropdown
          [label]="'grid_status' | translate"
          [options]="statusList"
          placeholder=" "
          (onChange)="getSwitchValue($event)"
          optionLabel="label"
          optionValue="value"
          formControlName="published"
          [parent]="couponSwitchForm"
          inputType="dropdown"
          [isRequired]="true"
        >
        </p-dropdown>
      </div>
      <div class="col-12">
        <app-input
          label="reason"
          formCtrlName="inactiveReason"
          [parent]="couponSwitchForm"
          inputType="text"
          dataCy="attribute-input"
          [isRequired]="true"
        >
        </app-input>
      </div>
    </ng-template>
    <ng-template pTemplate="footer">
      <p-button (click)="cancelStatus()" [label]="'cancel' | translate" styleClass="p-button-secondary p-button-outlined"></p-button>
      <p-button (click)="changeStatus()" [label]="'save' | translate"></p-button>
    </ng-template>
  </p-dialog>
</form>

<p-overlayPanel #more>
  <ng-template pTemplate class="flex flex-column card-container">
    <!-- <button
      pButton
      [label]="'cancel' | translate"
      (click)="clickMore('cancel'); more.hide($event)"
      class="p-button-text p-button-plain overlay-panel flex"
    ></button>
    <button
      pButton
      [label]="'replace' | translate"
      (click)="clickMore('replace'); more.hide($event)"
      class="p-button-text p-button-plain overlay-panel flex"
    ></button>
    <button
      pButton
      [label]="'updateQty' | translate"
      (click)="clickMore('updateQty'); more.hide($event)"
      class="p-button-text p-button-plain overlay-panel flex"
    ></button> -->
    <button
      pButton
      [label]="'readyForDelivery' | translate"
      (click)="clickMore('readyForDelivery'); more.hide($event)"
      class="p-button-text p-button-plain overlay-panel flex"
    ></button>
  </ng-template>
</p-overlayPanel>
