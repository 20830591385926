import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import * as moment from 'moment';
import { CommonBindingDataService } from './../../services/common-binding-data.service';

@Component({
  selector: 'app-bar-chart',
  templateUrl: './bar-chart.component.html',
})
export class BarChartComponent implements OnInit, OnChanges {
  @Input() data: any[] = [];
  @Input() header: string;
  @Output() fetchData: EventEmitter<any> = new EventEmitter();
  colorArray = ['#FF6384', '#36A2EB', '#FFCE56', '#1E88E5', '#9CCC65'];
  headerStr = 'Header';
  buildData: any;
  monthList: Array<any> = [];
  selectedMonth;
  yearList: Array<any> = [];
  selectedYear;
  options;

  constructor(private elm: ElementRef, private commonBindingService: CommonBindingDataService) {
    this.resetData();
    this.setMonthDropDown();
    this.setYearDropDown();
    this.options = {
      scales: {
        yAxes: [
          {
            ticks: {
              beginAtZero: true,
              stepSize: 10,
              suggestedMax: 50,
            },
          },
        ],
      },
    };
  }
  ngOnInit() {
    this.headerStr = this.header;
    this.refreshData();
  }

  ngOnChanges() {
    this.resetData();
    this.data.forEach((item) => {
      this.buildData.labels.push(item.label);
      this.buildData.datasets[0].data.push(item.value);
      this.buildData.datasets[0].backgroundColor.push(this.colorArray[0]);
      this.buildData.datasets[0].hoverBackgroundColor.push(this.colorArray[0]);
    });
  }

  resetData() {
    this.buildData = {
      labels: [],
      datasets: [
        {
          label: this.commonBindingService.getLabel('label_booking'),
          data: [],
          backgroundColor: [],
          hoverBackgroundColor: [],
        },
      ],
    };
  }

  getRandomColor() {
    return '#' + Math.random().toString(16).substr(-6);
  }

  setMonthDropDown() {
    this.monthList = [];
    const months = moment.months();
    months.forEach((element, idx) => {
      this.monthList.push({
        label: element,
        value: idx + 1,
      });
    });
    this.selectedMonth = moment().month() + 1;
  }

  setYearDropDown() {
    this.yearList = [];
    for (let yearCount = 10; yearCount >= 0; yearCount--) {
      this.yearList.push({
        label: moment().year() - yearCount,
        value: moment().year() - yearCount,
      });
    }
    this.selectedYear = moment().year();
  }

  refreshData() {
    this.fetchData.emit({
      month: this.selectedMonth,
      year: this.selectedYear,
    });
  }
}
