<div class="label-text" *ngIf="label">
  <label translate for="Input" class="block">{{ label | translate }} <span *ngIf="isRequired" class="required-star">*</span></label>
</div>
<span>
  <em class="autocomplete-icon pi z-1 absolute"></em>
  <p-autoComplete
    #Input
    [(ngModel)]="selectedItem"
    [suggestions]="arrayPayloadAuto"
    (completeMethod)="onAutoComplete($event)"
    (onSelect)="onChange($event)"
    (onUnselect)="onUnselect($event)"
    [field]="field"
    [showEmptyMessage]="true"
    [multiple]="false"
    [dataKey]="datakey"
    [forceSelection]="forceSelection"
    (onClear)="onClear($event)"
    [disabled]="isDisable"
  >
  </p-autoComplete>
</span>
