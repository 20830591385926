<div class="col-12" *ngIf="label">
  <div class="label-text">
    <label translate for="{{ formCtrlName }}" class="block widthAuto"
      >{{ label | translate }}
      <span *ngIf="isRequired" class="required-star">*</span>
    </label>
  </div>
</div>
<section class="image-box">
  <span class="box-icon">
    <img
      alt="pi-camera"
      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADwAAAA8CAYAAAA6/NlyAAAABmJLR0QA/wD/AP+gvaeTAAADFElEQVRoge2ZP08UQRiHHxCuERMTvRiFK4wF0FlYGIhgayxE8M9XMDb6CahFbLQx9sZEAVFjMBYaJJYS6SBB0AhaqcmBHcmuxczp8d7s3e7O7LIx8yRvMZPZ3/v+bm9mZ2fB4/F4PB7P/0Y7cBNYAXaAMGbsAMvADaAt96oteEB8k1FxP/eqBeeBTf4VtAGcM4wbxt5sLYYzcxODDUNBXw3j5g3j0sZ8Zm4ccR13ZmtxLVcHMegA+oF7QIB7wwFwV+foyMpEBZgGtjIwkHVsAbNAbxKzPwtQuG380l5aMp1B8kVgHDgL9AH7dfTpvnE9xnXeJ3EMu/obB8AUCf5aeuw07taCapykLhKtA6cTGJUMAJ9b5HgJdAM9wFyTcZkbfgcctjBbo6y1ovL01I2tNBnXEluzJYNmCbgKPELtr3/rWNF9V5pcF2V6zw2vY76zY8BajOs/AaOG68taW46f06YrwKu8DQc0ztl9wJ0UWpOot616Bki/kGVi2LT8pzFbiwmD3kxRDAc0PnrGLMzWYkRo9hfF8AdxfQk1H20Nr9O4kH20NSznShpeiPZF4IQD3ePABdH33FbUheEF0ZZF2iC1ZK7EuDD8TbRPOdCM0pK5nJB0jnSJ67dTaETFttDuSqGxCxd3OMvTxUC0ret1YfioaH93oBmldcxW0IXhbtFedKAZpVUIw2dE+5kDzSitIYfaf0m6KMi70AmsptCRsUbjxmMphY5zw6at5agDw/IZXJitZYg6lpFMWpi9ZdCbLZLhAPUKV087cDuF1gSNa8tgyroyMxyizqDKBr0R4s3pVczb0jLwpYiGQ6KPeDqBy8BD1OfQbR3Luu+SHiMpofbPNjVlajgE3gNH4iRqwSHgrYN6WmKbIES9y8o5nYRB7P7GuRsOUQvZDOpxEpd+4KnDGhoMmzb+sX6VhCyhXt4XUPvjTd3fg9ouDqEWrJMZ5G6LbGi2gAMZJN4LqsDB+g7TXvpNPrXkwus4g3pRnxpdzqO9iB/s/jrRlArqrLlagMKTRhV4nMSsx+PxeDwej8fjac0foXxpI0pZBp8AAAAASUVORK5CYII="
    />
  </span>
  <a [id]="imageUploadBoxID" class="box-text center" (click)="onClick($event)" translate>browseImageText</a>
  <p-fileUpload
    #fileUpload
    name="files"
    [url]="fileUploadUrl"
    auto="auto"
    (onUpload)="onUpload($event)"
    (onBeforeUpload)="onBeforeSend($event, fileUpload)"
    (onError)="onUploadError($event)"
    [accept]="accept"
    [chooseLabel]="'choose' | translate"
    [maxFileSize]="maxSize"
    [invalidFileSizeMessageSummary]="maxSizeMessage"
    withCredentials="true"
    [disabled]="disabled"
    [ngClass]="'imgClass'"
  >
  </p-fileUpload>
</section>
<div [id]="imageUploadNoteID" [class]="note === 'productRatio' ? 'image-note-class' : 'w-7'">
  <small class="text-500" translate>fileSize</small>
  <small class="text-500" *ngIf="fileType === 'image'" translate>imageFileNote</small>
  <small class="text-500" *ngIf="fileType === 'file'" translate>fileNote</small>
  <small class="text-500" *ngIf="aspectRatio" translate>{{ note }}</small>
  <small class="text-500" *ngIf="!aspectRatio" translate>{{ noteWeb }}</small>
</div>
