import * as moment from 'moment';
import 'moment-timezone';
import { AppSettings } from '../app.settings';

export const noSpaceAtStart = (event: any): boolean => {
  const charCode = event.which ? event.which : event.keyCode;
  if (charCode === AppSettings.CHAR_CODE_32) {
    return false;
  }
  return true;
};

export const getCountryId = () => JSON.parse(localStorage.getItem('userCountry')) || AppSettings.DEFAULT_COUNTRY;

export const getLanguage = () => JSON.parse(localStorage.getItem('userLanguage')) || AppSettings.DEFAULT_LANGUAGE;

export const getMinutes = (currentTime) => Math.floor((currentTime % (1000 * 60 * 60)) / (1000 * 60));

export const getSeconds = (currentTime) => Math.floor((currentTime % (1000 * 60)) / 1000);

export const minuteToCurrentTime = (minutes) => minutes * 60 * 1000 - 1000;

export const getCurrencyCode = (): string => JSON.parse(localStorage.getItem('userCurrency')) || AppSettings.DEFAULT_CURRENCY;

//when sending request with date and time
export const getCurrentTimeDate = (date) => {
  const timeZone = JSON.parse(localStorage.getItem('timeZone')) || AppSettings.DEFAULT_TIMEZONE;
  const selectedDate = date.getDate();
  const month = date.getMonth();
  const year = date.getFullYear();
  const hour = date.getHours();
  const min = date.getMinutes();
  return moment.tz(timeZone).set({ hour, minute: min, second: 0, millisecond: 0, date: selectedDate, month, year });
};

//when sending request of start of day of particular date
export const getCurrentStartOfDay = (date) => {
  const timeZone = JSON.parse(localStorage.getItem('timeZone')) || AppSettings.DEFAULT_TIMEZONE;
  const selectedDate = date.getDate();
  const month = date.getMonth();
  const year = date.getFullYear();

  return moment.tz(timeZone).set({ hour: 0, minute: 0, second: 0, millisecond: 0, date: selectedDate, month, year });
};

//when sending request of End of day of particular date
export const getCurrentEndOfDay = (date) => {
  const timeZone = JSON.parse(localStorage.getItem('timeZone')) || AppSettings.DEFAULT_TIMEZONE;
  const selectedDate = date.getDate();
  const month = date.getMonth();
  const year = date.getFullYear();

  return moment.tz(timeZone).set({ hour: 23, minute: 59, second: 59, millisecond: 0, date: selectedDate, month, year });
};

//when getting response to convert date to local to attach calender same date as that timezone
export const getDateFromMoment = (date) => {
  const timeZone = JSON.parse(localStorage.getItem('timeZone')) || AppSettings.DEFAULT_TIMEZONE;
  const selectedDate = moment(date).tz(timeZone).get('date');
  const month = moment(date).tz(timeZone).get('month');
  const year = moment(date).tz(timeZone).get('year');
  return new Date().setFullYear(year, month, selectedDate);
};

//when getting response to convert date and time to local to attach calender same date as that timezone
export const getDateTimeFromMoment = (date) => {
  const timeZone = JSON.parse(localStorage.getItem('timeZone')) || AppSettings.DEFAULT_TIMEZONE;
  const selectedDate = moment(date).tz(timeZone).get('date');
  const month = moment(date).tz(timeZone).get('month');
  const year = moment(date).tz(timeZone).get('year');
  const hour = moment(date).tz(timeZone).get('hour');
  const min = moment(date).tz(timeZone).get('minutes');
  return new Date(year, month, selectedDate, hour, min, 0, 0);
};

//to set time same as timezone date
export const getTimeZoneCurrentDateTime = () => {
  const timeZone = JSON.parse(localStorage.getItem('timeZone')) || AppSettings.DEFAULT_TIMEZONE;
  const year = moment.tz(timeZone).get('year');
  const month = moment.tz(timeZone).get('month');
  const day = moment.tz(timeZone).get('date');
  const hour = moment.tz(timeZone).get('hour');
  const min = moment.tz(timeZone).get('minutes');
  return { year, month, day, hour, min };
};

export const getDashboardDefaultValues = (): {
  month: Array<string>;
  frequency: Array<string>;
  defaultCategory: string;
  defaultFrequency: string;
  criteria: Array<string>;
  defaultCriteria: string;
  pointOfOrderOrigin: Array<string>;
} => {
  switch (getLanguage()) {
    case 'en':
      return {
        month: moment.months(),
        frequency: ['monthly', 'weekly'],
        defaultFrequency: 'monthly',
        defaultCategory: 'All',
        defaultCriteria: 'Overall',
        criteria: ['Overall'],
        pointOfOrderOrigin: [`App`, 'Marketplace', 'Call Center'],
      };
    case 'es':
      return {
        month: [
          'Enero',
          'Febrero',
          'Marzo',
          'Abril',
          'Mayo',
          'Junio',
          'Julio',
          'Agosto',
          'Septiembre',
          'Octubre',
          'Noviembre',
          'Diciembre',
        ],
        frequency: ['mensual', 'semanalmente'],
        defaultFrequency: 'mensual',
        defaultCategory: 'Todos',
        defaultCriteria: 'General',
        criteria: ['General'],
        pointOfOrderOrigin: [`Aplicación`, 'Mercado', 'Centro de llamadas'],
      };
    default:
      return {
        month: moment.months(),
        frequency: ['monthly', 'weekly'],
        defaultFrequency: 'monthly',
        defaultCategory: 'All',
        defaultCriteria: 'Overall',
        criteria: ['Overall'],
        pointOfOrderOrigin: [`App`, 'Marketplace', 'Call Center'],
      };
  }
};
export const valueInRange = (value, range) => Number(value) >= range;

export const convertNumberIntoCurrency = (value, range, currency, locale, withCurrency, maximumFractionDigits) =>
  (Math.abs(Number(value)) / range).toLocaleString(
    locale,
    withCurrency
      ? ({
          style: 'currency',
          currencyDisplay: 'narrowSymbol',
          currency,
          maximumFractionDigits,
        } as Intl.NumberFormatOptions)
      : {}
  );
