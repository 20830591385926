<div class="p-fluid grid" [formGroup]="parent">
  <div class="field col-12 calendar-with-clear">
    <div class="label-text">
      <label translate for="{{ formCtrlName }}" class="block widthAuto"
        >{{ label }}
        <span *ngIf="bracketLabel" translate> <span>(</span> {{ bracketLabel }} <span>)</span> </span>
        <span *ngIf="isRequired" class="required-star">*</span></label
      >
    </div>
    <p-calendar
      (onSelect)="getDate($event)"
      id="{{ formCtrlName }}"
      [formControlName]="formCtrlName"
      [showTime]="showTime"
      [readonlyInput]="true"
      [showSeconds]="showSeconds"
      [timeOnly]="timeOnly"
      [hourFormat]="hourFormat"
      [inputId]="formCtrlName"
      [disabledDays]="disabledDays"
      [showIcon]="showIcon"
      [ngClass]="validateError(formCtrlName)"
      [disabled]="disabled"
      [minDate]="minDate"
      [maxDate]="maxDate"
      (onSelect)="onSelectDate($event)"
      dateFormat="dd/mm/yy"
      [showClear]="true"
      (onClear)="onClearDate()"
    ></p-calendar>
    <small *ngIf="validateInput(formCtrlName)" id="{{ formCtrlName }}-help" class="small-error p-error block"
      ><span translate>{{ label | translate }} </span> is required.</small
    >
  </div>
</div>
