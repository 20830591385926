import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-image-box',
  templateUrl: './image-box.component.html',
  styleUrls: ['./image-box.component.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImageBoxComponent implements OnInit {
  @Input() imageName;
  @Input() imageDesc;
  @Input() isCloseBtn = false;
  @Input() isDisable = false;
  @Input() imageSrc = 'https://picsum.photos/seed/picsum/200/300';
  @Output() onClose = new EventEmitter<any>();
  constructor() {}

  ngOnInit(): void {}

  onClick(e) {
    this.onClose.emit(e);
  }
}
