import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
@Pipe({
  name: 'ago',
})
export class AgoPipe implements PipeTransform {
  transform(value: any, args?: any): string {
    return moment(value).fromNow(true);
  }
}
