<agm-map
  [zoom]="14"
  [latitude]="lat"
  [longitude]="lng"
  [streetViewControl]="false"
  (mapReady)="onMapReady($event)"
  [fullscreenControl]="true"
  [mapTypeControl]="true"
>
  <agm-marker [latitude]="lat" [longitude]="lng" [markerDraggable]="false">
    <agm-info-window>
      <strong>InfoWindow content</strong>
    </agm-info-window>
  </agm-marker>
</agm-map>
