<p-fileUpload
  #fileUpload
  [id]="fileUploadBoxID"
  name="files"
  [url]="fileUploadUrl"
  auto="auto"
  (onUpload)="onUpload($event)"
  (onBeforeUpload)="onBeforeSend($event, fileUpload)"
  (onError)="onUploadError($event)"
  [accept]="accept"
  [chooseLabel]="'choose' | translate"
  [maxFileSize]="maxSize"
  [invalidFileSizeMessageSummary]="maxSizeMessage"
  withCredentials="true"
  chooseIcon="pi pi-upload"
  [fileLimit]="fileLimit"
  [disabled]="disabled"
>
  <ng-template pTemplate="content">
    <p *ngIf="!uploadedFiles.length" class="no-file-selected-placeholder" translate>label_no_file</p>
    <ul *ngIf="uploadedFiles.length">
      <li *ngFor="let file of uploadedFiles; let i = index" (click)="openLink(file)" class="file-list">
        {{ i + 1 }}. {{ file.originalFileName }}
        <button [disabled]="deleteBtnDisabled" class="pi pi-trash col-1 trash-btn" (click)="deleteFile(i)" translate></button>
      </li>
    </ul>
  </ng-template>
</p-fileUpload>
