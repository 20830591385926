<div class="header-strip">
  <div class="first-item">
    <a id="home-link-MenuHideUnhide" class="header-btn" (click)="onSideMenuClick($event)"><i class="pi pi-bars h-btn"></i> </a>
  </div>
  <a class="drop">
    <p-dropdown
      id="home-dropdown-country"
      #countryId
      *ngIf="selectedCountry"
      class="c-dropdown"
      [options]="countries"
      [(ngModel)]="selectedCountry"
      optionLabel="fullNameLocale"
      [filter]="false"
      dataKey="fullNameLocale"
      filterBy="label"
      [showClear]="false"
      [placeholder]="'selectCountry' | translate"
      (onChange)="changeCountry()"
      [disabled]="true"
    >
      <ng-template pTemplate="selectedItem">
        <div class="country-item country-item-value" *ngIf="selectedCountry">
          <img [src]="selectedCountry.flagImgUrl" [class]="'flag flag-' + selectedCountry.countryId.toLowerCase()" />
          <div>{{ selectedCountry.fullNameLocale }}</div>
        </div>
      </ng-template>
      <ng-template let-country pTemplate="item">
        <div class="country-item">
          <img [src]="country.flagImgUrl" [class]="'flag flag-' + country.countryId.toLowerCase()" />
          <div>{{ country.fullNameLocale }}</div>
        </div>
      </ng-template>
    </p-dropdown>
  </a>

  <ul class="header--menu fadeInDown">
    <li class="user-profile" (click)="onProfileClick($event)">
      <a><img alt="Profile image" src="assets/images/default_profile.png" /></a>
      <div class="name">
        <div id="home-label-user-name" class="user-name">{{ userFname }} {{ userLname }}</div>
        <div id="home-label-user-role" class="user-role" translate>{{ userRole }}<i class="pi pi-chevron-down"></i></div>
      </div>
      <ul class="fadeInDown">
        <li role="menuitem">
          <div (click)="showDialog()" id="home-change-password" class="change-password-input">
            <i class="pi pi-lock"></i><span translate>changePassword</span>
          </div>
        </li>
      </ul>
    </li>
  </ul>
</div>

<p-dialog
  id="home-dialog-changePassword"
  [header]="'changePassword' | translate"
  [(visible)]="passwordChangePopup"
  [modal]="true"
  [style]="{ width: '40vw', height: 'auto' }"
  [draggable]="false"
  [resizable]="true"
  [closable]="true"
  (onHide)="resetForm()"
  styleClass="change-password"
>
  <div class="change-password">
    <form [formGroup]="changePasswordForm">
      <div class="flex-column col-12">
        <div class="form-control col-12">
          <app-input
            id="home-input-oldPassword"
            [parent]="changePasswordForm"
            label="oldPassword"
            formCtrlName="oldPassword"
            dataCy="newUser-oldPassword"
            [isRequired]="true"
            inputType="text"
            [placeholder]="'oldPasswordPlaceholder' | translate"
          ></app-input>
        </div>
        <div class="form-control col-12">
          <app-input
            id="home-input-newPassword"
            [parent]="changePasswordForm"
            label="newPasswordChange"
            formCtrlName="newPassword"
            dataCy="newUser-newPassword"
            [isRequired]="true"
            inputType="text"
            (paste)="(false)"
            (copy)="(false)"
            (cut)="(false)"
            [placeholder]="'newPassword' | translate"
          ></app-input>
        </div>
        <div class="form-control col-12">
          <app-input
            id="home-input-confirmPassword"
            [parent]="changePasswordForm"
            label="confirmPassword"
            formCtrlName="confirmPassword"
            dataCy="newUser-confirmPassword"
            [isRequired]="true"
            inputType="text"
            (paste)="(false)"
            (copy)="(false)"
            (cut)="(false)"
            [placeholder]="'confirmPasswordPlaceholder' | translate"
          ></app-input>
        </div>
        <div class="note-password ml-1 col-12" translate>textPasswordPattern</div>
        <div class="update-button pr-2">
          <button
            pButton
            id="home-save-button-changePassword"
            data-cy="save-btn"
            class="pl-3 pr-3 color mt-3"
            type="button"
            translate
            (click)="savePassword()"
          >
            save
          </button>
        </div>
      </div>
    </form>
  </div>
</p-dialog>
