<section class="image-box">
  <a *ngIf="isCloseBtn && !isDisable" (click)="onClick($event)" class="icon-position flex justify-content-end"
    ><a class="pi pi-times-circle"></a>
  </a>
  <img src="{{ imageSrc }}" alt="image" />
  <div *ngIf="imageName" class="image-name" [pTooltip]="imageName" tooltipPosition="bottom">
    <small>{{ imageName }}</small>
  </div>
  <div class="image-desc" *ngIf="imageDesc">{{ imageDesc }}</div>
</section>
