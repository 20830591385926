import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControlName, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss'],
  //changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CalendarComponent implements OnInit, AfterViewInit {
  @ViewChild('Input') input: ElementRef;
  @Output() onSelect = new EventEmitter<any>();
  @Input() label: any;
  @Input() parent: FormGroup;
  @Input() formCtrlName: FormControlName;
  @Output() onValueChange = new EventEmitter<any>();
  @Output() onClear = new EventEmitter<any>();
  @Input() bracketLabel: string | number;
  @Input() isRequired = false;
  @Input() dataCy = '';
  @Input() showTime = true;
  @Input() showSeconds = false;
  @Input() timeOnly = false;
  @Input() hourFormat = 24; // 12 | 24
  @Input() disabledDays = []; //'[0,6]';
  @Input() showIcon = 'true';
  @Input() timeonly = 'timeonly';
  @Input() disabled = false;
  @Input() minDate = '';
  @Input() maxDate = '';
  currentDate = '';

  constructor() {}

  ngOnInit(): void {
    this.parent.controls[`${this.formCtrlName}`].valueChanges.subscribe((ele) => {});
  }

  ngAfterViewInit() {
    this.input?.nativeElement?.setAttribute('aria-describedby', `${this.formCtrlName}-help`);
    if (this.dataCy) {
      this.input?.nativeElement?.setAttribute('data-cy', `${this.dataCy}`);
    }
  }

  validateInput(ctrlName) {
    return this.parent.controls[ctrlName].errors && (this.parent.controls[ctrlName].dirty || this.parent.controls[ctrlName].touched);
  }

  validateError(ctrlName) {
    if (this.parent.controls[ctrlName].errors && this.parent.controls[ctrlName].dirty && this.parent.controls[ctrlName].touched) {
      return 'ng-invalid ng-dirty';
    } else {
      return '';
    }
  }

  onSelectDate(event) {
    this.onSelect.emit(event);
  }
  onClearDate() {
    this.onClear.emit();
  }
  getDate(event) {
    this.onValueChange.emit(event);
  }
}
