import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-cancel-save-button',
  templateUrl: './cancel-save-button.component.html',
  styleUrls: ['./cancel-save-button.component.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CancelSaveButtonComponent implements OnInit {
  @Output() onSubmitClicked = new EventEmitter();
  @Output() onCancelClicked = new EventEmitter();
  @Input() saveDisabled = false;
  @Input() btnLabel = 'saveNext';
  @Input() isSaveVisible = true;
  @Input() cancleButtonID = '';
  @Input() saveButtonID = '';
  iconArray = ['publish', 'Save'];
  constructor() {}

  ngOnInit(): void {}

  onClick(e) {
    this.onSubmitClicked.emit(e);
  }
  onCancel(e) {
    this.onCancelClicked.emit(e);
  }
}
