import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { AssignSearchService } from './assign-search.service';

@Component({
  selector: 'app-assign-search',
  templateUrl: './assign-search.component.html',
  providers: [AssignSearchService],
})
export class AssignSearchComponent implements OnChanges {
  @Input() placeholder = '';
  @Input() url = '';
  @Input() urlDisp = '';
  @Input() multipleAssignments: boolean;
  @Input() serviceIds = [];
  @Input() vehicleTypeId = [];
  @Input() latitude = '';
  @Input() longitude = '';
  @Input() propertyName = '';
  @Input() negativeButtonLabel = '';
  @Input() positiveButtonLabel = '';
  @Input() associates;
  @Input() roleCode;
  @Input() bookingId;
  @Input() bookingBoolean = false;
  @Output() buttonClickAction = new EventEmitter<any>();
  @Output() buttonClickToGetObject = new EventEmitter<any>();
  @Output() cancelPopup = new EventEmitter<any>();
  @Output() searchQuery = new EventEmitter<any>();

  searchText = '';
  searchTextSet = [];
  eventSet: any[] = [];
  searchTextResult: any[];

  selectedItem: any;
  selectedObject: any;

  private searchList: any[];

  constructor(private assignSearchService: AssignSearchService) {}

  ngOnChanges() {
    if (this.associates !== null) {
      this.searchTextSet = this.associates;
    }
  }

  search(event) {
    this.searchText = event.query;
    this.assignSearchService.searchAssignment(this.getSearchUrl()).subscribe((data) => {
      if (data != null) {
        this.searchList = data;
        const c = this;
        this.searchTextResult = [];
        this.searchList.forEach((item) => {
          if (item.name === undefined) {
            if (item.lastName === null) {
              item.lastName = '';
            }
            data = {
              referrenceNumber: item.referrenceNumber,
              name: item.firstName + ' ' + item.lastName,
              id: item.driverId,
            };
            c.searchTextResult.push(data);
          } else {
            c.searchTextResult.push(item);
          }
        });
      }
    });
  }

  assign() {
    if (this.multipleAssignments === true) {
      this.associates = this.searchTextSet;
      this.buttonClickToGetObject.emit(this.associates);
    } else {
      this.buttonClickAction.emit(this.selectedItem);
      this.buttonClickToGetObject.emit(this.selectedObject);
    }
    this.associates = [];
    this.searchText = '';
    this.searchTextSet = [];
  }

  cancel() {
    this.selectedItem = null;
    this.buttonClickAction.emit(null);
    this.cancelPopup.emit(null);
    this.searchText = '';
    this.searchTextSet = [];
  }

  onSelect(event: any) {
    this.selectedObject = event;
    this.selectedItem = event.id;
    if (this.multipleAssignments === true) {
      this.eventSet.push(event);
    } else {
      this.searchText = `${event.referrenceNumber}-${event.name}`;
    }
  }

  onUnselect(event: any) {
    this.searchTextSet.splice(
      this.searchTextSet.findIndex((item) => item.id === event.id),
      1
    );
    this.eventSet = this.searchTextSet;
    this.associates = this.searchTextSet;
  }

  private getSearchUrl() {
    if (!this.bookingBoolean) {
      if (this.url !== null && this.url !== '') {
        return `${this.url}?serviceTypeIds=${(this.serviceIds && this.serviceIds.toString()) || ''}&searchText=${this.searchText}`;
      } else {
        return `${this.urlDisp}?searchText=${this.searchText}&roleCode=${this.roleCode}`;
      }
    } else {
      if (this.url !== null && this.url !== '') {
        return (
          `${this.url}?serviceTypeId=${(this.serviceIds && this.serviceIds.toString()) || ''}&` +
          `searchText=${this.searchText}&vehicleTypeId=${(this.vehicleTypeId && this.vehicleTypeId.toString()) || ''}` +
          `&latitude=${this.latitude}&longitude=${this.longitude}&bookingid=${this.bookingId}`
        );
      }
    }
  }
}
