import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
@Pipe({
  name: 'minutsformat',
})
export class MinutsFormatPipe implements PipeTransform {
  transform(value: any, args?: any): string {
    let format = 'hh:mm A';
    if (args !== undefined) {
      format = args;
    }
    return moment.utc().startOf('day').add(value, 'minutes').format(format);
  }
}
