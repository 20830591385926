import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
})
export class DropdownComponent implements OnInit {
  @Output() onValueChange = new EventEmitter<any>();
  @Input() arrayPayload: [];
  @Input() optionLabel;
  @Input() optionValue;
  @Input() datakey;
  @Input() label;
  @Input() placeholder: any;
  @Input() selectedItem: any;

  constructor() {}

  ngOnInit(): void {}

  onChange(changes) {
    this.onValueChange.emit(changes);
  }
}
