<div class="grid column-gap-3 justify-content-end">
  <div>
    <button
      [id]="cancleButtonID"
      pButton
      type="button"
      [label]="'cancel' | translate"
      (click)="onCancel($event)"
      class="button p-button-outlined"
    ></button>
  </div>

  <div *ngIf="isSaveVisible">
    <button
      pButton
      [id]="saveButtonID"
      class="button"
      type="button"
      [label]="btnLabel | translate"
      [icon]="!iconArray.includes(btnLabel) ? 'pi pi-chevron-right' : 'none'"
      iconPos="right"
      (click)="onClick($event)"
      [disabled]="saveDisabled"
    ></button>
  </div>
</div>
