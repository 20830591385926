import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-image-box-with-dropdown',
  templateUrl: './image-box-with-dropdown.component.html',
  styleUrls: ['./image-box-with-dropdown.component.scss'],
  //changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImageBoxWithDropdownComponent implements OnInit {
  @Input() options: any[];
  @Input() src: string;
  @Output() onClose = new EventEmitter<any>();
  @Output() onSelectionChange = new EventEmitter<any>();
  @Output() onPositionChange = new EventEmitter<any>();
  @Input() selectedValue = 'Primary';
  @Input() selectedPosition = 1;

  dropdownSelection = [
    { name: 'Primary', value: 'Primary' },
    { name: 'Banner', value: 'Banner' },
    { name: 'Default', value: 'Default' },
  ];
  constructor() {}
  ngOnInit(): void {}

  onChange(e) {
    this.onSelectionChange.emit(e);
  }

  positionChange(e) {
    this.onPositionChange.emit(e);
  }

  onClick(e) {
    this.onClose.emit(e);
  }
}
