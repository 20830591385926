/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/quotes */
/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { TranslateLoader } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';

@Injectable()
export class MiTranslateLoaderService implements TranslateLoader {
  getTranslation(lang: string): Observable<any> {
    switch (lang) {
      case 'en':
        return of({
          breadcrumbs: {
            Dashboard: 'Dashboard',
            Sales_history_by_period: ' > Sales history by period',
            Sales_by_category: ' > Sales by category',
            Sales_by_products: ' > Sales by products',
            Sales_by_stores: ' > Sales by stores',
            Sales_by_customers: ' > Sales by customers',
            Sales_by_promotions: ' > Sales by promotions',
            Settings: 'Settings',
            Terms_and_conditions: '> Terms and conditions',
            Products: 'Products',
            Product_management: ' > Product Management',
            Edit_product: ' > Edit Product',
            Add_product: ' > Add Product',
            Upload_products: ' > Upload Products',
            Brand: ' > Brand',
            Add_brand: ' > Add Brand',
            Edit_brand: ' > Edit Brand',
            Attributes: ' > Attributes',
            Add_attributes: ' > Add Attributes',
            Edit_attributes: ' > Edit Attributes',
            Category: ' > Category',
            Add_category: ' > Add Category',
            Edit_category: ' > Edit Category',
            Inventory_management: ' > Inventory Management',
            Order: 'Order',
            Order_list: '> Order List',
            Order_details: ' > Order Details',
            Order_reports: '> Download Report',
            download: 'Download',
            Admin: 'Admin',
            Add_new_user: ' > Add New User',
            Edit_user: ' > Edit User',
            Store_management: 'Store Management',
            Add_store: ' > Add Store',
            Edit_store: ' > Edit Store',
            Marketing: 'Marketing > ',
            Banner_list: 'Banner',
            Add_banner: ' > Add Banner',
            Edit_banner: ' > Edit Banner',
            Coupon_code: 'Coupon Code',
            Add_coupon: ' > Add Coupon',
            Edit_coupon: ' > Edit Coupon',
            Combo_offer: 'Combo Offer',
            Add_combo_offer: ' > Add Combo Offer',
            Edit_combo_offer: ' > Edit Combo Offer',
            Notification: 'Notification',
            Add_notification: ' > Add Notification',
            Notification_details: ' > Notification Details',
            Customer_management: 'Customer Management',
            Details: ' > Customer Details',
            Upload_products_management: ' > Upload Products Management',
          },
          evaluatedFile: 'Evaluated File',
          rememberMe: 'Remember Me',
          login: 'Login',
          Username: 'Email',
          Password: 'Password',
          createPassword: 'Create Password',
          validContactNumber: 'valid Contact Number',
          validEmail: 'valid Email',
          textPasswordPattern:
            '(Minimum eight characters, at least one letter, one number and one special characters: @, $, !, %, *, #, ?, or &)',
          enterPassword: 'Enter password',
          'Username is should be greater than x': 'Username is should be greater than {{minval}}',
          forgot_password: 'Forgot password?',
          emailOrUname: 'Email or User name',
          emailOrUnamePlaceholder: 'Enter email or user name',
          email_required: 'Email Required',
          passwordRequired: 'Password is required',
          enterEmail: 'Enter your email',
          otp: 'OTP',
          otpSent: 'We have sent you One Time Password to your email',
          enterOTP: 'Please Enter OTP',
          sendOTP: 'Send OTP',
          resendOTP: 'Resend OTP',
          resetPassword: 'Reset Password',
          newPassword: 'Enter New Password',
          newPasswordChange: 'New Password',
          oldPassword: 'Old Password',
          oldPasswordPlaceholder: 'Enter Old Password',
          confirmPasswordPlaceholder: 'Enter Confirm Password',
          confirmPassword: 'Confirm Password',
          changePassword: 'Change Password',
          reEnterPassword: 'Re-enter New Password',
          notMatchedPassword: 'Password and Confirm Password did not match.',
          requiredPassword: 'Password is required',
          passwordResetSuccess: 'Password Reset',
          verifyOTP: 'Verify OTP',

          grid_title: 'TITLE',
          ID_title: 'ID',
          grid_trial_start_date: 'START DATE',
          grid_trail_end_date: 'END DATE',
          grid_patients: 'PATIENTS',
          lbl_no_records_found: 'No records found',
          error: 'Error',
          historyData: 'Historic data',
          currentData: 'Current',

          sidebar_lbl_customers: 'Customers',
          sidebar_lbl_merchants: 'Merchants',
          sidebar_lbl_auctions: 'Auctions',
          sidebar_lbl_support: 'Support',
          sidebar_lbl_vaquitas: 'Vaquitas',
          sidebar_lbl_notifications: 'Notifications',
          sidebar_lbl_admin_users: 'Admin users',
          sidebar_lbl_settings: 'Settings',

          sidebar_lbl_dashboard: 'Dashboard',
          sidebar_lbl_product: 'Product',
          sidebar_lbl_product_management: 'Settings',
          sidebar_lbl_product_bulkUpload: 'Settings',
          sidebar_lbl_productMaster: 'Settings',
          sidebar_lbl_orderManagement: 'Order Management',
          sidebar_lbl_inventoryManagement: 'Inventory Management',
          sidebar_lbl_admin: 'Admin',
          sidebar_lbl_storeManagement: 'Store Management',
          sidebar_lbl_marketing: 'Marketing',
          sidebar_lbl_promocode: 'Promocode',
          sidebar_lbl_comboOffer: 'Combo Offer',
          sidebar_lbl_couponCode: 'Coupon Code',
          sidebar_lbl_notification: 'Notification',
          sidebar_lbl_banner: 'Banner',
          sidebar_lbl_customerManagement: 'Customer Management',
          sidebar_lbl_logout: 'Logout',

          sidebarLabelProductManagement: 'Product Management',
          sidebarLabelUploadFiles: 'Upload Products',
          sidebarLabelBrand: 'Brand',
          sidebarLabelCategory: 'Category',
          sidebarLabelInventory: 'Inventory',
          sidebarLabelAttributeMaster: 'Attribute Master',
          downloadInventory: 'Download Inventory',

          msg_logout: 'Are you sure that you want to perform this action?',
          lbl_confirm_idle_session: `Sesion has been idle over its time limit,
        It will be disconnected in 2 minutes. Press Ok now to continue session.`,

          multiselect_placeholder: 'Multiselect Dropdown',
          search_lbl: 'Search',
          searchByStore_lbl: 'Search By Store',
          searchBySKU_lbl: 'Search By Product',
          calendar_start: 'Start Date',
          calendar_end: 'End Date',
          documents_lbl: 'Documents',

          storeSelector: 'Store Selector',
          uploadInventory: 'Upload Inventory',
          inventoryList: 'Inventory List',
          uploadHistory: 'Upload History',
          showing: 'Showing',

          grid_name: 'Name',
          grid_stock: 'Stock',
          grid_contact: 'Contact Number',
          grid_email: 'Email',
          grid_usertype: 'User Type',
          grid_store: 'Store',
          grid_status: 'Status',
          grid_visibile_cart: 'Visibility status in cart',
          grid_action: 'Action',
          grid_units: 'Units',
          grid_revenue: 'Revenue',
          grid_orders: 'Orders',
          grid_slno: '#',
          store_status: 'STATUS',

          unitOfPricePerUnit: 'Unit of price per unit',
          pricePerUnit: 'Price per unit',
          numberOfUnit: 'Number of unit',
          firstname: 'First Name',
          lastname: 'Last Name',
          email: 'Email',
          contactnumber: 'Contact Number',
          usertype: 'User Type',
          assignStorename: 'Assign Store for Store Manager',
          sku: 'sku',
          thumbnail: 'Thumbnail',
          sellingPrice: 'Selling Price',
          price: 'Price',
          user_dropdown: 'Please Select',
          user_toggle: 'Are you sure you want to set the user status as active?',
          user_toggle_uncheck: 'Are you sure you want to set the user status as inactive?',
          activate: 'Activate',
          deactivate: 'Deactivate',
          contactNumber: 'Contact Number',
          cancel: 'Cancel',
          save: 'Save',
          ok: 'Ok',
          addNewAdmin: 'Add New Admin',
          success: 'success',
          userAddedSuccessfully: 'User Added Successfully',
          categoryUpdateSuccessMsg: 'Category updated successfully',
          addNewUser: 'Add New User',
          addNewStore: 'Add New Store',

          //Product General
          productManagement: 'Product Management',
          SaveNext: 'Save & Next',
          publish: 'Publish',
          deepLink: 'Deep Link',

          shortDescription: 'Short Description',
          productName: 'Product Name',
          description: 'Description',
          media: 'Media',
          Category: 'Category',
          productTags: 'Product Tags',
          brand: 'Brand',
          brands: 'Brands',
          relatedProducts: 'Related Products',
          relatedProduct: 'Related Product',
          general: 'General',
          attributes: 'Attributes',
          seo: 'SEO',
          relatedProductsDescription: 'Related products are shown to customers in addition to the item the customer is looking at.',
          publishSuccess: 'Product Published Successfully',
          generalInfo:
            'Below fields are required \n 1.SKU \n 2.Product Name \n 3.Short Description \n 4.Category \n 5.Price \n 6.Price Per Unit \n 7.Unit of Price Per Unit \n 8.Brand',
          categoryPositionSuggestMsg: 'Suggested position is',
          categoryPositionSuggestWarning1: 'Are you sure you want to set the position to ',
          categoryPositionSuggestWarning2: '? The product in ',
          categoryPositionSuggestWarning3: ' will shift to ',

          selectCountry: 'Select Country',
          action: 'ACTION',
          name: 'Name',
          storeNumber: 'Store Number',
          storeId: 'Store Id',
          state: 'State',
          city: 'City',
          status: 'Status',
          store_toggle: 'Are you sure you want to set the store status as online?',
          store_toggle_uncheck: 'Are you sure you want to set the store status as offline?',
          product_toggle:
            'Are you sure you want to set this product as available? By setting this as available, customers will be able to see this product.',
          product_toggle_uncheck:
            "Are you sure you want to set this product as not available? By setting this not available, customers won't be able to see this product.",
          available: 'Available',
          promo_code_toggle_uncheck:
            "Are you sure you want to set this promo code as not available? By setting this not available, customers won't be able to see this promo code in cart.",
          promo_code_toggle:
            'Are you sure you want to set this promo code as available? By setting this as available, customers will be able to see this promo code in cart.',

          promo_code_toggle_uncheck_status: 'Are you sure you want to set this promo code as Inactive?',
          promo_code_toggle_status: 'Are you sure you want to set this promo code active?',

          notAvailable: 'Not Available',
          online: 'Online',
          offline: 'Offline',
          geofencing: 'Geofencing',

          storeDetails: 'Store Details',
          cash: 'Cash',
          pinpad: 'PIN Pad',
          nameOfStore: 'Name of Store',
          contactOfStore: 'Contact Number of Store',
          crStore: 'CR Store',
          emailOfStore: 'Email ID of Store',
          deliveryPartner: 'Preferred Delivery Partner',
          location: 'Location',
          address: 'Address',
          country: 'Country',
          zip: 'Zip code',
          latitude: 'Latitude',
          longitude: 'Longitude',
          setStoreTime: 'Set Store Time',
          addStoreManager: 'Add Store Manager',
          storeManagerName: 'Store Manager Name',
          startTime: 'Start Time',
          endTime: 'End Time',
          addDeliveryPartner: 'Add Delivery Partner',
          geofenceRequired: 'please draw Polygon to add geofence',
          selectDeliveryPartner: 'Select Delivery Partner',
          preference: 'Preference',
          storeAddedSuccessfully: 'Store Added Successfully',
          minimumFreeShippingError: 'Minimum cart value cannot be less than minimum cart amount for delivery',

          edit: 'Edit',
          attributesMaster: 'Attributes Master',

          valueInDollars: 'Value in $',
          itemCount: 'Item Count',
          orderedOn: 'Ordered On',
          orderList: 'Order List',
          attributesSuccess: 'Attributes Added Successfully',
          attributesError: 'Please add Attributes',
          addAttributes: 'Add Attributes',
          editAttributes: 'Edit Attributes',
          attributesId: 'Attributes Id',
          type: 'Type',
          attributesName: 'Attributes Name',
          listValues: 'List Values',
          attributesValue: 'Attributes Values',
          attributesType: 'Attributes Type',

          //Order management
          reason: 'Reason',
          cancelOrder: 'Cancel Order',
          cancelItem: 'Cancel Item',
          customerName: 'Customer Name',
          custName: 'Customer Name',
          orderStatus: 'Order Status',
          orderItemCount: 'Order Item Count',
          orderDate: 'Order Date',
          paymentAmount: 'Payment Amount',
          assignStore: 'Assign Store',
          assignDeliveryPartner: 'Assign Delivery Partner',
          transactionId: 'Transaction Id',
          totalCost: 'Total Cost',
          cancelMessage: 'Do you really want to cancel this order',
          cancelMessageItem: 'Do you really want to cancel this item',
          quantity: 'Quantity',
          updateQty: 'Update Quantity',
          replaceItem: 'Replace Item',
          unitCostOf: 'Unit cost of',
          totalCostOf: 'Total cost of',
          finalBillValue: 'Final bill value',
          changeFromBillValue: 'Change from original bill value',
          subtotal: 'Subtotal',
          promoCodeDiscount: 'Promo Code Discount',
          totalAmount: 'Total Amount',
          cancelOrderSuccess: 'Order cancelled successfully.',
          updateOrderSuccess: 'Order updated successfully.',
          trackingInformation: 'Tracking Information',
          finalizeAllItems: 'Finalize All Items',
          replace: 'Replace',
          readyForDelivery: 'Ready for Delivery',

          searchEngineOptimization: 'Search Engine Optimization',
          metaTitle: 'Meta title',
          metaTagKeywords: 'Meta tag keywords',
          separatedByComma: '(Separated by Comma)',
          metaDescription: 'Meta description',
          urlKey: 'URL Key',
          seoSuccessfully: 'SEO Attributes Added Successfully',
          seoInfo: 'Below fields are required \n 1.Meta Title \n 2.Meta Tag Keywords \n 3.Meta Description \n 4.URL key.',
          productInformation: 'Product Information',
          skuTitle: 'SKU',
          stockKeepingUnit: 'Stock keeping Unit',
          selectCategory: 'Select category',
          selectFrequency: 'Select Frequency',
          from: 'From',
          to: 'To',
          To: 'to',
          of: 'of',
          entries: 'entries',
          startDate: 'Start Date',
          endDate: 'End Date',
          setDateTime: 'Set Date & Time',
          skuDescription: 'SKU (Stock Keeping Unit)',
          productAddedSuccess: 'Product Added Successfully',
          deleteSuccess: 'Delete Product Success',
          position: 'Position',
          productImages: 'Product Images',
          addRelatedProduct: 'Add related product',
          relatedProductSuccess: 'Related Products Added Successfully',
          imageSizeNote: 'Note : The image size should be as per width*height aspect ratio',
          image: 'IMAGE',
          imagePrimaryIssue: 'Only one primary image can be upload',
          priceIssue: 'Selling price must be smaller then actual price',
          imageMandatoryIssue: 'Please Upload Image',

          // ! Brand Screen
          brandName: 'Brand Name',
          nestUnderCategory: 'Nest Under Category',
          addBrand: 'Add Brand',
          editBrand: 'Edit Brand',
          brandErrorDetail: 'Please fill out all required fields',
          brandSuccessDetail: 'Brand added successfully',
          brandUpdatedDetail: 'Brand updated successfully',
          brandAvailable: 'Available',
          brandNotAvailable: 'Not Available',
          serverError: 'Server Error',
          brandImage: 'Brand Image',
          brandAvailableMessage:
            'Are you sure you want to set brand as available? By setting this available, customers will be able to see the brand.',
          brandNotAvailableMessage:
            "Are you sure you want to set brand as not available? By setting this not available, customers won't be able to see this brand.",

          uploadProducts: 'Upload Products',
          fileUploadSuccess: 'File Uploaded Successfully',
          fileNotSelected: 'File Not Selected',
          upload: 'Upload',
          choose: 'Choose',

          attributeErrorDetail: 'Please fill out all required fields',
          attributeSuccessDetail: 'Attribute added successfully',
          attributeScreenMsg: 'Please add Attributes',
          relatedProductScreenMsg: 'Please add Product',

          date: 'date',
          fileUpload: 'file upload',
          uploadedBy: 'Uploaded By',
          totalRecords: 'Total Records',
          recordUpdated: 'Record updated',
          recordSkip: 'record skip',
          recordWithError: 'record with errors',
          processStatus: 'Process Status',

          couponId: 'Coupon Id',
          enterRightQuantity: 'Enter Right Quantity',
          enterQuantity: 'Enter Quantity',
          searchItemNameOrSKU: 'Search Item Name or SKU',
          searchItem: 'Search Item',
          enterNewQuantity: 'Enter new quantity',
          chooseReason: 'Choose Reason',
          value: 'Value',
          minOrder: 'Min. Order',
          submit: 'Submit',
          done: 'Done',
          addCoupon: 'Add Coupon',
          editCoupon: 'Edit Coupon',
          getNearByStores: 'Get near by Stores',
          couponType: 'Coupon Type',
          percentage: 'Percentage',
          maxAllowedDis: 'Max Allowed Discount',
          discountValue: 'Discount Value',
          discountPercentage: 'Discount Percentage',
          selectStartDate: 'Select Start Date',
          selectEndDate: 'Select End Date',
          startDateAndTime: 'Start Date and Time',
          endDateAndTime: 'End Date and Time',
          applicableOn: 'Applicable On',
          categories: 'Categories',
          minimumOrder: 'Minimum Order',
          addBannerImage: 'Add Banner Image',
          addBannerImageForMobile: 'Add Banner Image for Mobile',
          addBannerImageForWeb: 'Add Banner Image for Web',
          category: 'Category',
          products: 'Products',
          cities: 'Cities',
          users: 'Users',
          couponCriteria: 'Coupon Criteria',
          stores: 'Stores',
          couponName: 'Coupon Name',
          couponSuccess: 'Coupon Added Successfully',
          couponError: 'Coupon Not Added',
          updatePromoStatus: 'Update Promo Code Status',
          couponStatusUpdated: 'Coupon Status Updated',
          couponStatusError: 'Coupon Status Is Not Updated',
          isRequired: 'is required.',
          selectValue: 'Select Value',
          max_length_25char: 'Maximum 25 characters allowed.',
          min_length_10dig: 'Minimum 10 digits required.',
          couponErrorDetail: 'Minimum order value should be greater than discount value',
          comboErrorDetail: 'Initial combo selling price should be greater than final combo selling price',

          comboOfferDetails: 'Combo Offer Details',
          comboOfferId: 'Combo Offer Id',
          discount: 'Discount',
          comboName: 'Combo Name',
          comboStartDate: 'Combo Offer Start Date',
          comboEndDate: 'Combo Offer End Date',
          initialSellingPrice: 'Initial Combo Selling Price',
          finalSellingPrice: 'Final Combo Selling Price',
          addComboOfferImageForMobile: 'Add Combo Offer Image for Mobile',
          addComboOfferImageForWeb: 'Add Combo Offer Image for web',
          addProduct: 'Add Product',
          comboOfferAddSuccess: 'Combo Offer Added Successfully',
          comboOfferError: 'Combo Offer Not Added.',
          updateComboStatus: 'Update Combo Offer Status',
          comboStatusUpdated: 'Combo Offer Status Updated',
          comboStatusError: 'Combo Offer Status Is Not Updated',
          paymentMethodError: 'Payment Method must be selected',
          // ! Notification screen

          notificationList: 'Notification List',
          id: 'Id',
          notificationSubject: 'Notification Subject',
          notificationBasedOn: 'Notification Based On',
          language: 'Language',
          dateAndTime: 'Date And Time',
          notificationDetails: 'Notification Details',
          title: 'Title',
          message: 'Message',
          store: 'Store',
          deviceType: 'Device Type',
          all: 'All',
          chooseFile: 'Choose File',
          fileType: 'Choose file with mime: (xlx, xlsx, csv)',
          downloadProductFile: 'Download Product File',
          xlsxFile: '(xlsx File)',
          notificationSuccess: 'Notification Details Added Successfully',
          send: 'Send',
          saveAsDraft: 'Save as draft',
          notificationStatusSuccess: 'Notification status updated Successfully',
          areYouSure: 'Are you sure ?',
          notificationMsg: 'Are you sure you want to send notification to the selected list ?',
          createNew: 'Create New',
          close: 'Close',
          bannerDetails: 'Banner Details',
          bannerId: 'Banner ID',
          bannerType: 'Banner Type',
          applicableTo: 'Applicable To',
          display: 'Display',
          popUp: 'Popup',
          positionOfBanner: 'Position Of Banner',
          editBanner: 'Edit Banner',
          popUpText: 'Number of times user can view the pop up',
          displaySuggestionNote: 'Note : Suggested banner position is',
          bannerSuccess: 'Banner Added Successfully',
          sendDateTime: 'Send on Date / Time',

          // ! Image note
          fileSize: 'Note : The file size should not be greater than 10MB. ',
          imageFileNote: 'Image should be in .jpg or .png format.',
          fileNote: 'File should be in .csv,.xlx format.',
          productRatio: ' While uploading the product image aspect ratio of the image should be 1:1.',
          ratio: ' While uploading the image aspect ratio of the image should be 1:1.',
          ratioWeb: ' While uploading the image aspect ratio of the image should be 1440 X 400.',
          downloadCatalog: 'Download Catalog',
          // Customer Management
          customerDetails: 'Customer Details',
          customer_toggle: 'Are you sure you want to set the customer status as active?',
          customer_toggle_uncheck: 'Are you sure you want to set the customer status as inactive?',
          orderDetails: 'Order Details',
          orders: 'Orders',
          // ! Manage customer
          billingAndShipping: 'Billing and Shipping Details',
          emailId: 'Email ID',
          paymentMethod: 'Payment Method',
          trackingDetails: 'Tracking Details',

          // ! Category
          selectLanguage: 'Select Language',
          addCategory: 'Add Category',

          // ! Cancel Messages
          discardChanges: 'Alert',
          areYouSureText: 'Do you want to discard the changes?',
          specialCharacter: 'Please enter valid input in',
          categoryImages: 'Category Images',
          brandImages: 'Brand Images',
          editUser: 'Edit User',
          userUpdatedSuccess: 'User Details Updated Successfully.',
          userNotUpdated: 'User Not Updated',
          userType: 'User Type',
          //Category screen
          editCategory: 'Edit Category',
          categoryName: 'Category Name',
          selectParentCategory: 'Select Parent Category',
          positionOfCategory: 'Position of Category',
          categoryImage: 'Category Image',
          ageRestrictedCategory: 'Age Restricted Category',
          ageInYears: 'Age in years',
          //Dashboard
          total_sales: 'Total Sales',
          day_sale: 'Day Sale',
          total_customers: 'Total Customers',
          new_customers: 'New Customers',
          total_orders: 'Total Orders',
          delivered_orders: 'Delivered Orders',
          pending_deliveries: 'Pending Deliveries',
          cancelled_orders: 'Cancelled Orders',
          view_details: 'View Details',
          sales_by_products: 'Sales By Products',
          bulk_action: 'Bulk Action',
          sales: 'Sales',
          period_with_parenthsis: '(Period)',
          category_parenthsis: '(Category)',
          history: 'History',
          history_period: 'History (Period)',
          sales_by_stores: 'Sales By Stores',
          coupon_conversion: 'Coupon Conversion',
          sales_by_promotions: 'Sales By Promotions',
          sales_by_customers: 'Sales By Customers',
          three_pl: '3PL',
          sale_of_products_with_discounts: 'Sale of Products with Discounts',
          order_origin: 'Order Origin',
          with_coupon: 'With Coupon',
          with_out_coupon: 'Without Coupon',
          delivery_by_order_value: 'Delivery by Order Value',
          delivery_by_order_number: 'Delivery by Order Number',
          upload_error: 'Upload failed due to the error.',
          sales_by_category: 'Sales By Category',
          point_of_order_origin: 'Point of Order Origin',

          grid_rating: 'Rating ',
          grid_orders_with_discount: 'Orders with Discount',
          grid_orders_without_discount: 'Orders without Discount',

          grid_popular_products: 'Popular Product',
          grid_no_of_customers: 'No of Customers',
          grid_revenue_per_customers: 'Revenue per customers',
          grid_order_with_replacement: 'Order with Replacement',
          grid_order_with_cancellation: 'Order with Cancellation',

          grid_customer_since: 'Customer Since',
          grid_no_of_address: 'No. Of Address',
          grid_coupon_code_used: 'Coupon Code Used',
          grid_order_cancelled: 'Order Cancelled',
          grid_combo_offers: 'Combo Offers',
          grid_coupon_list: 'Coupon List',
          grid_banner_list: 'Banner List',
          grid_total_discount: 'Total Discount',

          grid_net_discount: 'Net Discount',
          labelCurrentPrice: 'Current Price',
          browseImageText: 'Browse to find image',
          forgetPassword: 'Forgot Password',
          file_size_limit: 'Invalid file size limit.',

          grid_records: 'Records',
          grid_updated: 'Updated',
          grid_skipped: 'Skipped',
          grid_errors: 'Errors',
          label_no_file: '(No file selected)',
          addressNotFound: 'Address Not Found',
          listToppings: 'List toppings',
          toppings: 'Toppings',
          addToppings: 'Add toppings',
          toppingInfoText: '(Press Enter to add)',
          toppingIssue: 'Please add toppings',
          noInternet: 'No Internet connection available.',
          reload: 'Reload',
          userRoleAdmin: 'Admin',
          userRoleStoreManager: 'Store Manager',
          dashboard: 'Dashboard',
          pricePerUnitIssue: 'Price per unit should always be less than or equal to Actual Price.',
          maxDiscountWarning: 'Are you sure that you want to give more than 50% discount?',
          orderSource: 'Order Source',
          couponApplied: 'Coupon Applied',
          userEmail: 'User Email',
          payable: 'Payable',
          orderItemsCount: 'Order Items Count',
          jelpReference: 'Jelp Reference',
          storeCity: 'Store City',
          token: 'Token',
          deliveryPartnerLabel: 'Delivery Partner',
          needInvoice: 'Need Invoice',
          WAITING_FOR_DELIVERY_PARTNER_ALLOCATION: 'WAITING_FOR_DELIVERY_PARTNER_ALLOCATION',
          cost: 'Cost',
          qty: 'Qty',
          billingAddress: 'Billing Address',
          shippingAddress: 'Shipping Address',
          noTrackingInformationAvailable: 'No Tracking Information Available.',
          ActiveInCallCenter: 'Active in call center',
          ActiveInApp: 'Active in App',
          ActiveInWeb: 'Active in Web',
          DeliveredOrders: 'Delivered Orders',
          PendingDeliveries: 'Pending Deliveries',
          CancelledOrders: 'Cancelled Orders',
          PendingCancellation: 'Pending Cancellation',

          orderStatusObject: {
            all: 'All',
            OrderCreated: 'Order Created',
            waitingForDeliveryPartnerAllocation: 'Waiting for delivery partner allocation',
            deliveryPartnerAssigned: 'Delivery Partner Assigned',
            onTheWayToTheStore: 'On the way to the store',
            atTheStore: 'At the store',
            orderPicked: 'Order Picked',
            taskWaiting: 'Task Waiting',
            onRouteToDeliveryAddress: 'On route to delivery address',
            atDeliveryAddress: 'At delivery address',
            cancelOrder: 'Cancel Order',
            canceled: 'Canceled',
            deliveryCompleted: 'Delivery completed',
            failed: 'Failed',
          },
          notificationStatusObject: {
            all: 'All',
            draft: 'Draft',
            sent: 'Sent',
            published: 'Published',
            failed: 'Failed',
          },
          deviceTypeArray: {
            ios: 'IOS',
            android: 'Android',
          },
          attributeStatusObject: {
            text: 'Text',
            number: 'Number',
            list: 'List',
          },
          applicableOnObject: {
            all: 'All',
            category: 'Categories',
            product: 'Products',
          },
          purchaseCriteriaObject: {
            firstPurchase: 'First Purchase',
            lastPurchase: 'Last Purchase',
            cartAmount: 'Cart Amount',
          },
          bannerApplicableOnObject: {
            all: 'All',
            category: 'Categories',
            product: 'Products',
            comboOffer: 'Combo offer',
            none: 'None',
          },
          visibleToObject: {
            all: 'All',
            city: 'City',
            store: 'Store',
          },
          downloadReportObject: {
            order: 'Order',
            rating: 'Rating',
            Customers: 'Customers BI',
            Orders: 'Order BI',
            OrderDetails: 'Order Details BI',
            notification: 'Notification',
          },
          cancelReasonObject: {
            itemNotVailable: 'Item not available',
          },
          visibleTo: 'Visible To',
          active: 'Active',
          inActive: 'Inactive',
          timeError: 'Start time must be smaller then End time',
          dateError: 'Start date must be smaller then End date',
          surePopupText: 'Are you sure that you want to perform this action?',
          Confirmation: 'Confirmation',
          invoiceDetails: 'Invoice Details',
          idType: 'Id Type',
          idNumber: 'Id Number',
          downloadFeedback: 'Rating Report',
          storeName: 'Store Name',
          uploadedFile: 'Uploaded File',
          reportFile: 'Report File',
          processFile: 'Process File',
          Net_Sale: 'Net Sale',
          frequency: 'Frequency',
          months: 'Months',
          weeks: 'Weeks',
          sidebar_orders: 'Order',
          sidebar_reports: 'Reports',
          downloadReport: 'Download Report',
          report: 'Report',
          minimumPurchaseAmount: 'Minimum Purchase Amount',
          minFreeShippingAmount: 'Minimum Cart Value for Free Delivery',
          shippingFee: 'Shipping Fee',
          surgeFee: 'Surge Fee',
          surgeShippingFee: 'Surge Shipping Fee',
          surgeStartTime: 'Surge Fee Start Time',
          surgeEndTime: 'Surge Fee End Time',
          loginError: 'Please login again to access the application.',
          SignInRequired: 'Sign in required!',
          serverUnreachable: 'Server Unreachable.',
          NumberOfUsagePerCustomer: 'Number of usage per customer',
          maximumAllowedDiscount: 'Maximum Promocode Discount',
          select_criteria: 'Select Criteria',
          select_frequency: 'Select Frequency',
          select_category: 'Select Category',
          dob: 'DOB',
          userAddressID: 'User Address ID',
          notificationFormDateIssue: 'Please select Date and Time',
          editPolygon: 'Edit Polygon',
          stopEditingPolygon: 'Stop editing polygon',
          noEditPolygonMsg: 'Now you cannot edit the polygon.',
          editPolygonMsg: 'Now you can edit the polygon.',
          platformRequiredError: 'Please select at least one allowed platform',
          platform: {
            iOS: 'IOS',
            Android: 'Android',
            web: 'Web',
            cc: 'Call center',
          },
          allowedPlatform: 'Allowed Platform',
          comboOffer: 'Combo Offer',
          copyLatLngText: 'Copy Latitude and Longitude',
          latLngCopied: 'Latitude and Longitude copied !!',
          copied: 'Copied',
          ageRestricted: 'Restricted Age',
          comboType: 'Combo offer type',
          normal: 'Normal',
          list: 'List',
          nameForList: 'Name for list',
          productsFor: 'Products for ',
          normalProductError: 'Please enter all Product details',
          addComboList: 'Add combo list',
          listProductPriceError: 'All products on the same list must have the same actual price.',
          callCenterOrders: 'Call center orders',
          webOrders: 'Web orders',
          mobileOrders: 'Mobile orders',
          totalOrders: 'Total orders',
          emailSent: 'You will receive your report on your mail Id, Thank You',
          imageUploadErrorCombo: 'The image aspect ratio is not correct. Please use the image ratio between 1.9 to 2.1',
          imageUploadErrorBanner: 'The image aspect ratio is not correct. Please use the image ratio between 3.5 to 4.5',
          imageUploadErrorOneIsToOne: 'The image aspect ratio is not correct. Please use the image ratio between 0.95 to 1.05',
          uploadProductsImages: 'Upload Images',
          primaryImagePosition: 'Position for primary image must be 1.',
          VisibilityPromoCodeList: 'Visibility on promocode list',
          addProductError: 'Please add product SKU',
          selectProductsBySearch: 'select products by search',
          uploadProductsUsingSKU: 'upload products using SKU',
          back: 'Back',
          ProductSKU: 'Product SKU',
          validate: 'Validate',
          addToBanner: 'Add to banner',
          addToCoupon: 'Add to coupon',
          addCommaSeparatedProductsSKU: "Add comma separated products SKU's",
          editSKU: 'Edit SKU',
          SelectProductsText: 'Select How you want to select products',
          uploadTermsAndCondition: 'Upload terms and condition',
          uploadSupport: 'Upload support',
          uploadTermsAndConditionPromotion: 'Upload terms and condition promotion',
          uploadPrivacyPolicy: 'Upload privacy policy',
          termsAndCondition: 'Terms and condition',
          promocodeTermsAndCondition: 'Promocode terms and condition',
          privacyPolicy: 'Privacy policy',
        });
      case 'es':
        return of({
          uploadTermsAndCondition: 'Subir términos y condiciones',
          uploadSupport: 'Subir soporte',
          uploadTermsAndConditionPromotion: 'Subir términos y condiciones promoción',
          uploadPrivacyPolicy: 'Subir política de privacidad',
          termsAndCondition: 'Términos y Condiciones',
          promocodeTermsAndCondition: 'Términos y condiciones del código promocional',
          privacyPolicy: 'Política de privacidad',

          addProductError: 'Por favor agregue el SKU del producto',
          selectProductsBySearch: 'seleccionar productos por búsqueda',
          uploadProductsUsingSKU: 'subir productos usando SKU',
          back: 'Atrás',
          ProductSKU: 'SKU del producto',
          validate: 'Validar',
          addToBanner: 'Añadir al banner',
          addToCoupon: 'Añadir al cupón',
          addCommaSeparatedProductsSKU: 'Agregar SKU de productos separados por comas',
          editSKU: 'Editar SKU',
          SelectProductsText: 'Seleccione Cómo desea seleccionar productos',

          VisibilityPromoCodeList: 'Visibilidad en la lista de códigos promocionales',
          primaryImagePosition: 'La posición de la imagen principal debe ser 1',
          uploadProductsImages: 'Subir imágenes',
          emailSent: 'Recibirá su informe en su ID de correo, gracias',
          dateError: 'La fecha de inicio debe ser menor que la fecha de finalización',
          totalOrders: 'Pedidos totales',
          callCenterOrders: 'Pedidos del centro de llamadas',
          webOrders: 'Pedidos web',
          mobileOrders: 'Pedidos móviles',
          ageRestricted: 'Edad restringida',
          addComboList: 'Agregar lista combinada',
          productsFor: 'Productos para ',
          normalProductError: 'Ingrese todos los detalles del producto',
          comboOffer: 'Oferta combinada',
          platform: {
            iOS: 'IOS',
            Android: 'Androide',
            web: 'Web',
            cc: 'Centro de llamadas',
          },
          comboType: 'Tipo de oferta combinada',
          listProductPriceError: 'Todos los productos de la misma lista deben tener el mismo precio real.',
          normal: 'Normal',
          list: 'Lista',
          platformRequiredError: 'Seleccione al menos una plataforma permitida',
          copyLatLngText: 'Copiar latitud y longitud',
          latLngCopied: 'Latitud y longitud copiadas !!',
          copied: 'Copiada',
          nameForList: 'Nombre para la lista',

          allowedPlatform: 'Plataforma Permitida',
          editPolygon: 'Editar Polígono',
          stopEditingPolygon: 'Dejar de editar polígono',
          noEditPolygonMsg: 'Ahora no puedes editar el polígono.',
          editPolygonMsg: 'Ahora puedes editar el polígono.',
          notificationFormDateIssue: 'Por favor seleccione Fecha y Hora',
          dob: 'Fecha de nacimiento',
          userAddressID: 'ID de dirección de usuario',

          select_category: 'Seleccionar categoría',
          select_frequency: 'Seleccionar frecuencia',
          select_criteria: 'Seleccionar criterios',
          NumberOfUsagePerCustomer: 'Número de uso por cliente',
          maximumAllowedDiscount: 'Descuento máximo del código de promoción',
          loginError: 'Inicie sesión nuevamente para acceder a la aplicación.',
          SignInRequired: '¡Inicio de sesión requerido!',
          serverUnreachable: 'Servidor inalcanzable.',
          weeks: 'Semanas',
          months: 'Meses',
          frequency: 'Frecuencia',
          Net_Sale: 'venta neta',
          imageMandatoryIssue: 'Por favor sube la imagen',

          ActiveInCallCenter: 'Activo en call center',
          ActiveInApp: 'Activa en la aplicación',
          ActiveInWeb: 'Activa en la web',
          DeliveredOrders: 'Pedidos entregados',
          PendingDeliveries: 'Entregas Pendientes',
          CancelledOrders: 'Pedidos cancelados',
          PendingCancellation: 'Cancelación pendiente',

          minimumPurchaseAmount: 'Valor mínimo de compra',
          minFreeShippingAmount: 'Valor mínimo del carrito para entrega gratuita',
          shippingFee: 'Gastos de envío',
          surgeFee: 'Tarifa de sobretensión',
          surgeShippingFee: 'Tarifa de envío de aumento',
          surgeStartTime: 'Hora de inicio de la tarifa de aumento',
          surgeEndTime: 'Hora de finalización de la tarifa de aumento',

          downloadReport: 'Descargar Informe',
          report: 'Informe',
          sidebar_orders: 'Orden',
          sidebar_reports: 'Informes',
          uploadedFile: 'Archivo subido',
          reportFile: 'Archivo de informe',
          processFile: 'Archivo de proceso',
          storeName: 'Nombre de la tienda',
          downloadFeedback: 'Calificación Report',
          idType: 'Tipo de identificación',
          idNumber: 'Número de identificación',
          invoiceDetails: 'Detalles de la factura',
          visibleTo: 'Visible a',
          active: 'Activo',
          inActive: 'Inactivo',
          timeError: 'La hora de inicio debe ser menor que la hora de finalización',
          surePopupText: '¿Estás segura de que quieres realizar esta acción?',
          Confirmation: 'Confirmación',
          orderStatusObject: {
            all: 'Todas',
            OrderCreated: 'Orden creada',
            waitingForDeliveryPartnerAllocation: 'En espera de asignación de domiciliario',
            deliveryPartnerAssigned: 'Domiciliario asignado',
            onTheWayToTheStore: 'De camino a la tienda',
            atTheStore: 'En la tienda',
            orderPicked: 'Orden recogida',
            taskWaiting: 'Tarea en espera',
            onRouteToDeliveryAddress: 'En camino a la dirección de entrega',
            atDeliveryAddress: 'En la dirección de entrega',
            cancelOrder: 'Orden cancelada',
            canceled: 'Cancelar',
            deliveryCompleted: 'Orden entregada',
            failed: 'Fallida',
          },
          notificationStatusObject: {
            all: 'Todas',
            draft: 'Borrador',
            sent: 'Enviado',
            published: 'Publicada',
            failed: 'Fallida',
          },
          deviceTypeArray: {
            ios: 'IOS',
            android: 'Androide',
          },
          attributeStatusObject: {
            text: 'Texto',
            number: 'Número',
            list: 'Lista',
          },
          applicableOnObject: {
            all: 'Todas',
            category: 'Categorías',
            product: 'Productos',
          },
          purchaseCriteriaObject: {
            firstPurchase: 'Primera Compra',
            lastPurchase: 'Última Compra',
            cartAmount: 'Importe del carrito',
          },
          bannerApplicableOnObject: {
            all: 'Todas',
            category: 'Categorías',
            product: 'Productos',
            comboOffer: 'Oferta combinada',
            none: 'Ninguno',
          },
          visibleToObject: {
            all: 'Todas',
            city: 'Ciudad',
            store: 'Tienda',
          },
          downloadReportObject: {
            order: 'Orden',
            rating: 'Clasificación',
            Customers: 'Clientes BI',
            Orders: 'Pedido BI',
            OrderDetails: 'Detalles del pedido BI',
            notification: 'Notificación',
          },
          cancelReasonObject: {
            itemNotVailable: 'Artículo no disponible',
          },
          noTrackingInformationAvailable: 'No hay información de seguimiento disponible.',
          shippingAddress: 'Dirección de envío',
          billingAddress: 'Dirección de facturación',
          cost: 'Costo',
          qty: 'Cantidad',
          orderSource: 'Canal',
          couponApplied: 'BONO',
          userEmail: 'Correo electrónico',
          payable: 'Pago total',
          orderItemsCount: 'Artículos totales',
          jelpReference: 'Jelp Referencia',
          storeCity: 'Ciudad',
          token: 'Token',
          deliveryPartnerLabel: 'Repartidor',
          needInvoice: 'Factura Electrónica',
          maxDiscountWarning: '¿Estás seguro de que quieres dar más del 50% de descuento?',
          dashboard: 'Tablero',
          userRoleAdmin: 'Administrador',
          userRoleStoreManager: 'Gerente de la tienda',
          reload: 'Recargar',
          pricePerUnitIssue: 'El precio por unidad siempre debe ser menor o igual al precio real.',
          noInternet: 'No hay conexión a Internet disponible.',
          toppings: 'Coberturas',
          listToppings: 'Lista de ingredientes',
          addToppings: 'Agregar ingredientes',
          Username: 'Correo electrónico',
          toppingInfoText: '(Presione Entrar para agregar)',
          toppingIssue: 'Por favor agregue ingredientes',
          Password: 'Contraseña',
          createPassword: 'Crear contraseña',
          validContactNumber: 'Número de contacto válido',
          validEmail: 'Correo electrónico válido',
          textPasswordPattern: '(Mínimo ocho caracteres, al menos una letra, un número y un carácter especial: @, $, !, %, *, #, ? o &)',
          enterPassword: 'Introducir la contraseña',
          'El nombre de usuario debe ser mayor que x': 'El nombre de usuario debe ser mayor que {{minval}}',
          forgot_password: '¿Se te olvidó tu contraseña?',
          emailOrUname: 'Correo electrónico o nombre de usuario',
          emailOrUnamePlaceholder: 'Ingrese correo electrónico o nombre de usuario',
          email_required: 'Correo electronico (requerido)',
          passwordRequired: 'Se requiere contraseña',
          enterEmail: 'Introduce tu correo electrónico',
          otp: 'Contraseña de un solo uso',
          rememberMe: 'Acuérdate de mí',
          login: 'Acceso',
          otpSent: 'Le hemos enviado una contraseña de un solo uso a su correo electrónico',
          enterOTP: 'Ingrese Contraseña de un solo uso',
          sendOTP: 'Enviar Contraseña de un solo uso',
          resendOTP: 'Reenviar Contraseña de un solo uso',
          resetPassword: 'Restablecer la contraseña',
          newPassword: 'Introduzca nueva contraseña',
          newPasswordChange: 'Nueva contraseña',
          oldPassword: 'Contraseña anterior',
          oldPasswordPlaceholder: 'Ingrese la contraseña anterior',
          confirmPasswordPlaceholder: 'Ingrese confirmar contraseña',
          confirmPassword: 'Confirmar contraseña',
          changePassword: 'Cambiar la contraseña',
          reEnterPassword: 'Re-ingrese nueva contraseña',
          notMatchedPassword: 'Contraseña y Confirmar contraseña no coinciden.',
          requiredPassword: 'Se requiere contraseña',
          passwordResetSuccess: 'Restablecimiento de contraseña',
          verifyOTP: 'Verificar Contraseña de un solo uso',

          grid_title: 'TÍTULO',
          ID_title: 'ID de usuario',
          grid_trial_start_date: 'FECHA DE INICIO',
          grid_trail_end_date: 'FECHA FINAL',
          grid_patients: 'PACIENTES',
          lbl_no_records_found: 'No se encontraron registros',
          error: 'Error',
          historyData: 'Datos históricos',
          currentData: 'Actual',

          sidebar_lbl_customers: 'Clientes',
          sidebar_lbl_merchants: 'Comerciantes',
          sidebar_lbl_auctions: 'Subastas',
          sidebar_lbl_support: 'Soporte',
          sidebar_lbl_vaquitas: 'vaquitas',
          sidebar_lbl_notifications: 'Notificaciones',
          sidebar_lbl_admin_users: 'Usuarios administradores',
          sidebar_lbl_settings: 'Ajustes',

          sidebar_lbl_dashboard: 'Tablero',
          sidebar_lbl_product: 'Producto',
          sidebar_lbl_product_management: 'Ajustes',
          sidebar_lbl_product_bulkUpload: 'Ajustes',
          sidebar_lbl_productMaster: 'Ajustes',
          sidebar_lbl_orderManagement: 'Gestión de pedidos',
          sidebar_lbl_inventoryManagement: 'La gestión del inventario',
          sidebar_lbl_admin: 'Administrador',
          sidebar_lbl_storeManagement: 'Administración de la tienda',
          sidebar_lbl_marketing: 'Marketing',
          sidebar_lbl_promocode: 'Código promocional',
          sidebar_lbl_comboOffer: 'Oferta combinada',
          sidebar_lbl_couponCode: 'Código promocional',
          sidebar_lbl_notification: 'Notificación',
          sidebar_lbl_banner: 'Banner',
          sidebar_lbl_customerManagement: 'Gestión de clientes',
          sidebar_lbl_logout: 'Cerrar sesión',

          sidebarLabelProductManagement: 'Gestión de productos',
          sidebarLabelUploadFiles: 'Subir productos',
          sidebarLabelBrand: 'Marca',
          sidebarLabelCategory: 'Categoría',
          sidebarLabelInventory: 'Inventario',
          sidebarLabelAttributeMaster: 'Maestro de atributos',
          downloadInventory: 'Descargar inventario',

          msg_logout: '¿Está seguro de que desea realizar esta acción?',
          lbl_confirm_idle_session: `La sesión ha estado inactiva por encima de su límite de tiempo,
      Se desconectará en 2 minutos. Presione Ok ahora para continuar la sesión.`,

          multiselect_placeholder: 'Menú desplegable de selección múltiple',
          search_lbl: 'Búsqueda',
          searchByStore_lbl: 'Buscar por tienda',
          searchBySKU_lbl: 'Buscar por Producto',
          calendar_start: 'FECHA DE INICIO',
          calendar_end: 'FECHA FINAL',
          documents_lbl: 'Documentos',

          storeSelector: 'Selector de tiendas',
          uploadInventory: 'Subir inventario',
          inventoryList: 'Lista de inventario',
          uploadHistory: 'Subir historial',
          showing: 'Mostrando',

          grid_name: 'Nombre',
          grid_stock: 'Existencias',
          grid_contact: 'Número de contacto',
          grid_email: 'Correo electrónico',
          grid_usertype: 'Tipo de usuario',
          grid_store: 'Tienda',
          grid_status: 'Estado',
          grid_visibile_cart: 'Visibilidad en el carrito',
          grid_action: 'Acción',
          grid_units: 'Unidades',
          grid_revenue: 'Ganancia',
          grid_orders: 'Pedidos',
          grid_slno: '#',
          store_status: 'ESTADO',

          unitOfPricePerUnit: 'Unidad de precio por unidad',
          pricePerUnit: 'Precio por unidad',
          numberOfUnit: 'Número de unidad',
          firstname: 'Primer nombre',
          lastname: 'Apellido',
          email: 'Correo electrónico',
          contactnumber: 'Número de contacto',
          usertype: 'Tipo de usuario',
          assignStorename: 'Asignar tienda para gerente de tienda',
          sku: 'sku',
          thumbnail: 'Miniatura',
          sellingPrice: 'Precio de venta',
          price: 'Precio',
          user_dropdown: 'Seleccione',
          user_toggle: '¿Está seguro de que desea establecer el estado del usuario como activo?',
          user_toggle_uncheck: '¿Está seguro de que desea establecer el estado del usuario como inactivo?',
          activate: 'Activar',
          deactivate: 'Desactivar',
          contactNumber: 'Número de contacto',
          cancel: 'Cancelar',
          save: 'Ahorrar',
          ok: 'OK',
          addNewAdmin: 'Agregar nuevo administrador',
          success: 'éxito',
          userAddedSuccessfully: 'Usuario agregado con éxito',
          categoryUpdateSuccessMsg: 'Categoría actualizada con éxito',
          addNewUser: 'Agregar nuevo usuario',
          addNewStore: 'Agregar nueva tienda',

          //Product General
          productManagement: 'Gestión de productos',
          SaveNext: 'Guardar & Siguiente',
          publish: 'Publicar',

          shortDescription: 'Breve descripción',
          productName: 'Nombre del producto',
          description: 'Descripción',
          media: 'Medios de comunicación',
          Category: 'Categoría',
          productTags: 'Etiquetas de productos',
          brand: 'Marca',
          brands: 'Marcas',
          relatedProducts: 'Productos relacionados',
          relatedProduct: 'Producto relacionado, artículo relacionado',
          general: 'General',
          attributes: 'Atributos',
          seo: 'SEO',
          relatedProductsDescription:
            'Los productos relacionados se muestran a los clientes además del artículo que el cliente está mirando.',
          publishSuccess: 'Producto publicado con éxito',
          generalInfo:
            'Los siguientes campos son obligatorios \n 1.SKU \n 2.Nombre del producto \n 3.Descripción breve \n 4.Categoría \n 5.Precio \n 6.Precio por unidad \n 7.Unidad de precio por unidad \n 8 .Marca',
          categoryPositionSuggestMsg: 'La posición sugerida es',
          categoryPositionSuggestWarning1: '¿Está seguro de que desea establecer la posición en',
          categoryPositionSuggestWarning2: '? el producto en',
          categoryPositionSuggestWarning3: ' cambiará a ',

          selectCountry: 'Seleccionar país',
          action: 'ACCIÓN',
          name: 'Nombre',
          storeNumber: 'Número de tienda',
          storeId: 'CR TIENDA',
          state: 'Expresar',
          city: 'Ciudad',
          status: 'Estado',
          store_toggle: '¿Está seguro de que desea configurar el estado de la tienda como en línea?',
          store_toggle_uncheck: '¿Está seguro de que desea establecer el estado de la tienda como fuera de línea?',
          product_toggle:
            '¿Está seguro de que desea configurar este producto como disponible? Al establecer esto como disponible, los clientes podrán ver este producto.',
          product_toggle_uncheck:
            '¿Está seguro de que desea configurar este producto como no disponible? Al configurar esto como no disponible, los clientes no podrán ver este producto.',
          promo_code_toggle_uncheck:
            '¿Estás seguro de que deseas establecer este código de promoción como no disponible? Al configurar esto como no disponible, los clientes no podrán ver este código de promoción en el carrito.',
          promo_code_toggle:
            '¿Estás seguro de que deseas configurar este código de promoción como disponible? Al configurarlo como disponible, los clientes podrán ver este código de promoción en el carrito.',
          promo_code_toggle_uncheck_status: '¿Estás seguro de que deseas establecer este código de promoción como Inactivo?',
          promo_code_toggle_status: '¿Estás seguro de que deseas activar este código de promoción?',
          available: 'Disponible',
          notAvailable: 'No disponible',
          online: 'En línea',
          offline: 'Desconectado',
          geofencing: 'Geocercas',

          storeDetails: 'Detalles de la tienda',
          cash: 'Efectivo',
          pinpad: 'Datafono',
          nameOfStore: 'Nombre de la tienda',
          contactOfStore: 'Número de contacto de la tienda',
          crStore: 'CR Tienda',
          emailOfStore: 'ID de correo electrónico de la tienda',
          deliveryPartner: 'Socio de entrega preferido',
          location: 'Localización',
          address: 'Dirección',
          country: 'País',
          zip: 'Código postal',
          latitude: 'Latitud',
          longitude: 'Longitud',
          setStoreTime: 'Establecer tiempo de tienda',
          addStoreManager: 'Añadir administrador de tienda',
          storeManagerName: 'Nombre del administrador de la tienda',
          startTime: 'Hora de inicio',
          endTime: 'Hora de finalización',
          addDeliveryPartner: 'Agregar socio de entrega',
          geofenceRequired: 'dibuje un polígono para agregar una geocerca',
          selectDeliveryPartner: 'Seleccionar socio de entrega',
          preference: 'Preferencia',
          storeAddedSuccessfully: 'Tienda agregada con éxito',
          minimumFreeShippingError: 'El valor mínimo del carrito no puede ser inferior al monto mínimo del carrito para la entrega',

          edit: 'Editar',
          attributesMaster: 'Maestro de atributos',

          valueInDollars: 'Valor en $',
          itemCount: 'Artículos totales',
          orderedOn: 'Fecha',
          orderList: 'Lista de ordenes',
          attributesSuccess: 'Atributos agregados con éxito',
          attributesError: 'Por favor agregue atributos',
          addAttributes: 'Agregar atributos',
          editAttributes: 'Editar atributos',
          attributesId: 'Identificación de atributos',
          type: 'Tipo',
          attributesName: 'Nombre de atributos',
          listValues: 'Valores de lista',
          attributesValue: 'Atributos Valores',
          attributesType: 'Atributos Escribe',

          reason: 'Razón',
          cancelOrder: 'Cancelar orden',
          cancelItem: 'Cancelar artículo',
          customerName: 'Nombre del cliente',
          custName: 'Nombre',
          orderStatus: 'Estado',
          orderItemCount: 'Recuento de artículos de pedido',
          orderDate: 'Fecha de orden',
          paymentAmount: 'Monto del pago',
          assignStore: 'Asignar tienda',
          assignDeliveryPartner: 'Asignar socio de entrega',
          transactionId: 'ID de transacción',
          totalCost: 'Coste total',
          cancelMessage: '¿Realmente desea cancelar este pedido?',
          cancelMessageItem: '¿Realmente quieres cancelar este artículo?',
          quantity: 'Cantidad',
          updateQty: 'Cantidad de actualización',
          replaceItem: 'Reemplazar artículo',
          unitCostOf: 'Costo unitario de',
          totalCostOf: 'Costo total de',
          finalBillValue: 'valor final de la factura',
          changeFromBillValue: 'Cambio del valor de la factura original',
          subtotal: 'Total parcial',
          promoCodeDiscount: 'Descuento de código de promoción',
          totalAmount: 'Sub total',
          cancelOrderSuccess: 'Pedido cancelado con éxito.',
          updateOrderSuccess: 'Pedido actualizado con éxito.',
          trackingInformation: 'Información de seguimiento',
          finalizeAllItems: 'Finalizar todos los elementos',
          replace: 'Reemplazar',
          readyForDelivery: 'Listo para la entrega',

          searchEngineOptimization: 'Optimización de motores de búsqueda',
          metaTitle: 'metatítulo',
          metaTagKeywords: 'Palabras clave de etiquetas meta',
          separatedByComma: '(Separado por coma)',
          metaDescription: 'Metadescripción',
          urlKey: 'Clave de URL',
          seoSuccessfully: 'Atributos de SEO agregados con éxito',
          seoInfo:
            'Los siguientes campos son obligatorios \n 1.Meta título \n 2. Palabras clave de metaetiqueta \n 3. Meta descripción \n 4. Clave de URL.',
          productInformation: 'Información del Producto',
          skuTitle: 'SKU',
          stockKeepingUnit: 'Unidad de mantenimiento de stock',
          selectCategory: 'Selecciona una categoría',
          selectFrequency: 'Seleccionar frecuencia',
          from: 'Desde',
          to: 'A',
          To: 'A',
          of: 'de',
          entries: 'entradas',
          startDate: 'Fecha de inicio',
          endDate: 'Fecha final',
          setDateTime: 'Establecer fecha y hora',
          skuDescription: 'SKU (Unidad de mantenimiento de stock)',
          productAddedSuccess: 'Producto agregado con éxito',
          deleteSuccess: 'Eliminar éxito del producto',
          position: 'Posición',
          productImages: 'Imágenes del producto',
          addRelatedProduct: 'Agregar producto relacionado',
          relatedProductSuccess: 'Productos relacionados agregados con éxito',
          imageSizeNote: 'Nota: el tamaño de la imagen debe ser según la relación de aspecto ancho * alto',
          image: 'IMAGEN',
          imagePrimaryIssue: 'Solo se puede cargar una imagen principal',
          priceIssue: 'El precio de venta debe ser menor que el precio real',

          // ! Brand Screen
          brandName: 'Nombre de la marca',
          nestUnderCategory: 'Anidar debajo de la categoría',
          addBrand: 'Agregar marca',
          editBrand: 'Editar marca',
          brandErrorDetail: 'Por favor llene todos los campos requeridos',
          brandSuccessDetail: 'Marca añadida con éxito',
          brandUpdatedDetail: 'Marca actualizada correctamente',
          brandAvailable: 'Disponible',
          brandNotAvailable: 'No disponible',
          serverError: 'Error del Servidor',
          brandImage: 'Imagen de marca',
          brandAvailableMessage:
            '¿Está seguro de que desea establecer la marca como disponible? Al configurarlo como disponible, los clientes podrán ver la marca.',
          brandNotAvailableMessage:
            '¿Está seguro de que desea establecer la marca como no disponible? Al configurar esto como no disponible, los clientes no podrán ver esta marca.',

          uploadProducts: 'Subir productos',
          fileUploadSuccess: 'El archivo ha subido correctamente',
          fileNotSelected: 'Archivo no seleccionado',
          upload: 'Subir',
          choose: 'Escoger',

          attributeErrorDetail: 'Por favor llene todos los campos requeridos',
          attributeSuccessDetail: 'Atributo agregado con éxito',
          attributeScreenMsg: 'Por favor agregue atributos',
          relatedProductScreenMsg: 'Por favor agregue Producto',

          date: 'fecha',
          fileUpload: 'Subir archivo',
          uploadedBy: 'Subido por',
          totalRecords: 'Registros totales',
          recordUpdated: 'Registro actualizado',
          recordSkip: 'salto de registro',
          recordWithError: 'grabar con errores',
          processStatus: 'Estado del proceso',

          couponId: 'Identificación del promoción',
          enterRightQuantity: 'Ingrese la cantidad correcta',
          enterQuantity: 'Introducir cantidad',
          searchItemNameOrSKU: 'Buscar nombre de artículo o SKU',
          searchItem: 'Buscar artículo',
          enterNewQuantity: 'Ingrese nueva cantidad',
          chooseReason: 'Elija la razón',
          value: 'Valor',
          minOrder: 'mín. Ordenar',
          submit: 'Enviar',
          done: 'Hecho',
          addCoupon: 'Agregar promoción',
          editCoupon: 'Editar promoción',
          getNearByStores: 'Acércate a Tiendas',
          couponType: 'Tipo de promoción',
          percentage: 'Porcentaje',
          maxAllowedDis: 'Descuento máximo permitido',
          discountValue: 'Valor de descuento',
          discountPercentage: 'Porcentaje de descuento',
          selectStartDate: 'Seleccionar fecha de inicio',
          selectEndDate: 'Seleccionar fecha de finalización',
          startDateAndTime: 'Fecha y hora de inicio',
          endDateAndTime: 'Fecha y hora de finalización',
          applicableOn: 'Aplicable en',
          applicableTo: 'Applicable a',
          categories: 'Categorías',
          minimumOrder: 'Pedido mínimo',
          addBannerImage: 'Agregar imagen de pancarta',
          addBannerImageForMobile: 'Agregar imagen de banner para dispositivos móviles',
          addBannerImageForWeb: 'Agregar imagen de banner para web',
          category: 'Categoría',
          products: 'productos',
          cities: 'Ciudades',
          users: 'Usuarios',
          couponCriteria: 'Criterios de promoción',
          stores: 'Historias',
          couponName: 'Nombre del promoción',
          couponSuccess: 'promoción agregado con éxito',
          couponError: 'promoción no agregado',
          updatePromoStatus: 'Actualizar el estado del código de promoción',
          couponStatusUpdated: 'Estado del promoción actualizado',
          couponStatusError: 'El estado del promoción no está actualizado',
          isRequired: 'se requiere.',
          selectValue: 'Selecciona valor',
          max_length_25char: 'Máximo de 25 caracteres permitidos.',
          min_length_10dig: 'Se requiere un mínimo de 10 dígitos.',
          couponErrorDetail: 'El valor mínimo del pedido debe ser mayor que el valor del descuento',
          comboErrorDetail: 'El precio de venta combinado inicial debe ser mayor que el precio de venta combinado final',

          comboOfferDetails: 'Detalles de la oferta combinada',
          comboOfferId: 'ID de oferta combinada',
          discount: 'Descuento',
          comboName: 'Nombre combinado',
          comboStartDate: 'Fecha de inicio de la oferta combinada',
          comboEndDate: 'Fecha de finalización de la oferta combinada',
          initialSellingPrice: 'Precio de venta combinado inicial',
          finalSellingPrice: 'Precio de venta combinado final',
          addComboOfferImageForMobile: 'Agregar imagen de oferta combinada para dispositivos móviles',
          addComboOfferImageForWeb: 'Agregar imagen de oferta combinada para web',
          addProduct: 'Añadir Producto',
          comboOfferAddSuccess: 'Oferta combinada añadida con éxito',
          comboOfferError: 'Oferta combinada no agregada.',
          updateComboStatus: 'Actualizar estado de oferta combinada',
          comboStatusUpdated: 'Estado de la oferta combinada actualizado',
          comboStatusError: 'El estado de la oferta combinada no está actualizado',
          paymentMethodError: 'Se debe seleccionar el método de pago',
          // ! Notification screen

          notificationList: 'Lista de notificaciones',
          id: 'Identificador de pedido',
          notificationSubject: 'Asunto de la notificación',
          notificationBasedOn: 'Notificación basada en',
          language: 'Idioma',
          dateAndTime: 'Fecha y hora',
          notificationDetails: 'Detalles de notificación',
          title: 'Título',
          message: 'Mensaje',
          store: 'Tienda',
          deviceType: 'Tipo de dispositivo',
          all: 'Todos',
          chooseFile: 'Elija el archivo',
          fileType: 'Elija archivo con mime: (xlx, xlsx, csv)',
          downloadProductFile: 'Descargar ficha de producto',
          xlsxFile: '(Archivo xlsx)',
          notificationSuccess: 'Detalles de notificación agregados con éxito',
          send: 'Enviar',
          saveAsDraft: 'Guardar como borrador',
          notificationStatusSuccess: 'Estado de notificación actualizado con éxito',
          areYouSure: 'Estas seguro ?',
          notificationMsg: '¿Está seguro de que desea enviar una notificación a la lista seleccionada?',
          createNew: 'Crear nuevo',
          close: 'Cerca',
          bannerDetails: 'Detalles de la banner',
          bannerId: 'ID de banner',
          bannerType: 'Tipo de banner',
          display: 'Banner home',
          popUp: 'Popup',
          positionOfBanner: 'Posición de la banner',
          editBanner: 'Editar banner',
          popUpText: 'Número de veces que el usuario puede ver la ventana emergente',
          displaySuggestionNote: 'Nota: la posición sugerida del banner es',
          bannerSuccess: 'Banner agregado con éxito',
          sendDateTime: 'Enviar el Fecha / Hora',

          // ! Image note
          fileSize: 'Nota: el tamaño del archivo no debe ser superior a 10 MB. ',
          imageFileNote: 'La imagen debe estar en formato .jpg o .png.',
          fileNote: 'El archivo debe estar en formato .csv, .xlx.',
          productRatio: ' Al cargar la imagen del producto, la relación de aspecto de la imagen debe ser 1:1.',
          ratio: ' Al cargar la imagen del, la relación de aspecto de la imagen debe ser 1:1.',
          ratioWeb: ' Al cargar la imagen del, la relación de aspecto de la imagen debe ser 1440 X 400.',
          downloadCatalog: 'Descargar Catálogo',
          // Customer Management
          customerDetails: 'Detalles del cliente',
          customer_toggle: '¿Está seguro de que desea establecer el estado del cliente como activo?',
          customer_toggle_uncheck: '¿Está seguro de que desea establecer el estado del cliente como inactivo?',
          orderDetails: 'Detalles del pedido',
          orders: 'Pedidos',
          // ! Manage customer
          billingAndShipping: 'Detalles de facturación y envío',
          emailId: 'Identificación de correo',
          paymentMethod: 'Método de pago',
          trackingDetails: 'Detalles de seguimiento',

          // ! Category
          selectLanguage: 'Seleccione el idioma',
          addCategory: 'añadir categoría',

          // ! Cancel Messages
          discardChanges: 'Alerta',
          areYouSureText: '¿Desea descartar los cambios?',
          specialCharacter: 'Por favor ingrese una entrada válida en',
          categoryImages: 'Imágenes de la categoría',
          brandImages: 'Imágenes de marca',
          editUser: 'Editar usuario',
          userUpdatedSuccess: 'Detalles del usuario actualizados con éxito.',
          userNotUpdated: 'Usuario no actualizado',
          userType: 'Tipo de usuario',
          //Category screen
          editCategory: 'Editar categoria',
          categoryName: 'nombre de la categoría',
          selectParentCategory: 'Seleccionar categoría principal',
          positionOfCategory: 'Posición de Categoría',
          categoryImage: 'Imagen de categoría',
          ageRestrictedCategory: 'Categoría restringida por edad',
          ageInYears: 'Edad en años',
          //Dashboard
          total_sales: 'Ventas totales',
          day_sale: 'Venta del día',
          total_customers: 'Clientes totales',
          new_customers: 'Nuevos clientes',
          total_orders: 'Pedidos totales',
          delivered_orders: 'Pedidos entregados',
          pending_deliveries: 'Entregas pendientes',
          cancelled_orders: 'Pedidos cancelados',
          view_details: 'Ver detalles',
          sales_by_products: 'Ventas por productos',
          bulk_action: 'Acción masiva',
          sales: 'Ventas',
          period_with_parenthsis: '(Período)',
          category_parenthsis: '(Categoría)',
          history: 'Historia',
          history_period: 'Historia (período)',
          sales_by_stores: 'Ventas por tiendas',
          coupon_conversion: 'Conversión de promoción',
          sales_by_promotions: 'Ventas por promociones',
          sales_by_customers: 'Ventas por clientes',
          three_pl: '3PL',
          sale_of_products_with_discounts: 'Venta de productos con descuentos',
          order_origin: 'Origen del',
          with_coupon: 'con promoción',
          with_out_coupon: 'Sin promoción',
          delivery_by_order_value: 'Entrega por valor de pedido',
          delivery_by_order_number: 'Entrega por número de pedido',
          upload_error: 'La carga falló debido al error.',
          sales_by_category: 'Ventas por categoría',
          point_of_order_origin: 'Origen del punto de pedido',

          grid_rating: 'Clasificación ',
          grid_orders_with_discount: 'Pedidos con Descuento',
          grid_orders_without_discount: 'Pedidos sin descuento',

          grid_popular_products: 'Producto Popular',
          grid_no_of_customers: 'Nº de clientes',
          grid_revenue_per_customers: 'Ingresos por clientes',
          grid_order_with_replacement: 'Orden con Reemplazo',
          grid_order_with_cancellation: 'Pedido con Cancelación',

          grid_customer_since: 'Cliente desde',
          grid_no_of_address: 'Nº de dirección',
          grid_coupon_code_used: 'Código de promoción utilizado',
          grid_order_cancelled: 'Orden cancelada',
          grid_combo_offers: 'Ofertas combinadas',
          grid_coupon_list: 'Lista de promoción',
          grid_banner_list: 'Lista de banners',
          grid_total_discount: 'Descuento total',

          grid_net_discount: 'Descuento neto',
          labelCurrentPrice: 'Precio actual',
          browseImageText: 'Examinar para encontrar la imagen',
          forgetPassword: 'Contraseña olvidada',
          file_size_limit: 'Límite de tamaño de archivo no válido.',

          grid_records: 'Registros',
          grid_updated: 'Actualizada',
          grid_skipped: 'Omitida',
          grid_errors: 'errores',
          label_no_file: '(Ningún archivo seleccionado)',
          addressNotFound: 'Dirección no encontrada',
          evaluatedFile: 'Evaluated File',
          breadcrumbs: {
            Settings: 'Ajustes',
            Terms_and_conditions: '> Términos y Condiciones',
            Upload_products_management: ' > Upload Products Management',
            Sales_history_by_period: ' > Historial de ventas por periodo',
            Sales_by_category: ' > Ventas por categoría',
            Sales_by_products: ' > Ventas por productos',
            Sales_by_stores: ' > Ventas por tiendas',
            Sales_by_customers: ' > Ventas por clientes',
            Sales_by_promotions: ' > Ventas por promociones',
            Dashboard: 'Tablero',
            Products: 'Productos',
            Product_management: ' > Gestión de productos',
            Edit_product: ' > Editar producto',
            Add_product: ' > Agregar producto',
            Upload_products: ' > Subir productos',
            Brand: ' > Marca',
            Add_brand: ' > Agregar marca',
            Edit_brand: ' > Editar marca',
            Attributes: ' > Atributos',
            Add_attributes: ' > Agregar atributos',
            Edit_attributes: ' > Editar atributos',
            Category: ' > Categoría',
            Add_category: ' > añadir categoría',
            Edit_category: ' > Editar categoria',
            Inventory_management: ' > La gestión del inventario',
            Order: 'Orden',
            Order_reports: '> Descargar Informes',
            download: 'Descargar',
            Order_list: '> Lista de ordenes',
            Order_details: ' > Detalles del pedido',
            Admin: 'Administrador',
            Add_new_user: ' > Agregar nuevo usuario',
            Edit_user: ' > Editar usuario',
            Store_management: 'Administración de la tienda',
            Add_store: ' > Agregar tienda',
            Edit_store: ' > Editar tienda',
            Marketing: 'Marketing > ',
            Banner_list: 'Banner',
            Add_banner: ' > Agregar banner',
            Edit_banner: ' > Editar banner',
            Coupon_code: 'Código promocional',
            Add_coupon: ' > Agregar promoción',
            Edit_coupon: ' > Editar promoción',
            Combo_offer: 'Oferta combinada',
            Add_combo_offer: ' > Agregar oferta combinada',
            Edit_combo_offer: ' > Editar oferta combinada',
            Notification: 'Notificación',
            Add_notification: ' > Agregar notificación',
            Notification_details: ' > Detalles de notificación',
            Customer_management: 'Gestión de clientes',
            Details: ' > Detalles del cliente',
          },
          deepLink: 'Deep Link',
          imageUploadErrorCombo: 'La relación de aspecto de la imagen no es correcta. Utilice la relación de imagen entre 1,9 y 2,1',
          imageUploadErrorBanner: 'La relación de aspecto de la imagen no es correcta. Utilice la relación de imagen entre 3,5 y 4,5',
          imageUploadErrorOneIsToOne: 'La relación de aspecto de la imagen no es correcta. Utilice la relación de imagen entre 0,95 y 1,05',
        });
    }
  }
}
