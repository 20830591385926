import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CommonBindingDataService } from '../../services/common-binding-data.service';
import * as utils from '../../utility-functions/utils';
import { GridService } from '../mi-grid/grid.service';
@Component({
  selector: 'app-sku-grid',
  templateUrl: './sku-grid.component.html',
  styleUrls: ['./sku-grid.component.scss'],
})
export class SkuGridComponent implements OnInit {
  @Output() skuBackButtonClick = new EventEmitter<any>();
  @Output() skuAddProductButtonClick = new EventEmitter<any>();
  @Input() addBtnLabel = 'addToBanner';
  SKUForm: FormGroup;
  editProductSKUForm: FormGroup;
  selectedIndex: number;
  editSkuPopup = false;
  statusColArray = ['validSku'];
  validateSKUResponseArray = [];
  columnDefs = [
    {
      field: 'sku',
      header: this.commonBindingDataService.getLabel('sku', true),
      sort: false,
      class: 'eclipse-tooltip',
    },
    {
      field: 'name',
      header: this.commonBindingDataService.getLabel('product name', true),
      sort: false,
      class: 'eclipse',
    },
    {
      field: 'validSku',
      header: this.commonBindingDataService.getLabel('SKU validity', true),
      sort: false,
      class: '',
    },
  ];
  constructor(
    private commonBindingDataService: CommonBindingDataService,
    private formBuilder: FormBuilder,
    private gridService: GridService
  ) {
    this.SKUForm = this.formBuilder.group({
      productSKU: ['', Validators.required],
    });

    this.editProductSKUForm = this.formBuilder.group({
      editProductSKU: ['', Validators.required],
    });
  }

  ngOnInit(): void {}

  onSKUBackClick() {
    this.skuBackButtonClick.emit();
  }

  onEditSKUClick(rowData) {
    this.selectedIndex = this.validateSKUResponseArray.findIndex((ele) => ele.sku === rowData.sku);
    this.editProductSKUForm.controls['editProductSKU'].setValue(rowData.sku);
    this.editSkuPopup = true;
  }

  onDeleteSKUClick(rowData) {
    this.selectedIndex = this.validateSKUResponseArray.findIndex((ele) => ele.sku === rowData.sku);
    this.validateSKUResponseArray.splice(this.selectedIndex, 1);
    const arr = Array.prototype.map.call(this.validateSKUResponseArray, (item) => item.sku).join(', ');
    this.SKUForm.controls['productSKU'].setValue(arr);
  }

  hideDialog() {
    this.editSkuPopup = false;
  }

  saveSKU() {
    this.validateSKUResponseArray[this.selectedIndex].sku = this.editProductSKUForm.controls['editProductSKU'].value;
    const arr = Array.prototype.map.call(this.validateSKUResponseArray, (item) => item.sku).join(', ');
    this.SKUForm.controls['productSKU'].setValue(arr);

    this.editSkuPopup = false;
  }

  onValidButtonClick() {
    const url = `/api/v1/${utils.getCountryId()}/products/validate-product-sku/?languageCode=${utils.getLanguage()}`;
    const data = { skuList: this.SKUForm.controls['productSKU'].value.split(',') };
    data.skuList = data.skuList.filter((n) => n); //remove blank items
    data.skuList.forEach((element, index) => {
      data.skuList[index] = element.trim();
    });

    this.gridService.getSkuGridData('get-grid-data', url, data).subscribe((res) => {
      this.validateSKUResponseArray = res;
    });
  }

  isSkuListValid() {
    if (this.validateSKUResponseArray.length) {
      let flag = false;
      this.validateSKUResponseArray.forEach((element) => {
        if (element.validSku === false) {
          flag = true;
        }
      });
      return flag;
    } else {
      return true;
    }
  }

  onEmitProducts() {
    this.skuAddProductButtonClick.emit(this.validateSKUResponseArray);
    this.onSKUBackClick();
  }

  getClassForStatus(statusValue) {
    if (statusValue) {
      return 'green-class';
    } else {
      return 'red-class';
    }
  }
}
