import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { ProductListService } from '../../../../product/services/product-list.service';
import { AppSettings } from '../../../app.settings';
import * as utils from '../../../utility-functions/utils';
@Component({
  selector: 'app-country-save-next-button',
  templateUrl: './country-save-next-button.component.html',
  styleUrls: ['./country-save-next-button.component.scss'],
})
export class CountrySaveNextButtonComponent implements OnInit {
  @Input() parent: FormGroup;
  @Input() btnLabel = 'saveNext';
  @Input() formCtrlName;
  @Input() isIcon = true;
  @Output() onSubmitClicked = new EventEmitter();
  @Output() onValueChange = new EventEmitter<any>();
  @Input() saveDisabled = false;
  @Input() disabled = true;
  selectedLanguage;
  countryList = AppSettings.COUNTRY_LIST_LANGUAGE;

  constructor(private router: Router, private productListService: ProductListService) {}

  ngOnInit() {
    // if (this.router.url.indexOf('/edit') > -1) {
    //   this.disabled = false;
    // }
    this.setLanguage();
  }

  setLanguage() {
    const language = utils.getLanguage();
    this.selectedLanguage = this.countryList.filter((ele) => ele.value === language)[0];
    this.parent.controls[`${this.formCtrlName}`].patchValue(this.selectedLanguage);
    this.productListService.setLanguage(this.selectedLanguage.value);
  }

  onChange(changes) {
    this.selectedLanguage = changes.value;
    this.productListService.setLanguage(this.selectedLanguage.value);
    this.onValueChange.emit(changes.value);
  }

  onClick(e) {
    this.onSubmitClicked.emit(e);
  }
}
