<div class="signin-box-container grid">
  <div class="signin-box-inner-container">
    <div class="signin-box col-5">
      <div class="company-logo" data-cy="signIn-company-logo">
        <img width="200" src="/assets/images/bitmap.png" />
      </div>
      <p-card class="signin-card">
        <form [formGroup]="signInForm">
          <div class="form-container grid">
            <div id="login-header" class="header" translate>login</div>
            <div class="form-field col-12 col-nogutter">
              <div class="form-label">
                <label for="email" id="login-label-emailOrUname" translate>emailOrUname</label>
              </div>
              <div class="form-control">
                <input
                  id="login-input-emailOrUname"
                  formControlName="email"
                  class="full-width"
                  type="text"
                  pInputText
                  data-cy="signIn-email"
                  (keypress)="eventHandler($event)"
                  [placeholder]="'emailOrUnamePlaceholder' | translate"
                />
              </div>
              <div
                id="login-errors-emailOrUname"
                class="form-errors"
                *ngIf="signInForm.controls.email.errors && (signInForm.controls.email.dirty || signInForm.controls.email.touched)"
              >
                <div [hidden]="!signInForm.controls.email.errors.required" translate>email_required</div>
              </div>
            </div>

            <div class="form-field col-12 col-nogutter">
              <div class="form-label">
                <label for="passwd" id="login-label-Password" translate>Password</label>
              </div>
              <div class="form-control">
                <input
                  id="login-input-Password"
                  pPassword
                  formControlName="password"
                  type="{{ passwordType }}"
                  class="full-width"
                  (keypress)="eventHandler($event)"
                  [feedback]="false"
                  data-cy="signIn-password"
                  [placeholder]="'enterPassword' | translate"
                  styleClass="p-password p-component p-input-icon-right"
                />
                <span (click)="toggleEye()">
                  <em [ngClass]="isEyeOpen ? 'pi pi-eye' : 'pi pi-eye-slash'" id="togglePassword"></em>
                </span>
              </div>
              <div [hidden]="!loginErrors.password.hasError" class="alert alert-danger" translate>
                <div *ngFor="let ErrorMessage of loginErrors.password.error">
                  {{ ErrorMessage.message }}
                </div>
              </div>
              <div
                id="login-errors-password"
                class="form-errors"
                *ngIf="signInForm.controls.password.errors && (signInForm.controls.password.dirty || signInForm.controls.password.touched)"
              >
                <div [hidden]="!signInForm.controls.password.errors.required" translate>passwordRequired</div>
              </div>
            </div>

            <div class="form-buttons col-12 col-nogutter">
              <div class="grid">
                <div class="field-checkbox col-6">
                  <p-checkbox id="login-checkbox-remMe" inputId="remMe" binary="true" formControlName="remMe" data-cy="signIn-rememberMe">
                  </p-checkbox>
                  <label for="remMe" translate>rememberMe</label>
                </div>
                <div class="col-6">
                  <a
                    id="login-link-forgot_password"
                    (click)="forgotPassword()"
                    class="forgot-password-link"
                    data-cy="signIn-forgot-pass-link"
                    translate
                    [routerLink]="['/forgot-password']"
                    >forgot_password</a
                  >
                </div>
              </div>

              <div class="form-errors">
                <div [hidden]="!loginErrors.general.hasError" class="alert alert-danger">
                  <div *ngFor="let ErrorMessage of loginErrors.general.error">
                    {{ ErrorMessage.message }}
                  </div>
                </div>
              </div>

              <button
                id="login-button-loginBtn"
                class="loginBtn col-12"
                pButton
                [label]="'login' | translate"
                type="button"
                data-cy="signIn-button-login"
                [disabled]="!signInForm.valid || isSaveBtn"
                (click)="doSignIn()"
              ></button>
            </div>
          </div>
        </form>
      </p-card>
    </div>
  </div>
</div>
